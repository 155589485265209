export class trendingProperties{
    trending : any = [
        {
            propertyId : 'emaar-digi-homes',
            propertyName : 'Emaar Digi Homes',
            propertyPlace : 'Sector 62, Gurgaon',
            propertyType : '',
            propertyUnit : '2 BHK, 3BHK',
            propertyThumbnail : 'assets/images/property/emaar-digi-homes.jpg',
            propertySize : [
                {
                    min : '802 Sq Ft',
                    max : '1300 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '1.62 Cr',
                    max : '2.38 Cr'
                }
            ]
        },
        {
            propertyId : 'anthurium',
            propertyName : 'Anthurium',
            propertyPlace : 'Sector 73, Noida',
            propertyType : '',
            propertyUnit : 'Office space floors',
            propertyThumbnail : 'assets/images/property/anthurium.jpg',
            propertySize : [
                {
                    min : '251 Sq Ft',
                    max : '300 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '18 Lacs',
                    max : '23 Lacs'
                }
            ]
        },
        {
            propertyId : 'godrej-alive',
            propertyName : 'Godrej Alive',
            propertyPlace : 'Mulund West, Mumbai',
            propertyType : '',
            propertyUnit : '2 BHK, 3BHK, 4 BHK',
            propertyThumbnail : 'assets/images/property/godrej-alive.jpg',
            propertySize : [
                {
                    min : '795 Sq Ft',
                    max : '1240 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '2 Cr',
                    max : '4.45 Cr'
                }
            ]
        },
        {
            propertyId : 'emaar-digi-homes',
            propertyName : 'Emaar Digi Homes',
            propertyPlace : 'Sector 62, Gurgaon',
            propertyType : '',
            propertyUnit : '2 BHK, 3BHK',
            propertyThumbnail : 'assets/images/property/emaar-digi-homes.jpg',
            propertySize : [
                {
                    min : '802 Sq Ft',
                    max : '1300 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '1.62 Cr',
                    max : '2.38 Cr'
                }
            ]
        },
        {
            propertyId : 'anthurium',
            propertyName : 'Anthurium',
            propertyPlace : 'Sector 73, Noida',
            propertyType : '',
            propertyUnit : 'Office space floors',
            propertyThumbnail : 'assets/images/property/anthurium.jpg',
            propertySize : [
                {
                    min : '251 Sq Ft',
                    max : '300 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '18 Lacs',
                    max : '23 Lacs'
                }
            ]
        },
        {
            propertyId : 'godrej-alive',
            propertyName : 'Godrej Alive',
            propertyPlace : 'Mulund West, Mumbai',
            propertyType : '',
            propertyUnit : '2 BHK, 3BHK, 4 BHK',
            propertyThumbnail : 'assets/images/property/godrej-alive.jpg',
            propertySize : [
                {
                    min : '795 Sq Ft',
                    max : '1240 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '2 Cr',
                    max : '4.45 Cr'
                }
            ]
        },
        {
            propertyId : 'emaar-digi-homes',
            propertyName : 'Emaar Digi Homes',
            propertyPlace : 'Sector 62, Gurgaon',
            propertyType : '',
            propertyUnit : '2 BHK, 3BHK',
            propertyThumbnail : 'assets/images/property/emaar-digi-homes.jpg',
            propertySize : [
                {
                    min : '802 Sq Ft',
                    max : '1300 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '1.62 Cr',
                    max : '2.38 Cr'
                }
            ]
        },
        {
            propertyId : 'anthurium',
            propertyName : 'Anthurium',
            propertyPlace : 'Sector 73, Noida',
            propertyType : '',
            propertyUnit : 'Office space floors',
            propertyThumbnail : 'assets/images/property/anthurium.jpg',
            propertySize : [
                {
                    min : '251 Sq Ft',
                    max : '300 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '18 Lacs',
                    max : '23 Lacs'
                }
            ]
        },
        {
            propertyId : 'godrej-alive',
            propertyName : 'Godrej Alive',
            propertyPlace : 'Mulund West, Mumbai',
            propertyType : '',
            propertyUnit : '2 BHK, 3BHK, 4 BHK',
            propertyThumbnail : 'assets/images/property/godrej-alive.jpg',
            propertySize : [
                {
                    min : '795 Sq Ft',
                    max : '1240 Sq Ft'
                }
            ],
            propertyPrice : [
                {
                    min : '2 Cr',
                    max : '4.45 Cr'
                }
            ]
        }
        
    ] 
}