<div class="dashboard-container listings position-fixed">
    <div class="w-100">
        <div class="card border-0 rounded-0 p-3">
            <h5 class="text-info fw-700 text-uppercase">
                <span class="fw-400">My</span> Contacts
            </h5>
            <div class="row listing-dropdowns">
                <div class="col-md-8">
                    <div class="btn-group">
                        <div class="btn-group">
                            <button id="status" class="btn btn-outline dropdown-toggle pr-5 custom-select" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Status</button>
                            <div class="dropdown-menu" aria-labelledby="status">
                                <a href="" class="dropdown-item">Active</a>
                                <a href="" class="dropdown-item">New</a>
                                <a href="" class="dropdown-item">Contacted</a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button id="type" class="btn btn-outline dropdown-toggle pr-5 custom-select" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Type</button>
                            <div class="dropdown-menu" aria-labelledby="type">
                                <a href="" class="dropdown-item">Individual</a>
                                <a href="" class="dropdown-item">Dealer</a>
                                <a href="" class="dropdown-item">Builder</a>
                                <a href="" class="dropdown-item">Owner</a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button id="duration" class="btn btn-outline dropdown-toggle pr-5 custom-select" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Duration</button>
                            <div class="dropdown-menu" aria-labelledby="duration">
                                <a href="" class="dropdown-item">Latest</a>
                                <a href="" class="dropdown-item">Within a week</a>
                                <a href="" class="dropdown-item">Within a month</a>
                                <a href="" class="dropdown-item">Last 6 months</a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <div class="input-group">
                                <input type="text" class="form-control rounded-0 border-right-0" placeholder="Area">
                                <div class="input-group-prepend">
                                    <button class="input-group-text btn rounded-right border-left-0 bg-white">
                                        <img src="assets/images/search.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-right">
                    <div class="btn-group">
                        <button id="sorting" class="btn btn-dark dropdown-toggle pr-5 custom-select-white" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Sort</button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sorting">
                            <a href="" class="dropdown-item">Newest</a>
                            <a href="" class="dropdown-item">Update Date</a>
                            <a href="" class="dropdown-item">Activity Rating</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="project-status listings">
    <div class="row">
        <div class="col-lg-6 mb-4">
            <div class="card shadow-sm p-3">
                <div class="row">
                    <div class="col-12">
                        <div class="media mb-4 pb-3 border-bottom">
                            <div class="media-thumbnail">
                                <div class="item-img">
                                    <img src="assets/images/gagan.jpg" alt="">
                                </div>
                            </div>
                            <div class="media-body p-3 position-relative">
                                <span class="btn status active btn-sm position-absolute propertyType fw-300 text-uppercase px-3 text-white"
                                    style="z-index:1">Active</span>
                                <div class="row">
                                    <div class="col-10 col-md-12">
                                        <h6 class="text-info fs-14 fw-700 mb-1">
                                            <a href="" class="text-info" target="_blank">
                                                Gagan sethi
                                            </a>
                                        </h6>
                                        <div class="mt-3 form-row">
                                            <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Phone</div>
                                            <div class="col">+91 9922334567</div>
                                        </div>
                                        <div class="mt-1 form-row">
                                            <div class="col-3 col-md-3 col-lg-4 col-xl-3 fw-600 fixed-width-column">Email</div>
                                            <div class="col">
                                                gagziweb@gmail.com
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="btn-group">
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-phone.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-message.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-whatsapp.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-email.svg" width="16" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="col-12 border-bottom pb-3 mb-3">
                            <div class="row justify-content-center">
                                <div class="col-md-9 status active text-center">
                                    <div class="media d-inline-flex">
                                        <div class="mr-4">
                                            <div class="progress-circle">
                                                <div class="progress">
                                                    <span class="progress-left">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <span class="progress-right">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <div class="progress-value">
                                                        <span class="value-wrapper fw-500">
                                                            80
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-info fw-700 mt-3 text-center">
                                                Activity Rating
                                            </div>
                                        </div>
                                        <div class="media-body align-self-center text-left">
                                            <div>
                                                <div class="mb-3"><span class="fw-500">Interested in:</span></div>
                                                <div class="text-black-50">• Gurgaon Area</div>
                                                <div class="text-black-50">• Residential Properties</div>
                                                <div class="text-black-50">• Price Range</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-row">
                            <div class="col">
                                <select name="" id="" class="form-control custom-select">
                                    <option value="">Activities</option>
                                </select>
                            </div>
                            <div class="col-fixed-95">
                                <button class="btn btn-dark">
                                    <i class="fas fa-plus-circle mr-1"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-4">
            <div class="card shadow-sm p-3">
                <div class="row">
                    <div class="col-12">
                        <div class="media mb-4 pb-3 border-bottom">
                            <div class="media-thumbnail">
                                <div class="item-img">
                                    <img src="assets/images/gagan.jpg" alt="">
                                </div>
                            </div>
                            <div class="media-body p-3 position-relative">
                                <span class="btn status active btn-sm position-absolute propertyType fw-300 text-uppercase px-3 text-white"
                                    style="z-index:1">Active</span>
                                <div class="row">
                                    <div class="col-10 col-md-12">
                                        <h6 class="text-info fs-14 fw-700 mb-1">
                                            <a href="" class="text-info" target="_blank">
                                                Gagan sethi
                                            </a>
                                        </h6>
                                        <div class="mt-3 form-row">
                                            <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Phone</div>
                                            <div class="col">+91 9922334567</div>
                                        </div>
                                        <div class="mt-1 form-row">
                                            <div class="col-3 col-md-3 col-lg-4 col-xl-3 fw-600 fixed-width-column">Email</div>
                                            <div class="col">
                                                gagziweb@gmail.com
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="btn-group">
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-phone.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-message.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-whatsapp.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-email.svg" width="16" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="col-12 border-bottom pb-3 mb-3">
                            <div class="row justify-content-center">
                                <div class="col-md-9 status active text-center">
                                    <div class="media d-inline-flex">
                                        <div class="mr-4">
                                            <div class="progress-circle">
                                                <div class="progress">
                                                    <span class="progress-left">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <span class="progress-right">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <div class="progress-value">
                                                        <span class="value-wrapper fw-500">
                                                            80
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-info fw-700 mt-3 text-center">
                                                Activity Rating
                                            </div>
                                        </div>
                                        <div class="media-body align-self-center text-left">
                                            <div>
                                                <div class="mb-3"><span class="fw-500">Interested in:</span></div>
                                                <div class="text-black-50">• Gurgaon Area</div>
                                                <div class="text-black-50">• Residential Properties</div>
                                                <div class="text-black-50">• Price Range</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-row">
                            <div class="col">
                                <select name="" id="" class="form-control custom-select">
                                    <option value="">Activities</option>
                                </select>
                            </div>
                            <div class="col-fixed-95">
                                <button class="btn btn-dark">
                                    <i class="fas fa-plus-circle mr-1"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-4">
            <div class="card shadow-sm p-3">
                <div class="row">
                    <div class="col-12">
                        <div class="media mb-4 pb-3 border-bottom">
                            <div class="media-thumbnail">
                                <div class="item-img">
                                    <img src="assets/images/gagan.jpg" alt="">
                                </div>
                            </div>
                            <div class="media-body p-3 position-relative">
                                <span class="btn status active btn-sm position-absolute propertyType fw-300 text-uppercase px-3 text-white"
                                    style="z-index:1">Active</span>
                                <div class="row">
                                    <div class="col-10 col-md-12">
                                        <h6 class="text-info fs-14 fw-700 mb-1">
                                            <a href="" class="text-info" target="_blank">
                                                Gagan sethi
                                            </a>
                                        </h6>
                                        <div class="mt-3 form-row">
                                            <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Phone</div>
                                            <div class="col">+91 9922334567</div>
                                        </div>
                                        <div class="mt-1 form-row">
                                            <div class="col-3 col-md-3 col-lg-4 col-xl-3 fw-600 fixed-width-column">Email</div>
                                            <div class="col">
                                                gagziweb@gmail.com
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="btn-group">
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-phone.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-message.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-whatsapp.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-email.svg" width="16" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="col-12 border-bottom pb-3 mb-3">
                            <div class="row justify-content-center">
                                <div class="col-md-9 status active text-center">
                                    <div class="media d-inline-flex">
                                        <div class="mr-4">
                                            <div class="progress-circle">
                                                <div class="progress">
                                                    <span class="progress-left">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <span class="progress-right">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <div class="progress-value">
                                                        <span class="value-wrapper fw-500">
                                                            80
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-info fw-700 mt-3 text-center">
                                                Activity Rating
                                            </div>
                                        </div>
                                        <div class="media-body align-self-center text-left">
                                            <div>
                                                <div class="mb-3"><span class="fw-500">Interested in:</span></div>
                                                <div class="text-black-50">• Gurgaon Area</div>
                                                <div class="text-black-50">• Residential Properties</div>
                                                <div class="text-black-50">• Price Range</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-row">
                            <div class="col">
                                <select name="" id="" class="form-control custom-select">
                                    <option value="">Activities</option>
                                </select>
                            </div>
                            <div class="col-fixed-95">
                                <button class="btn btn-dark">
                                    <i class="fas fa-plus-circle mr-1"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-4">
            <div class="card shadow-sm p-3">
                <div class="row">
                    <div class="col-12">
                        <div class="media mb-4 pb-3 border-bottom">
                            <div class="media-thumbnail">
                                <div class="item-img">
                                    <img src="assets/images/gagan.jpg" alt="">
                                </div>
                            </div>
                            <div class="media-body p-3 position-relative">
                                <span class="btn status active btn-sm position-absolute propertyType fw-300 text-uppercase px-3 text-white"
                                    style="z-index:1">Active</span>
                                <div class="row">
                                    <div class="col-10 col-md-12">
                                        <h6 class="text-info fs-14 fw-700 mb-1">
                                            <a href="" class="text-info" target="_blank">
                                                Gagan sethi
                                            </a>
                                        </h6>
                                        <div class="mt-3 form-row">
                                            <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Phone</div>
                                            <div class="col">+91 9922334567</div>
                                        </div>
                                        <div class="mt-1 form-row">
                                            <div class="col-3 col-md-3 col-lg-4 col-xl-3 fw-600 fixed-width-column">Email</div>
                                            <div class="col">
                                                gagziweb@gmail.com
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="btn-group">
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-phone.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-message.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-whatsapp.svg" width="16" alt="">
                                                </button>
                                                <button class="btn btn-outline-dark">
                                                    <img src="assets/images/icons/contact-email.svg" width="16" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="col-12 border-bottom pb-3 mb-3">
                            <div class="row justify-content-center">
                                <div class="col-md-9 status active text-center">
                                    <div class="media d-inline-flex">
                                        <div class="mr-4">
                                            <div class="progress-circle">
                                                <div class="progress">
                                                    <span class="progress-left">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <span class="progress-right">
                                                        <span class="progress-bar"></span>
                                                    </span>
                                                    <div class="progress-value">
                                                        <span class="value-wrapper fw-500">
                                                            80
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-info fw-700 mt-3 text-center">
                                                Activity Rating
                                            </div>
                                        </div>
                                        <div class="media-body align-self-center text-left">
                                            <div>
                                                <div class="mb-3"><span class="fw-500">Interested in:</span></div>
                                                <div class="text-black-50">• Gurgaon Area</div>
                                                <div class="text-black-50">• Residential Properties</div>
                                                <div class="text-black-50">• Price Range</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-row">
                            <div class="col">
                                <select name="" id="" class="form-control custom-select">
                                    <option value="">Activities</option>
                                </select>
                            </div>
                            <div class="col-fixed-95">
                                <button class="btn btn-dark">
                                    <i class="fas fa-plus-circle mr-1"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>