import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchListComponent } from './pages/search-list/search-list.component';
import { HomeComponent } from './pages/home/home.component';
import { PropertyDetailComponent } from './pages/property-detail/property-detail.component';
import { WhyReachxComponent } from './pages/why-reachx/why-reachx.component';
import { ReachXPlatformComponent } from './pages/reach-x-platform/reach-x-platform.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { BuyerDashboardComponent } from './pages/buyer-dashboard/buyer-dashboard.component';
import { SellComponent } from './pages/sell/sell.component';
import { PostPropertyComponent } from './pages/post-property/post-property.component';
import { MyReachXComponent } from './admin/my-reach-x/my-reach-x.component';
import { MyListingComponent } from './admin/my-listing/my-listing.component';
import { ContactsComponent } from './admin/contacts/contacts.component';
import { PlansComponent } from './admin/plans/plans.component';
import { TestMediaComponent } from './pages/test-media/test-media.component';


const routes: Routes = [
  {
    path : 'test-media', component: TestMediaComponent
  },
  {
    path : '', component: HomeComponent
  },
  {
    path : 'sell', component: SellComponent
  },
  {
    path : 'post-property', component: PostPropertyComponent
  },
  {
    path : 'search-list', component: SearchListComponent
  },
  {
    path : 'why-reachx', component: WhyReachxComponent
  },
  {
    path : 'reachx-platform', component: ReachXPlatformComponent
  },
  {
    path : 'my-account', component: MyAccountComponent
  },
  {
    path : 'my-profile', component: BuyerDashboardComponent
  },

  {
    path : 'admin/my-reach-x', component: MyReachXComponent
  },
  {
    path : 'admin/my-listing', component: MyListingComponent
  },
  {
    path : 'admin/contacts', component: ContactsComponent
  },
  {
    path : 'admin/plans', component: PlansComponent
  },
  {
    path : 'search-list/:propertyId', component: PropertyDetailComponent
  },
  {
    path : ':propertyId', component: PropertyDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
