import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.css']
})
export class ProspectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
