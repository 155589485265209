import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { exclusiveProperties } from '../../appModals/exclusiveProperty.modals';
import { FormGroup, FormControl, Form, Validators, AbstractControl } from "@angular/forms";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { ActivatedRoute, Params } from '@angular/router';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AgmCoreModule } from '@agm/core';
declare var $: any;
// import { ScrollSpyService } from 'ng-spy';


@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.css']
})
export class PropertyDetailComponent implements OnInit, AfterViewInit {

  response_message = { status_code: '', status_desc: '' };
  contactform = new FormGroup({
    person_name: new FormControl('', [Validators.required, this.nameValidator]),
    person_email: new FormControl('', [Validators.required, this.emailValidator]),
    person_phone: new FormControl('', [Validators.required, this.phoneNumberValidator]),
    message: new FormControl('', [Validators.required]),
    loan_interested: new FormControl('false', [Validators.required]),
    alert_subscription: new FormControl('false', [Validators.required])
  });

  // BANNER ITEM
  propertiesPic: OwlOptions = {
    loop: true,
    margin: 5,
    nav: false,
    items: 2
  }

  exclusiveProjects: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [''],
    margin: 30,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      738: {
        items: 2
      },
      940: {
        items: 3
      },
      1200: {
        items: 4
      },
      1700: {
        items: 5
      }
    },
    nav: true
  }

  lat: number = 28.398190;
  lng: number = 77.048770;

  // Initial value for data call;
  exclusiveSelectedItem;
  id;
  exclusiveAllProperties: [];

  // Initial value for collapse data
  expanded: boolean = false;
  text = 'View More';

  constructor(
    private exclusiveProperty: exclusiveProperties,
    private _activatedRoutes: ActivatedRoute,
    private http: HttpClient,
    private refozoDBAPIService: refozoDBAPIService
    // private _spyService: ScrollSpyService
  ) { }

  get person_phone() {
    return this.contactform.get('person_phone');
  }
  get person_name() {
    return this.contactform.get('person_name');
  }
  get person_email() {
    return this.contactform.get('person_email');
  }
  get message() {
    return this.contactform.get('message');
  }


  nameValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/.test(control.value)
    return valid ? null : { invalidName: { valid: false, value: control.value } }
  }
  emailValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(control.value.toLowerCase())
    return valid ? null : { invalidEmail: { valid: false, value: control.value } }
  }

  phoneNumberValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^\d+$/.test(control.value)
    return valid ? null : { invalidNumber: { valid: false, value: control.value } }
  }

  ngOnInit(): void {
    // Get All Exclusive Projects
    this.exclusiveAllProperties = this.exclusiveProperty.propertyList[0].exclusivePropertiesAll;

    this._activatedRoutes.params.subscribe((params: Params) => {
      this.id = params['propertyId'];
      const data = from(this.exclusiveProperty.propertyList[0].exclusivePropertiesAll);
      data.pipe(
        filter(property => property['propertyId'] == this.id),
      ).subscribe(
        res => {
          this.exclusiveSelectedItem = res;
        }
      )
    });

    var x, i, j, l, ll, selElmnt, a, b, c;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("select-field");
    l = x.length;
    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      ll = selElmnt.length;
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected custom-select form-control fs-14 fw-400 py-2 pl-2 h-100 rounded-left rounded-0 border-right-0 telcode");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items py-3 select-hide");
      for (j = 1; j < ll; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
          /* When an item is clicked, update the original select box,
          and the selected item: */
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }

    function closeAllSelect(elmnt) {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      var x, y, i, xl, yl, arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);

    $(document).ready(function () {
      $('.thumbnail').viewbox();

      (function () {
        var vb = $('.popup-link').viewbox();
        $('.popup-open-button').click(function () {
          vb.trigger('viewbox.open');
        });
        $('.close-button').click(function () {
          vb.trigger('viewbox.close');
        });
      })();
    });



    $(document).ready(function () {
      $(window).on('scroll', function () {
        var scroll = $(document).scrollTop();
        if (scroll > ($('.afterPropertyDetail').offset().top - 200) || scroll <= 300) {
          $('.scrollableMenu .propertyNavbar').removeClass('position-fixed');
        }
        else {
          $('.scrollableMenu .propertyNavbar').addClass('position-fixed');
        }

      });
      var contactSec = $('.contactSec').height();
      console.log(contactSec);
      $(window).on('scroll', function () {
        if (window.innerWidth < 992) {
          var scroll = $(document).scrollTop();


          if (scroll > ($('.afterPropertyDetail').offset().top - (contactSec + 150)) || scroll <= 300) {
            $('.scrollableMenu .propertyNavbar').removeClass('position-fixed');
          }
          else {
            $('.scrollableMenu .propertyNavbar').addClass('position-fixed');
          }
        }

      });
      $(window).on('scroll', function () {
        if (window.innerWidth > 1200) {

          var scroll = $(document).scrollTop();
          if (scroll > ($('.afterPropertyDetail').offset().top - (window.innerHeight - ($('.property-detail-outlet').offset().top - 100))) || scroll <= 300) {
            $('.contactSec .contactWrapper').removeClass('position-fixed');
            $('.contactSec .contactWrapper').addClass('align-self-end');
          }
          else if (scroll <= 300) {
            $('.contactSec .contactWrapper').removeClass('align-self-end');
          }
          else {
            $('.contactSec .contactWrapper').addClass('position-fixed');
          }
        }
      });
      $(window).on('scroll', function () {
        if (window.innerWidth > 1400) {

          var scroll = $(document).scrollTop();
          if (scroll > ($('.afterPropertyDetail').offset().top - (window.innerHeight - ($('.property-detail-outlet').offset().top - 150))) || scroll <= 300) {
            $('.contactSec .contactWrapper').removeClass('position-fixed');
          }
          else {
            $('.contactSec .contactWrapper').addClass('position-fixed');
          }
        }
      });
    });
    $(document).ready(function () {
      $("a[href*='#']").bind('click', function (e) {
        e.preventDefault(); // prevent hard jump, the default behavior

        var target = $(this).attr("href"); // Set the target as variable

        // perform animated scrolling by getting top-position of target-element and set it as scroll target
        $('html, body').stop().animate({
          scrollTop: $(target).offset().top
        }, 600, function () {
          location.hash = target; //attach the hash (#jumptarget) to the pageurl
        });

        return false;
      });
    });

    $(window).scroll(function () {
      var scrollDistance = $(window).scrollTop();

      // Show/hide menu on scroll
      //if (scrollDistance >= 850) {
      //		$('nav').fadeIn("fast");
      //} else {
      //		$('nav').fadeOut("fast");
      //}

      // Assign active class to nav links while scolling
      $('.section').each(function (i) {
        var x = $(this).position();
        if (x.top <= scrollDistance) {
          $('.propertyNavbar a.active').removeClass('active');
          $('.propertyNavbar a').eq(i).addClass('active');
        }
      });
    }).scroll();
  }


  ngAfterViewInit() {

  }

  expandOverview() {
    this.expanded = !this.expanded;
    if (this.expanded == true) {
      this.text = 'View Less'
    } else {
      this.text = 'View More'
    }
  }

  submitContactUsForm(post_data) {
    var response: any;
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'update_contact_us');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => this.response_message = { status_code: 'error', status_desc: 'Sorry, your request could not be submitted. Please try again sometime later.' },
        () => this.response_message = response)
  }

}
