<!-- STATS BEGIN HERE -->
<div class="stats">
    <div class="form-row">
        <div class="col-md-6 col-lg-3">
            <div class="alert alert-danger">
                <div class="media mb-2">
                    <div class="media-body">
                        <h3 class="mb-0 lh-normal fw-700">37</h3>
                        <small class="fs-11 text-uppercase fw-500">prospects</small>
                    </div>
                    <img src="assets/images/icons/prospects2.svg" class="align-self-center ml-3" alt="">
                </div>
                <small><a href="">View Detail</a></small>
            </div>
        </div>
        <div class="col-md-6 col-lg-3">
            <div class="alert alert-success">
                <div class="media mb-2">
                    <div class="media-body">
                        <h3 class="mb-0 lh-normal fw-700">342</h3>
                        <small class="fs-11 text-uppercase fw-500">messages</small>
                    </div>
                    <img src="assets/images/icons/messages2.svg" class="align-self-center ml-3" alt="">
                </div>
                <small><a href="">View Detail</a></small>
            </div>
        </div>
        <div class="col-md-6 col-lg-3">
            <div class="alert alert-purple">
                <div class="media mb-2">
                    <div class="media-body">
                        <h3 class="mb-0 lh-normal fw-700">342</h3>
                        <small class="fs-11 text-uppercase fw-500">visitors</small>
                    </div>
                    <img src="assets/images/icons/visitors.svg" class="align-self-center ml-3" alt="">
                </div>
                <small><a href="">View Detail</a></small>
            </div>
        </div>
        <div class="col-md-6 col-lg-3">
            <div class="alert alert-info">
                <div class="media mb-2">
                    <div class="media-body">
                        <h3 class="mb-0 lh-normal fw-700">2</h3>
                        <small class="fs-11 text-uppercase fw-500">property listings</small>
                    </div>
                    <img src="assets/images/icons/my-properties2.svg" class="align-self-center ml-3" alt="">
                </div>
                <small><a href="">View Details</a></small>
            </div>
        </div>
    </div>
</div>
<!-- STATS END HERE -->
<!-- ACTIVITIES SECTION BEGIN HERE -->
<div class="activities">
    <div class="form-row">
        <div class="col-lg-6">
            <div class="card p-3">
                <div class="row align-items-center">
                    <div class="col-5 col-md-6">
                        <h6 class="fs-14 text-uppercase text-truncate">PROSPECT ACTIVITY</h6>
                    </div>
                    <div class="col-7 col-md-6">
                        <div class="form-row align-items-center">
                            <div class="col-3 text-center">
                                <div class="mb-1">
                                    <img src="assets/images/icons/line.svg" alt="">
                                </div>
                                <div class="text-info" style="font-size: 8px">
                                    Line
                                </div>
                            </div>
                            <div class="col-3 text-center">
                                <div class="mb-1">
                                    <img src="assets/images/icons/bar.svg" height="27" alt="">
                                </div>
                                <div class="text-gray" style="font-size: 8px">
                                    Bar
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <div class="dropdown ">
                                    <button class="btn btn-info btn-sm dropdown-toggle fs-7-5 text-uppercase" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      Date range
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" href="#">Action</a>
                                      <a class="dropdown-item" href="#">Another action</a>
                                      <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <img src="assets/images/bar.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card p-3">
                <div class="row align-items-center">
                    <div class="col-5 col-md-6">
                        <h6 class="fs-14 text-uppercase text-truncate">MESSAGE ACTIVITY</h6>
                    </div>
                    <div class="col-7 col-md-6">
                        <div class="form-row align-items-center">
                            <div class="col-3 text-center">
                                <div class="mb-1">
                                    <img src="assets/images/icons/line.svg" alt="">
                                </div>
                                <div class="text-info" style="font-size: 8px">
                                    Line
                                </div>
                            </div>
                            <div class="col-3 text-center">
                                <div class="mb-1">
                                    <img src="assets/images/icons/bar.svg" height="27" alt="">
                                </div>
                                <div class="text-gray" style="font-size: 8px">
                                    Bar
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <div class="dropdown ">
                                    <button class="btn btn-info btn-sm dropdown-toggle fs-7-5 text-uppercase" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      Date range
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" href="#">Action</a>
                                      <a class="dropdown-item" href="#">Another action</a>
                                      <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <img src="assets/images/bar.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ACTIVITIES SECTION END HERE -->