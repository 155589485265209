<div class="dashboard-container listings position-fixed">
    <div class="w-100">
        <div class="card border-0 rounded-0 p-3">
            <h5 class="text-info fw-700 text-uppercase mb-0">
                <span class="fw-400">My</span> Plan
            </h5>
        </div>
    </div>
</div>
<div class="admin planTable py-4">
    <div class="fw-700 mb-4">
        Select a Best Plan option for your Property
    </div>
    <table class="table planTable table-sm">
        <thead>
            <tr>
                <th class="border-0" width="240"></th>
                <th class="text-center border-0 basic-plan py-3">
                    Basic <br>
                    <small class="fw-300">Free</small>
                </th>
                <th class="text-center border-0 silver-plan py-3">
                    Silver <br>
                    <small class="fw-300">Rs 500</small>
                </th>
                <th class="text-center border-0 gold-plan py-3">
                    GOLD <br>
                    <small class="fw-300">Rs 1000</small>
                </th>
                <th class="text-center border-0 platinum-plan py-3">
                    PLATINUM <br>
                    <small class="fw-300">Rs 2500</small>
                </th>
                <th class="text-center border-0 daimond-plan py-3">
                    DIAMOND <br>
                    <small class="fw-300">Customized</small>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="border-0">Plan Validity</td>
                <td class="text-center basic-plan border-0">2 Months</td>
                <td class="text-center silver-plan border-0">3 Months</td>
                <td class="text-center gold-plan border-0">3 Months</td>
                <td class="text-center platinum-plan border-0">6 Months</td>
                <td class="text-center daimond-plan border-0">Till you sell</td>
            </tr>
            <tr>
                <td>Properties </td>
                <td class="text-center basic-plan">1</td>
                <td class="text-center silver-plan">1</td>
                <td class="text-center gold-plan">2</td>
                <td class="text-center platinum-plan">3</td>
                <td class="text-center daimond-plan">Multiple</td>
            </tr>
            <tr>
                <td>Reach-X Verification</td>
                <td class="text-center basic-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center silver-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center gold-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center platinum-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr>
                <td>Priority in Searches</td>
                <td class="text-center basic-plan">&nbsp;</td>
                <td class="text-center silver-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center gold-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center platinum-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr>
                <td>Premium Listing</td>
                <td class="text-center basic-plan">&nbsp;</td>
                <td class="text-center silver-plan">&nbsp;</td>
                <td class="text-center gold-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center platinum-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr>
                <td>Posting Review by Experts</td>
                <td class="text-center basic-plan">&nbsp;</td>
                <td class="text-center silver-plan">&nbsp;</td>
                <td class="text-center gold-plan">&nbsp;</td>
                <td class="text-center platinum-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr>
                <td>Email Promotions</td>
                <td class="text-center basic-plan">&nbsp;</td>
                <td class="text-center silver-plan">&nbsp;</td>
                <td class="text-center gold-plan">&nbsp;</td>
                <td class="text-center platinum-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr>
                <td>3D model &amp; Video Shoot </td>
                <td class="text-center basic-plan">&nbsp;</td>
                <td class="text-center silver-plan">&nbsp;</td>
                <td class="text-center gold-plan">&nbsp;</td>
                <td class="text-center platinum-plan">&nbsp;</td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr>
                <td>Dedicated Reach-X Expert</td>
                <td class="text-center basic-plan">&nbsp;</td>
                <td class="text-center silver-plan">&nbsp;</td>
                <td class="text-center gold-plan">&nbsp;</td>
                <td class="text-center platinum-plan">&nbsp;</td>
                <td class="text-center daimond-plan">
                    <img src="assets/images/icons/small-check.svg" alt="">
                </td>
            </tr>
            <tr class="btn-group btn-group-toggle" data-toggle="buttons">
                <td class="border-0"></td>
                <td class="border-0 {{selectPlan.planBtn}}"  *ngFor="let selectPlan of selectedPlan; let i = index;">
                    <label class="btn btn-light btn-block" [class.active] = "selectPlan.planActive ? 'active' : '' ">
                        <input type="radio" name="selectPlan" id="{{selectPlan.id}}" [checked] = "selectPlan.planActive"> 
                        {{selectPlan.planActive ? 'Select' : 'Buy'}}
                    </label>
                </td>
                <td class="border-0 daimond-btn">
                    <a class="btn btn-light btn-block">
                        <span class="d-block d-md-none">
                            <i class="fas fa-phone-alt"></i>
                        </span>
                        <span class="d-none d-md-block">
                            Contact Us
                        </span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>