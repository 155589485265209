<!-- HOMEPAGE BANNER BEGIN HERE -->
<home-banner class="bannerSec"></home-banner>
<!-- HOMEPAGE BANNER END HERE -->
<!-- HOMEPAGE ACTIVE LISTING SEARCH BEGIN HERE -->
<home-active-listing></home-active-listing>
<!-- HOMEPAGE ACTIVE LISTING SEARCH END HERE -->
<!-- HOMEPAGE FEATURED AND EXCLUSIVE PROPERTIES BEGIN HERE -->
<home-property-type></home-property-type>
<!-- HOMEPAGE FEATURED AND EXCLUSIVE PROPERTIES END HERE -->
<!-- TRENDING PROJECTS BEGIN HERE -->
<home-trending-projects></home-trending-projects>
<!-- TRENDING PROJECTS END HERE -->
<!-- EXPAND YOUR REACH BEGIN HERE -->
<home-expand-reach></home-expand-reach>
<!-- EXPAND YOUR REACH END HERE -->
<!-- SERVICE AT YOUR REACH BEGIN HERE -->
<home-services-reach></home-services-reach>
<!-- SERVICE AT YOUR REACH END HERE -->
<!-- REACH WITH CONFIDENCE BEGIN HERE -->
<home-reach-confidence></home-reach-confidence>
<!-- REACH WITH CONFIDENCE END HERE -->
<!-- RESOURCES AT YOUR REACH BEGIN HERE -->
<home-resources></home-resources>
<!-- RESOURCES AT YOUR REACH END HERE -->
<!-- SIGN UP TODAY BEGIN HERE -->
<home-sign-up></home-sign-up>
<!-- SIGN UP TODAY END HERE -->