<div class="row propertyType pt-5 bg-white">
    <div class="col-12">
        <h5 class="text-info text-uppercase fw-400 text-center">
            reach <span class="fw-600">featured</span> and <span class="fw-600">exclusive</span> properties
        </h5>
    </div>
    <div class="col-12 mt-3">
        <div class="row propertiesTab">
            <div class="col-md-10 col-lg-9 mx-auto text-center nav-scroll">
                <ul class="nav nav-tabs fs-16 text-uppercase justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="exclusive-properties-tab" data-toggle="tab" href="#exclusive-properties" role="tab" aria-controls="exclusive-properties" aria-selected="true">
                            Exclusive Properties
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="featured-developers-tab" (click) = "activeTab($event)" data-toggle="tab" href="#featured-developers" role="tab" aria-controls="featured-developers" aria-selected="false">
                            Featured Developers
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        
        
        <div class="row homeProperties">
            <div class="properties">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="exclusive-properties" role="tabpanel" aria-labelledby="exclusive-properties-tab">
                        <div class="col-xl-11 mx-auto position-relative py-5">
                            <home-exclusive-property></home-exclusive-property>
                        </div>
                    </div>
                    <div class="tab-pane fade show active" id="featured-developers" role="tabpanel" aria-labelledby="featured-developers-tab">
                        <div class="col-xl-11 mx-auto position-relative py-5" *ngIf="tabLoaded">
                            <home-featured-developers></home-featured-developers>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
