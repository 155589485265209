<div class="row">
    <div class="py-3 col-lg-11 mx-auto">
        <div class="row">
            <div class="col-lg-3 mb-3 mb-lg-0">
                <div class="card profileMenu py-2">
                    <div  id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <ul class="nav flex-column nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" id="v-pills-dashboard-tab" data-toggle="pill" href="#v-pills-dashboard" role="tab" aria-controls="v-pills-dashboard" aria-selected="true">
                                    <span class="icons">
                                        <img src="assets/images/icons/dashboard.svg" alt="">
                                    </span>
                                    <span class="text">Dashboard</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-myproperties-tab" data-toggle="pill" href="#v-pills-myproperties" role="tab" aria-controls="v-pills-myproperties" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-properties.svg" alt="">
                                    </span>
                                    <span class="text">My Properties</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-mylistings-tab" data-toggle="pill" href="#v-pills-mylistings" role="tab" aria-controls="v-pills-mylistings" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-listing.svg" alt="">
                                    </span>
                                    <span class="text">My Listings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-myservices-tab" data-toggle="pill" href="#v-pills-myservices" role="tab" aria-controls="v-pills-myservices" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-settings.svg" alt="">
                                    </span>
                                    <span class="text">My Services</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-prospects-tab" data-toggle="pill" href="#v-pills-prospects" role="tab" aria-controls="v-pills-prospects" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/prospects.svg" alt="">
                                    </span>
                                    <span class="text">Prospects</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/messages.svg" alt="">
                                    </span>
                                    <span class="text">Messages</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-reports-tab" data-toggle="pill" href="#v-pills-reports" role="tab" aria-controls="v-pills-reports" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/reports.svg" alt="">
                                    </span>
                                    <span class="text">Reports</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 mobileDashboard">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-dashboard" role="tabpanel" aria-labelledby="v-pills-dashboard-tab">
                        <app-dashboard></app-dashboard>
                    </div>
                    <div class="tab-pane fade" id="v-pills-myproperties" role="tabpanel" aria-labelledby="v-pills-myproperties-tab">
                        <app-my-properties></app-my-properties>
                    </div>
                    <div class="tab-pane fade" id="v-pills-mylistings" role="tabpanel" aria-labelledby="v-pills-mylistings-tab">
                        <app-my-listings></app-my-listings>
                    </div>
                    <div class="tab-pane fade" id="v-pills-myservices" role="tabpanel" aria-labelledby="v-pills-myservices-tab">
                        <app-my-services></app-my-services>
                    </div>
                    <div class="tab-pane fade" id="v-pills-prospects" role="tabpanel" aria-labelledby="v-pills-prospects-tab">
                        <app-prospects></app-prospects>
                    </div>
                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <app-messages></app-messages>
                    </div>
                    <div class="tab-pane fade" id="v-pills-reports" role="tabpanel" aria-labelledby="v-pills-reports-tab">
                        <app-reports></app-reports>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>