export class exclusiveProperties{
    propertyList : any = [
        {
            exclusivePropertiesAll :  [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : 'New Project',
                    propertyTypeClass : 'badge-success',
                    propertyUnit : 'Retail Shops',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall.jpg",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-2.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-3.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall/airia-mall-4.jpg",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    propertyMap : 'https://goo.gl/maps/WTY6khkdCQsuEyN36',
                    propertyDetail : [
                        {
                            propertyMenus : [
                                {
                                    propertyMenusName : 'Overview',
                                    propertyMenusURL: 'property-overview',
                                    menuactive : true
                                },
                                {
                                    propertyMenusName : 'Amenities',
                                    propertyMenusURL: 'property-aminities',
                                    menuactive : false
                                },
                                {
                                    propertyMenusName : 'Tours',
                                    propertyMenusURL: 'property-tours',
                                    menuactive : false
                                },
                                {
                                    propertyMenusName : 'Documents',
                                    propertyMenusURL: 'property-documents',
                                    menuactive : false
                                }
                            ],
                            overviewDetail : [
                                {
                                    overviewText : `<p>Situated at a prime location in Sector 68, Sohna Road, Gurugram, Airia Mall offers an inimitable mix of shopping, F&B and entertainment outlets. Located in close proximity to various residencies, community amenities and six-lane National Expressway, Airia Mall offers a one-of-a-kind opportunity to retailers and traders with the best connectivity possible not only from across sectors of Gurugram but also with the national capital.</p>
                                    <p>The central and pristine location of the property along with its spacious ambience is the attraction for people ensuring the growth of businesses up and running at Airia. Having adopted leased model for promotion (only 2nd of its kind in the city), this development is attracting all the opulent brands like Lifestyle, Forever 21, Marks & Spencer etc. due to its worthy prospects and unique compilation. It’s a premium HYBRID retail space adorned with centralized air-conditioning and open-air regal rotunda wherein everyday items as well as high-end products are on offer. Specially designed to accommodate a wide range of cafés and restaurants serving international cuisine, along with a lavishing multiplex, this slice of Gurugram real estate includes every modern convenience.</p> 
                                    <p>Situated at a prime location in Sector 68, Sohna Road, Gurugram, Airia Mall offers an inimitable mix of shopping, F&B and entertainment outlets. Located in close proximity to various residencies, community amenities and six-lane National Expressway, Airia Mall offers a one-of-a-kind opportunity to retailers and traders with the best connectivity possible not only from across sectors of Gurugram but also with the national capital.</p>
                                    <p> The central and pristine location of the property along with its spacious ambience is the attraction for people ensuring the growth of businesses up and running at Airia. Having adopted leased model for promotion (only 2nd of its kind in the city), this development is attracting all the opulent brands like Lifestyle, Forever 21, Marks & Spencer etc. due to its worthy prospects and unique compilation. It’s a premium HYBRID retail space adorned with centralized air-conditioning and open-air regal rotunda wherein everyday items as well as high-end products are on offer. Specially designed to accommodate a wide range of cafés and restaurants serving international cuisine, along with a lavishing multiplex, this slice of Gurugram real estate includes every modern convenience. Situated at a prime location in Sector 68, Sohna Road, Gurugram, Airia Mall offers an inimitable mix of shopping, F&B and entertainment outlets. Located in close proximity to various residencies, community amenities and six-lane National Expressway, Airia Mall offers a one-of-a-kind opportunity to retailers and traders with the best connectivity possible not only from across sectors of Gurugram but also with the national capital.</p>
                                    <p>The central and pristine location of the property along with its spacious ambience is the attraction for people ensuring the growth of businesses up and running at Airia. Having adopted leased model for promotion (only 2nd of its kind in the city), this development is attracting all the opulent brands like Lifestyle, Forever 21, Marks & Spencer etc. due to its worthy prospects and unique compilation. It’s a premium HYBRID retail space adorned with centralized air-conditioning and open-air regal rotunda wherein everyday items as well as high-end products are on offer. Specially designed to accommodate a wide range of cafés and restaurants serving international cuisine, along with a lavishing multiplex, this slice of Gurugram real estate includes every modern convenience.</p>`,
                                    overviewOptions : [
                                        {
                                            overviewOptionTitle : 'Primary Property Type',
                                            overviewOptionValue : 'Retail'
                                        },
                                        {   
                                            overviewOptionTitle : 'Construction Status',
                                            overviewOptionValue : 'Built'
                                        },
                                        {   
                                            overviewOptionTitle : 'Occupancy',
                                            overviewOptionValue : '55%'
                                        },
                                        {   
                                            overviewOptionTitle : 'Type of Ownership',
                                            overviewOptionValue : 'Rental Only'
                                        },
                                        {   
                                            overviewOptionTitle : 'Buildings',
                                            overviewOptionValue : '01'
                                        },
                                        {   
                                            overviewOptionTitle : 'Floor',
                                            overviewOptionValue : '01'
                                        },
                                        {   
                                            overviewOptionTitle : 'Year Built',
                                            overviewOptionValue : '1979'
                                        },
                                        {   
                                            overviewOptionTitle : 'Parcel Number',
                                            overviewOptionValue : '19-09-09-202-003-0000'
                                        },
                                        {   
                                            overviewOptionTitle : 'Unit Types',
                                            overviewOptionValue : 'Retail, Office, Food Court'
                                        },
                                        {   
                                            overviewOptionTitle : 'Gross Area Range (Sq. Ft.)',
                                            overviewOptionValue : '100 – 1000 Sq. Ft.'
                                        },
                                        {   
                                            overviewOptionTitle : 'Starting Price ',
                                            overviewOptionValue : 'INR 2 Lacs per month onwards'
                                        },
                                        {   
                                            overviewOptionTitle : 'Ready possession as of Date',
                                            overviewOptionValue : '03/31/2020'
                                        },
                                        {   
                                            overviewOptionTitle : 'Units/Lot Sizes available',
                                            overviewOptionValue : '04'
                                        }

                                    ]
                                }
                            
                            ],
                            aminitiesAvailable : [
                                {
                                    aminitiesName : 'Food Court',
                                    aminitiesIcon : 'assets/images/icons/food-court.svg'
                                },
                                {
                                    aminitiesName : 'Multiplex',
                                    aminitiesIcon : 'assets/images/icons/movie.svg'
                                },
                                {
                                    aminitiesName : '24 hr Security',
                                    aminitiesIcon : 'assets/images/icons/security.svg'
                                },
                                {
                                    aminitiesName : 'Lift',
                                    aminitiesIcon : 'assets/images/icons/lift.svg'
                                },
                                {
                                    aminitiesName : 'Video Security',
                                    aminitiesIcon : 'assets/images/icons/cctv.svg'
                                },
                                {
                                    aminitiesName : 'Business Center',
                                    aminitiesIcon : 'assets/images/icons/business-center.svg'
                                },
                            ],
                            virtualTour : [
                                {
                                    virtualDesc : `
                                        <p>We are making professional Tours of retail stores and shopping malls. These are walk-through virtual tours of the insides of stores and businesses. Your guests can now tour, view and explore your facility using their computer and or mobile device. This is an excellent way to showcase your facilities. These Tours are placed directly on google maps, plus you can easily embed the 360 Tours to your website!</p>
                                        <h6 class="text-orange fs-14 text-uppercase">Airia mall</h6>
                                        <p>The Airia Mall is a luxurious 1.4 million square foot regional shopping center, featuring over 150 fine specialty shops, anchored by Nordstrom, Saks Fifth Avenue, Bloomingdale’s, Macy’s and Sears.</p>
                                        `
                                }
                            ]
                        }
                    ]
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : 'Rental',
                    propertyTypeClass : 'badge-warning',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/signature-global-park.jpg",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/signature-global-park.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/signature-global-park.jpg",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : 'Resale',
                    propertyTypeClass : 'badge-info',
                    propertyUnit : '2 BHK, 3 BHK, 4 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/godrej-icon.jpg",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/godrej-icon.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/godrej-icon.jpg",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : 'New Project',
                    propertyTypeClass : 'badge-success',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/indiabulls.png",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/indiabulls.png",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/indiabulls.png",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall-2',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : 'Rental',
                    propertyTypeClass : 'badge-warning',
                    propertyUnit : 'Retail Shops',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/airia-mall.jpg",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/airia-mall.jpg",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park-2',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : 'Rental',
                    propertyTypeClass : 'badge-warning',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/signature-global-park.jpg",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/signature-global-park.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/signature-global-park.jpg",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon-2',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : 'Rental',
                    propertyTypeClass : 'badge-warning',
                    propertyUnit : '2 BHK, 3 BHK, 4 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/godrej-icon.jpg",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/godrej-icon.jpg",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/godrej-icon.jpg",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09-2',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : 'Rental',
                    propertyTypeClass : 'badge-warning',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertyThumbnailCarousel : [
                        {
                            thumbnail : "assets/images/property/indiabulls.png",
                            thumbnailActive : true
                        },
                        {
                            thumbnail : "assets/images/property/indiabulls.png",
                            thumbnailActive : false
                        },
                        {
                            thumbnail : "assets/images/property/indiabulls.png",
                            thumbnailActive : false
                        }
                    ],
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            exclusivePropertiesOffice : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            exclusivePropertiesApartment : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK, 4 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            exclusivePropertiesPlot : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            exclusivePropertiesRental : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            exclusivePropertiesIndustrial : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ]
        }
    ]
}