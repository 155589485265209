<!-- TOP SEARCH BAR BEGIN HERE -->
<div class="position-fixed modify-search pt-4 bg-light row w-100">
    <div class="col-12">
        <div class="form-row">
            <div class="col-lg-9 col-xl-7">
                <form>
                    <div class="row no-gutters">
                        <div class="col-md-6">
                            <div class="row no-gutters">
                                <div class="col-4  property dropdown">
                                    <button
                                        class="text-truncate form-control text-left custom-select fs-14 property rounded-right-0 border-right-0 h-auto py-2"
                                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        name="property_type" id="property_type"
                                        [innerHTML]="p_type != '' ? p_type : 'Property'">
                                        Property
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a *ngFor="let p_type of property_type" class="dropdown-item" href="#"
                                            (click)="set_features(1, p_type.name)">{{ p_type.name }}</a>
                                    </div>
                                </div>
                                <div class="col-4  type dropdown">
                                    <button
                                        class="text-truncate form-control text-left custom-select fs-14 property rounded-0 border-right-0 h-auto py-2"
                                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        name="property_usage" id="property_usage"
                                        [innerHTML]="usage  != '' ? usage : 'Type'">
                                        Type
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a *ngFor="let usage of property_usage" class="dropdown-item" href="#"
                                            (click)="set_features(2, usage.name)">{{ usage.name }}</a>
                                    </div>
                                </div>
                                <div class="col-4  city dropdown">
                                    <button
                                        class="text-truncate form-control text-left custom-select fs-14 property rounded-0 border-right-0 h-auto py-2"
                                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        name="search_city" id="search_city"
                                        [innerHTML]="selectedcity != '' ? selectedcity : 'City'">
                                        City
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a *ngFor="let city of cities" class="dropdown-item" href="#"
                                            (click)="set_features(3, city.name)">{{ city.name }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-md-0 mt-3">
                            <div class="input-group">
                                <input [(ngModel)]="search_value" type="text" id="search_text" name="search_text"
                                    class="form-control border-right-0 rounded-0 fs-14 h-auto py-2"
                                    placeholder="What are you looking for?">
                                <div class="input-group-prepend">
                                    <button (click)="search()"
                                        class="input-group-text btn rounded-right border-left-0 bg-white" type="submit">
                                        <img src="assets/images/search.svg" alt=""
                                                width="20">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-xl-5 mt-md-3 mt-lg-0 filter-options">
                <div class="form-row h-100">
                    <div class="col">
                        <button class="btn btn-outline-info fs-12 fw-300 py-2 text-uppercase btn-block h-100"
                            data-toggle="modal" data-target="#moreFilter">
                            <i class="fas fa-filter mr-md-1"></i>
                            <span class="d-none d-md-inline-block d-lg-none d-xl-inline-block">
                                More filters
                            </span>
                        </button>
                    </div>
                    <div class="col">
                        <button class="btn btn-dark fs-12 fw-300 py-2 text-uppercase btn-block h-100 ask-realzo" onclick="$('.chatBot').addClass('active');">
                            <img src="assets/images/realzo.svg" class="mr-md-1 align-middle" alt="" >
                            <span class="d-none d-md-inline-block d-lg-none d-xl-inline-block">
                                Ask RealZo
                            </span>
                        </button>
                    </div>
                    <div class="col">
                        <button class="btn btn-success fs-12 fw-300 py-2 text-uppercase btn-block h-100">
                            <span class="d-md-none d-lg-inline-block d-xl-none">
                                <i class="fas fa-heart mr-md-1 align-middle"></i>
                            </span>
                            <span class="d-none d-md-inline-block d-lg-none d-xl-inline-block">
                                save search
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- TOP SEARCH BAR END HERE -->
<div class="clearfix"></div>
<!-- SEARCH RESULT CONTAINER BEGIN HERE -->
<div class="search-container pt-5">
    <div class="row position-fixed mt-3 mt-md-1 mt-lg-4 sortingOption">
        <div class="col-12 text-right">
            <button class="btn btn-link text-dark">
                <i class="fas fa-list"></i>
            </button>
            <button class="btn btn-link text-dark">
                <i class="fas fa-th"></i>
            </button>
            <button class="btn btn-link text-dark">
                <i class="fas fa-map"></i>
            </button>
        </div>
    </div>
    <div class="row pt-md-5 mt-2">
        <div class="col-xl-7 col-xxl-5 getHeight">
            <!-- SORTING AND SEARCH RESULTS BEGIN HERE -->

            <app-search-list-record [property_type]="p_type" [property_usage]="usage" [search_city]="search_city"
                [search_value]="search_value" [page]="page" [propertyList]="propertyList" (page_changed)='refresh_page($event)' (hovered_property_event)='onhover_property_function($event)'>
            </app-search-list-record>
        </div>
        <div class="col-xl-5 col-md-6 col-xxl-7 d-md-none d-lg-block getHeight2">
            <div class="mapContainer mt-n1">
                <div class="h-100 map position-fixed">
                    <app-search-list-map [property_type]="p_type" [property_usage]="usage" [search_city]="search_city"
                        [search_value]="search_value" [page]="page" [propertyList]="propertyList" (map_center)='search_map($event)'></app-search-list-map>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- SEARCH RESULT CONTAINER END HERE -->

<!-- FILTER MODAL BEGIN HERE -->
<div class="modal fade reachX-modal" id="moreFilter" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content filter-modal">
            <div class="modal-body p-4">
                <button class="close" data-dismiss="modal">
                    <i class="fas fa-times"></i>
                </button>
                <form action="" #filterForm>
                    <div class="row">
                        <div class="col-md-4">
                            <!-- BUDGET SECTION -->
                            <div class="budget-section mb-3">
                                <h6 class="mt-0 mb-2 text-uppercase fs-16 fw-700">
                                    Budget
                                </h6>
                                <div class="price range-slider">
                                    <div class="form-row">
                                        <div class="col-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text bg-transparent border-right-0 input-group-text-border">
                                                        <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" name="min" [(ngModel)]="minPrice"
                                                    class="form-control border-left-0 fs-12 modal-control bg-white px-2"
                                                    placeholder="MIN">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text bg-transparent border-right-0 input-group-text-border">
                                                        <i class="fas fa-rupee-sign"></i>
                                                    </span>
                                                </div>
                                                <input type="text" name="max" [(ngModel)]="maxPrice"
                                                    class="form-control border-left-0 fs-12 modal-control bg-white px-2"
                                                    placeholder="MAX">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <ng5-slider [(value)]="minPrice" [(highValue)]="maxPrice" [options]="price">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- BUDGET SECTION END HERE -->
                            <!-- BEDROOM SECTION BEGIN HERE -->
                            <div class="bedroom-section mb-2">
                                <h6 class="mt-0 mb-2 text-uppercase fs-16 fw-700">
                                    Bedroom/s
                                </h6>
                                <div class="row">
                                    <div class="col-9 position-relative">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-transparent input-group-text-border">
                                                    <i class="fas fa-bed"></i>
                                                </span>
                                            </div>
                                            <input type="text"
                                                class="form-control modal-control fs-12 bg-white border-left-0"
                                                name="bedCount" [(ngModel)]="bedroomCount" value="{{bedroomCount}}">
                                        </div>
                                        <div class="custom-control custom-switch mt-2">
                                            <input type="checkbox" class="custom-control-input" id="exactMatch">
                                            <label class="custom-control-label" for="exactMatch">Use Exact Match</label>
                                        </div>
                                        <div class="position-absolute add-remove-btn">
                                            <button class="btn px-0" (click)="plus()">
                                                <img src="assets/images/icons/plus.svg" alt="">
                                            </button>
                                            <button class="btn px-0" (click)="minus()">
                                                <img src="assets/images/icons/minus.svg" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!-- BEDROOM SECTION END HERE -->
                            <!-- SIZE SECTION BEGIN HERE -->
                            <div class="size-section mb-2">
                                <h6 class="mt-0 mb-2 text-uppercase fs-16 fw-700">
                                    size <small>(sqft)</small>
                                </h6>
                                <div class="size range-slider">
                                    <div class="form-row">
                                        <div class="col-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text bg-transparent border-right-0 input-group-text-border">
                                                        <i class="fas fa-building"></i>
                                                    </span>
                                                </div>
                                                <input type="text" name="minSize" [(ngModel)]="minSize"
                                                    class="form-control border-left-0 fs-12 modal-control bg-white px-2"
                                                    placeholder="MIN">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text bg-transparent border-right-0 input-group-text-border">
                                                        <i class="fas fa-building"></i>
                                                    </span>
                                                </div>
                                                <input type="text" name="maxSize" [(ngModel)]="maxSize"
                                                    class="form-control border-left-0 fs-12 modal-control bg-white px-2"
                                                    placeholder="MAX">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <ng5-slider [(value)]="minSize" [(highValue)]="maxSize" [options]="size">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- SIZE SECTION END HERE -->
                        </div>
                        <div class="col-md-4">
                            <!-- TYPE SECTION BEGIN HERE -->
                            <h6 class="mt-0 mb-2 text-uppercase fs-16 fw-700">
                                Type
                            </h6>
                            <div class="type-section">
                                <div class="top-radios mb-2">
                                    <div class="custom-control custom-radio custom-control-inline ">
                                        <input type="radio" id="residentialRadio" name="propertyType"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="residentialRadio">Residential</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline mr-0">
                                        <input type="radio" id="commercialRadio" name="propertyType"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="commercialRadio">Commercial</label>
                                    </div>
                                </div>
                                <div class="propertyTypes">
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="apratment">
                                            <label class="custom-control-label" for="apratment">Apartment</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="banglow-villa">
                                            <label class="custom-control-label"
                                                for="banglow-villa">Banglow/Villa</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="farmHouse">
                                            <label class="custom-control-label" for="farmHouse">Farm House</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="independentHouse">
                                            <label class="custom-control-label" for="independentHouse">Independent
                                                House</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="plot">
                                            <label class="custom-control-label" for="plot">Plot</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="studio">
                                            <label class="custom-control-label" for="studio">Studio</label>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="floor">
                                            <label class="custom-control-label" for="floor">Floor</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- TYPE SECTION END HERE -->
                        </div>
                        <div class="col-md-4">
                            <!-- FEATURE SECTION BEGIN HERE -->
                            <div class="feature-section">
                                <div class="features mb-3">
                                    <h6 class="mt-0 mb-2 text-uppercase fs-16 fw-700">
                                        features
                                    </h6>
                                    <div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="privatePool">
                                                <label class="custom-control-label" for="privatePool">Private
                                                    Pool</label>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="pool">
                                                <label class="custom-control-label" for="pool">Pool</label>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="privateLawn">
                                                <label class="custom-control-label" for="privateLawn">Private
                                                    Lawn</label>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="basement">
                                                <label class="custom-control-label" for="basement">basement</label>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="servantRoom">
                                                <label class="custom-control-label" for="servantRoom">Servant
                                                    Room</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tours">
                                    <h6 class="mt-0 mb-2 text-uppercase fs-16 fw-700">
                                        Tours
                                    </h6>
                                    <div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="virtual360">
                                                <label class="custom-control-label" for="virtual360">Virtual 360</label>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="streetView">
                                                <label class="custom-control-label" for="streetView">Street View</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- FEATURE SECTION END HERE -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
                            <div class="shadow border card pt-3">
                                <h6 class="text-uppercase fw-700 fs-16 px-3">Location</h6>
                                <div class="form-row px-3">
                                    <div class="col">
                                        <div class="input-group">
                                            <input type="search"
                                                class="form-control bg-white border-right-0 modal-control fs-12"
                                                placeholder="A-Z">
                                            <div class="input-group-append">
                                                <button class="input-group-text bg-white border-left-0">
                                                    <img src="assets/images/search.svg" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="refList">
                                    <ul class="mt-2 mb-0 p-0">
                                        <li class="px-3">
                                            <a href="">Sector 68</a>
                                        </li>
                                        <li class="px-3">
                                            <a href="">Sector 72</a>
                                        </li>
                                        <li class="px-3">
                                            <a href="">Sector 32</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
                            <div class="shadow border card pt-3">
                                <h6 class="text-uppercase fw-700 fs-16 px-3">Builder</h6>
                                <div class="form-row px-3">
                                    <div class="col">
                                        <div class="input-group">
                                            <input type="search"
                                                class="form-control border-right-0 bg-white modal-control fs-12"
                                                placeholder="A-Z">
                                            <div class="input-group-append">
                                                <button class="input-group-text bg-white border-left-0">
                                                    <img src="assets/images/search.svg" alt="">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="refList">
                                    <ul class="mt-2 mb-0 p-0">
                                        <li class="px-3">
                                            <a href="">DLF</a>
                                        </li>
                                        <li class="px-3">
                                            <a href="">Godrej Property</a>
                                        </li>
                                        <li class="px-3">
                                            <a href="">Central Park</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <h6 class="text-uppercase fw-700 fs-16">status</h6>
                                    <div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="ready_to_move">
                                                <label class="custom-control-label" for="ready_to_move">Ready to
                                                    Move</label>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="furnished">
                                                <label class="custom-control-label" for="furnished">Furnished</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6">
                                    <button class="btn btn-block btn-warning py-2 mt-4 text-white fw-400" type="submit">
                                        Let's Reach
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- FILTER MODAL END HERE -->
