<div class="p-3 d-none d-md-block">
    <div class="navbar-brand w-100">
        <div class="media">
            <a routerLink="">
                <img src="assets/images/reach-arrow.png" class="mr-3 img-fluid" style="width: 30px;" alt="">
            </a>
            <div class="media-body align-items-center" style="width: 100px; flex: none;">
                <a routerLink="">
                    <img src="assets/images/reach-text.png" class="img-fluid" alt="">
                </a>
            </div>
        </div>
    </div>
</div>
<div class="card-body p-0">
    <div *ngIf="!adminMenu">
        <div class="menuHeading fs-12 py-2 px-3">
            <span>MAIN</span>
        </div>
        <ul>
            <li>
                <a routerLink="admin/my-reach-x" routerLinkActive="active">
                    <span class="icon">
                        <img src="assets/images/icons/admin-dashboard.svg" alt="">
                    </span>
                    <span class="text">
                        Dashboard
                    </span>
                </a>
            </li>
            <li>
                <a href="post-property#message">
                    <span class="icon">
                        <img src="assets/images/icons/admin-message.svg" alt="">
                    </span>
                    <span class="text">
                        Message
                    </span>
                </a>
            </li>
        </ul>
    </div>
    <div *ngIf="!adminMenu" class="mt-2">
        <div class="menuHeading fs-12 py-2 px-3 text-uppercase">
            <span>Manage Listings</span>
        </div>

        <ul>
            <li>
                <a [ngClass]="{'active': active_menu == 'location'}" (click)="update_active_menu('location')"
                    id="location-link" href="post-property#location"
                    onclick="document.getElementById('locationCollapse').classList.remove('show');document.getElementById('btn-location-section').click();document.getElementById('dealer-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">
                    <span class="icon">
                        <img src="assets/images/icons/location.svg" alt="">
                    </span>
                    <span class="text">
                        Location
                    </span>
                </a>
            </li>
            <li>
                <a [ngClass]="{'active': active_menu == 'basics'}" (click)="update_active_menu('basics')"
                    id="basic-link" href="post-property#basics"
                    onclick="document.getElementById('basicCollapse').classList.remove('show');document.getElementById('btn-basic-section').click();document.getElementById('location-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">
                    <span class="icon">
                        <img src="assets/images/icons/basic.svg" alt="">
                    </span>
                    <span class="text">
                        Basics
                    </span>
                </a>
            </li>
            <li>
                <a [ngClass]="{'active': active_menu == 'property'}" (click)="update_active_menu('property')"
                    id="property-link" href="post-property#property"
                    onclick="document.getElementById('propertyCollapse').classList.remove('show');document.getElementById('btn-property-section').click();document.getElementById('basic-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">
                    <span class="icon">
                        <img src="assets/images/icons/property.svg" alt="">
                    </span>
                    <span class="text">
                        Property
                    </span>
                </a>
            </li>

            <li>
                <a [ngClass]="{'active': active_menu == 'features'}" (click)="update_active_menu('features')"
                    id="featured-link" href="post-property#features"
                    onclick="document.getElementById('featuredCollapse').classList.remove('show');document.getElementById('btn-featured-section').click();document.getElementById('property-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">
                    <span class="icon">
                        <img src="assets/images/icons/feature.svg" alt="">
                    </span>
                    <span class="text">
                        Features
                    </span>
                </a>
            </li>
            <li>
                <a [ngClass]="{'active': active_menu == 'media'}" (click)="update_active_menu('media')" id="media-link"
                    href="post-property#media"
                    onclick="document.getElementById('mediaCollapse').classList.remove('show');document.getElementById('btn-media-section').click();document.getElementById('featured-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">

                    <span class="icon">
                        <img src="assets/images/icons/media.svg" alt="">
                    </span>
                    <span class="text">
                        Media
                    </span>
                </a>
            </li>
            <li>
                <a [ngClass]="{'active': active_menu == 'price'}" (click)="update_active_menu('price')"
                    id="pricing-link" href="post-property#price"
                    onclick="document.getElementById('pricingCollapse').classList.remove('show');document.getElementById('btn-pricing-section').click();document.getElementById('media-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">
                    <span class="icon">
                        <img src="assets/images/icons/price.svg" alt="">
                    </span>
                    <span class="text">
                        Price
                    </span>
                </a>
            </li>
            <li>
                <a [ngClass]="{'active': active_menu == 'profile'}" (click)="update_active_menu('profile')"
                    id="profile-link" href="post-property#profile"
                    onclick="document.getElementById('profileCollapse').classList.remove('show');document.getElementById('btn-profile-section').click();document.getElementById('pricing-section').scrollIntoView({ behavior: 'smooth', block: 'start' }); return false;">
                    <span class="icon">
                        <img src="assets/images/icons/profile.svg" alt="">
                    </span>
                    <span class="text">
                        Profile
                    </span>
                </a>
            </li>

            <li>
                <a [ngClass]="{'active': active_menu == 'publish'}" (click)="update_active_menu('publish')"
                    id="publish-link" href="post-property#publish"
                    onclick="document.getElementById('publishCollapse').classList.remove('show');document.getElementById('btn-publish-section').click();document.getElementById('profile-section').scrollIntoView({ behavior: 'smooth', block: 'start' });return false;">

                    <span class="icon">
                        <img src="assets/images/icons/publish.svg" alt="">
                    </span>
                    <span class="text">
                        Publish
                    </span>
                </a>
            </li>
        </ul>
    </div>
    <div *ngIf="!adminMenu" class="mt-2">
        <div class="menuHeading fs-12 py-2 px-3 text-uppercase">
            <span>Listing Completed</span>
        </div>
        <!-- <div class="progress-circle">
            <div class="progress green">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">{{complete_percent}}%</div>
            </div>
        </div> -->
        <circle-progress [percent]="complete_percent" [radius]="50" [outerStrokeWidth]="12" [innerStrokeWidth]="6" [titleColor]="'#FFFFFF'"
            [outerStrokeColor]="'#78C000'" [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300">
        </circle-progress>
    </div>
    <div *ngIf="adminMenu">
        <div class="menuHeading fs-12 py-2 px-3">
            <span>MAIN</span>
        </div>
        <ul>
            <li>
                <a routerLink="admin/my-reach-x" routerLinkActive="active">
                    <span class="icon">
                        <img src="assets/images/icons/my-reach-x.svg" alt="">
                    </span>
                    <span class="text">
                        My Reach-x
                    </span>
                </a>
            </li>
            <li>
                <a routerLink="admin/my-listing" routerLinkActive="active">
                    <span class="icon">
                        <img src="assets/images/icons/listings.svg" alt="">
                    </span>
                    <span class="text">
                        Listings
                    </span>
                </a>
            </li>
            <li>
                <a routerLink="admin/contacts" routerLinkActive="active">
                    <span class="icon">
                        <img src="assets/images/icons/contacts.svg" alt="">
                    </span>
                    <span class="text">
                        Contacts
                    </span>
                </a>
            </li>
            <li>
                <a href="#">
                    <span class="icon">
                        <img src="assets/images/icons/admin-message.svg" alt="">
                    </span>
                    <span class="text">
                        Messages
                    </span>
                </a>
            </li>
            <li>
                <a routerLink="admin/plans" routerLinkActive="active">
                    <span class="icon">
                        <img src="assets/images/icons/plans.svg" alt="">
                    </span>
                    <span class="text">
                        Plans
                    </span>
                </a>
            </li>
            <li>
                <a href="#">
                    <span class="icon">
                        <img src="assets/images/icons/lead.svg" alt="">
                    </span>
                    <span class="text">
                        Leads
                    </span>
                </a>
            </li>
            <li>
                <a href="#">
                    <span class="icon">
                        <img src="assets/images/icons/services.svg" alt="">
                    </span>
                    <span class="text">
                        Services
                    </span>
                </a>
            </li>
        </ul>
    </div>
    <div *ngIf="adminMenu">
        <div class="menuHeading fs-12 py-2 px-3">
            <span>Account</span>
        </div>
        <ul>
            <li>
                <a routerLink="admin/my-reach-x">
                    <span class="icon">
                        <img src="assets/images/icons/profile.svg" alt="">
                    </span>
                    <span class="text">
                        Profile
                    </span>
                </a>
            </li>
            <li>
                <a href="#">
                    <span class="icon">
                        <img src="assets/images/icons/settings.svg" alt="">
                    </span>
                    <span class="text">
                        Settings
                    </span>
                </a>
            </li>
            <li>
                <a href="#">
                    <span class="icon">
                        <img src="assets/images/icons/logout.svg" alt="">
                    </span>
                    <span class="text">
                        Logout
                    </span>
                </a>
            </li>
        </ul>
    </div>
</div>