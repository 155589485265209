import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiInterface } from '../../interface/api-interface';
import { apiUrl } from '../api-url';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class refozoDBAPIService {

  get(query_params): Observable<apiInterface> {
    query_params = query_params.append('_session', this.cookieService.get('_session'));
    return this.http.get<apiInterface>(this.apiUrl.BASE_GET_URL, { params: query_params })
  }
  post(query_params, post_data): Observable<apiInterface> {
    query_params = query_params.append('_session', this.cookieService.get('_session'));
    var queryString = Object.keys(post_data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(post_data[key])).join('&');
    const httpOptions = {params: query_params,
      // withCredentials: true,
      // headers: new HttpHeaders({
      //   'Content-Type':  'application/x-www-form-urlencoded'})
      }

    return this.http.post<apiInterface>(this.apiUrl.BASE_POST_URL, queryString, httpOptions)
  }

  constructor(private http: HttpClient, private apiUrl: apiUrl, private cookieService: CookieService) { }
}
