import { Component, OnInit } from "@angular/core";
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { apiUrl } from '../../services/api-url';

@Component({
  selector: 'app-test-media',
  templateUrl: './test-media.component.html',
  styleUrls: ['./test-media.component.css']
})
export class TestMediaComponent implements OnInit {
  fileQueue = 0;
  progress = [0, 0, 0, 0, 0];
  uploadedSize = ['0', '0', '0', '0', '0'];
  fileSize = ['0', '0', '0', '0', '0'];
  infoMessage: any;
  isUploading: boolean = false;
  fileList: FileList;
  medias: any;
  imageUrl: any = '';
  fileName = '';
  imageUrls: string[] = ['', '', '', '', ''];
  uploadCount = 0;
  deleteImages = [];

  fileNames = ["No file selected",
    "No file selected",
    "No file selected",
    "No file selected",
    "No file selected"]

  getmedia(): void {
    let query_params = new HttpParams();

    query_params = query_params.append('api', 'get_mediadata');
    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.medias = refozoDBAPIService);
  }

  constructor(private refozoDBAPIService: refozoDBAPIService, private http: HttpClient, private cookieService: CookieService, private apiUrl: apiUrl) { }

  ngOnInit() {
    // this.uploader.progressSource.subscribe(progress => {
    //   this.progress = progress;
    // });
    this.getmedia();

  }

  onChange(fileList: FileList) {
    this.progress = [0, 0, 0, 0, 0];
    this.imageUrls = ['', '', '', '', ''];
    this.uploadedSize = ['0', '0', '0', '0', '0'];
    this.fileSize = ['0', '0', '0', '0', '0'];

    this.fileNames = ["No file selected",
      "No file selected",
      "No file selected",
      "No file selected",
      "No file selected"]

    if (fileList) {
      var file_count = 5;
      if (fileList.length < file_count) {
        file_count = fileList.length;
      }
      this.fileQueue = file_count;
      var readers0 = new FileReader();
      var readers1 = new FileReader();
      var readers2 = new FileReader();
      var readers3 = new FileReader();
      var readers4 = new FileReader();

      this.fileList = fileList;
      for (var i = 0; i < file_count; i++) {
        this.fileNames[i] = fileList[i].name;
        this.fileSize[i] = ((fileList[i].size / 1024) / 1024).toFixed(4);

        switch (i) {
          case 0:
            readers0.readAsDataURL(fileList[0]);
            readers0.onload = event => {
              this.imageUrls[0] = <string>readers0.result;
            };
            break;
          case 1:
            readers1.readAsDataURL(fileList[1]);
            readers1.onload = event => {
              this.imageUrls[1] = <string>readers1.result;
            };
            break;
          case 2:
            readers2.readAsDataURL(fileList[2]);
            readers2.onload = event => {
              this.imageUrls[2] = <string>readers2.result;
            };
            break;
          case 3:
            readers3.readAsDataURL(fileList[3]);
            readers3.onload = event => {
              this.imageUrls[3] = <string>readers3.result;
            };
            break;
          case 4:
            readers4.readAsDataURL(fileList[4]);
            readers4.onload = event => {
              this.imageUrls[4] = <string>readers4.result;
            };
            break;
        }

      }
    }
  }

  uploader(i) {
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'upload_mediadata');
    let post_data = {
      'imgData': encodeURIComponent(<string>this.imageUrls[i]),
      'imgId': i
    }
    this.progress[i] = 0;
    this.uploadedSize[i] = (parseFloat(this.fileSize[i]) * this.progress[i] / 100).toFixed(4);

    query_params = query_params.append('_session', this.cookieService.get('_session'));
    var queryString = Object.keys(post_data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(post_data[key])).join('&');

    this.http.post(this.apiUrl.BASE_POST_URL, queryString, {
      params: query_params,
      reportProgress: true,
      observe: 'events'
    }).subscribe(response => {
      if (response.type === HttpEventType.Response) {
        console.log(i, response, 'Upload complete');
      }
      if (response.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * response.loaded / response.total);
        this.progress[i] = percentDone;
        this.uploadedSize[i] = (parseFloat(this.fileSize[i]) * this.progress[i] / 100).toFixed(4);
      }
      this.isUploading = false;
      this.progress[<any>response] = 100;
      this.uploadedSize[<any>response] = this.fileSize[<any>response];
      // this.infoMessage = message;
    },
      error => console.log(error),
      () => {
        this.progress[i] = 100;
        this.uploadCount = this.uploadCount + 1;
        if (this.uploadCount == this.fileQueue) {
          this.getmedia();
          this.progress = [0, 0, 0, 0, 0];
          this.imageUrls = ['', '', '', '', ''];
          this.uploadedSize = ['0', '0', '0', '0', '0'];
          this.fileSize = ['0', '0', '0', '0', '0'];

          this.fileNames = ["No file selected",
            "No file selected",
            "No file selected",
            "No file selected",
            "No file selected"]

        }
        // this.imageUrls[i] = '';
      }

    )
  }

  onUpload() {
    this.uploadCount = 0;
    this.infoMessage = null;
    this.progress[0] = 0;
    this.isUploading = true;
    i = 0;
    // 
    for (var i = 0; i < this.imageUrls.length; i++) {
      if (this.imageUrls[i] != '') {
        this.uploader(i);
      }

    }
    if (this.deleteImages.length > 0) {
      let query_params = new HttpParams();
      query_params = query_params.append('api', 'delete_mediadata');
      let post_data = {
        'del_ids': this.deleteImages.toString()
      }

      this.refozoDBAPIService.post(query_params, post_data)
        .subscribe(response => {
          this.isUploading = false
        },
          error => console.log(error),
          () => {
            this.deleteImages = [];
            this.getmedia();
          }

        )
    }

  }
  trashImage(id) {
    this.deleteImages.push(id);
  }
}