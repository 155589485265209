<div class="dashboard-container listings position-fixed">
    <div class="w-100">
        <div class="card border-0 rounded-0 p-3">
            <h5 class="text-info fw-700 text-uppercase">
                <span class="fw-400">My</span> Listings
            </h5>
            <div class="row listing-dropdowns">
                <div class="col-md-6">
                    <div class="btn-group">
                        <div class="btn-group">
                            <button id="status" class="btn btn-outline dropdown-toggle pr-5 custom-select"
                                data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Status</button>
                            <div class="dropdown-menu" aria-labelledby="status">
                                <a href="" class="dropdown-item">Active</a>
                                <a href="" class="dropdown-item">Expire</a>
                                <a href="" class="dropdown-item">Deleted</a>
                                <a href="" class="dropdown-item">Hidden</a>
                                <a href="" class="dropdown-item">Under Review</a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button id="type" class="btn btn-outline dropdown-toggle pr-5 custom-select"
                                data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Type</button>
                            <div class="dropdown-menu" aria-labelledby="type">
                                <a href="" class="dropdown-item">Residential</a>
                                <a href="" class="dropdown-item">Commercial</a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button id="category" class="btn btn-outline dropdown-toggle pr-5 custom-select"
                                data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Category</button>
                            <div class="dropdown-menu" aria-labelledby="category">
                                <a href="" class="dropdown-item">Sale</a>
                                <a href="" class="dropdown-item">Rent</a>
                                <a href="" class="dropdown-item">Lease</a>
                                <a href="" class="dropdown-item">Sharing</a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button id="plan" class="btn btn-outline dropdown-toggle pr-5 custom-select"
                                data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Plan</button>
                            <div class="dropdown-menu" aria-labelledby="plan">
                                <a href="" class="dropdown-item">Basic</a>
                                <a href="" class="dropdown-item">Silver</a>
                                <a href="" class="dropdown-item">Gold</a>
                                <a href="" class="dropdown-item">Platinum</a>
                                <a href="" class="dropdown-item">Daimond</a>
                                <a href="" class="dropdown-item">Featured Listing</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 text-right">
                    <div class="btn-group">
                        <button id="sorting" class="btn btn-dark dropdown-toggle pr-5 custom-select-white"
                            data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">Sort</button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sorting"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="project-status listings">
    <div class="row">
        <!-- ACTIVE CARD BEGIN HERE -->
        <div class="col-lg-6 mb-4" *ngFor="let p of properties">
            <div class="card p-3 shadow">
                <div class="status active">
                    <div class="media mb-4">
                        <div class="media-thumbnail">
                            <div class="item-img">
                                <div id="carouselControls{{p.row_id}}" class="carousel slide h-100" data-ride="carousel"
                                    data-interval="false">
                                    <div class="carousel-inner h-100">
                                        <div class="carousel-item h-100"
                                            *ngFor="let m of p.media_thumbnails.split(','); let i = index"
                                            [ngClass]="{'active': i == 0}">
                                            <img src="{{m}}" class="img-fluid" alt=""
                                                onerror="this.onerror=null;this.src='assets/images/services/home-loan.jpg';">
                                        </div>
                                        <div *ngIf="p.media_thumbnails.split(',')[0].length==0"
                                            class="carousel-item h-100">
                                            <img src="assets/images/services/home-loan.jpg" class="img-fluid" alt="">
                                        </div>
                                        <div *ngIf="p.media_thumbnails.split(',').length==1"
                                            class="carousel-item h-100">
                                            <img src="{{p.media_thumbnails.split(',')[0]}}" class="img-fluid" alt="">
                                        </div>

                                    </div>
                                    <div class="carousel-controls">
                                        <a class="carousel-control-prev" href="#carouselControls{{p.row_id}}"
                                            role="button" data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselControls{{p.row_id}}"
                                            role="button" data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-body py-3 p-md-3 position-relative">
                            <span
                                class="badge badge-pill bg-success position-absolute propertyType fw-300 text-uppercase p-2 text-white"
                                style="z-index:1">{{p.building_type_desc}}</span>
                            <div class="row">
                                <div class="col-10 col-md-12">
                                    <h6 class="text-info fs-14 fw-700 mb-1">
                                        <a routerLink="airia-mall" class="text-info" target="_blank">
                                            {{p.building_subtype_desc}}
                                        </a>
                                    </h6>
                                    <small class="fs-12">{{p.location_address}}, {{p.location_city}}</small>
                                    <div class="mt-3 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Price</div>
                                        <div class="col text-truncate">{{p.price_expected}} Lacs</div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Unit</div>
                                        <div class="col text-truncate">
                                            {{p.building_subtype_desc}}
                                        </div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Size</div>
                                        <div class="col text-truncate">{{p.super_area}} Sq Ft</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card p-3 border-0 rounded-0">
                        <div class="row">
                            <div class="col-md-6 text-center mb-3 mb-md-0">
                                <div class="btn status px-4 text-white text-uppercase fs-12">{{p.publish_status}}</div>
                                <div class="mt-3">
                                    <!-- <div class="progress-circle">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    80% <br>
                                                    <span class="fw-300 fs-10">Completed</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div> -->
                                    <circle-progress [percent]="p.percentage" [radius]="50" [outerStrokeWidth]="12"
                                        [innerStrokeWidth]="6" [titleColor]="'#000000'" [outerStrokeColor]="'#78C000'"
                                        [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300">
                                    </circle-progress>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <h6 class="fs-14 fw-500">{{p.publish_remark}} {{p.row_creation_time}}</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white" (click)="edit_property(p.row_id)">
                                                <img src="assets/images/icons/edit.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white" (click)="delete_property(p.row_id)">
                                                <img src="assets/images/icons/trash.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white" (click)="toggle_display_setting(p.row_id)">
                                                <svg *ngIf="p.location_address_display=='False'" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-eye-fill"
                                                    fill="green" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path fill-rule="evenodd"
                                                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                                <svg *ngIf="p.location_address_display=='True'" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-eye-slash-fill" fill="red" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z"/>
                                                    <path fill-rule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"/>
                                                  </svg>
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Expires {{p.expiry}}</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/extand.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/question.svg" width="16" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Services</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/leads.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/boost.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/advertise.svg" width="16" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card bg-white py-3 border-0 rounded-0 project-views-response-qualified">
                        <div class="row align-items-center">
                            <div class="col-md-8 col-lg-7 col-xl-8">
                                <div class="media ml-xl-5">
                                    <div class="progress-circle mr-3">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    2 <br>
                                                    <span class="fw-300 fs-10">New</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <div>
                                            <div><span class="fw-500">{{p.views}}</span> Views</div>
                                            <div><span class="fw-500">{{p.responses}}</span> Responses</div>
                                            <div><span class="fw-500">{{p.leads}}</span> Qualified Leads</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-5 col-xl-4 mt-3 mt-md-0 views-contact-btns text-right">
                                <button class="btn bg-white shadow-sm mb-0 mb-md-3 float-left float-md-none">
                                    <img src="assets/images/icons/view.svg" width="18" class="mr-1" alt=""> Views
                                </button>
                                <button class="btn bg-white shadow-sm">
                                    <img src="assets/images/icons/contact-blue.svg" width="18" class="mr-1" alt="">
                                    Contacts
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ACTIVE CARD END HERE -->
        <!-- EXPIRE CARD BEGIN HERE -->
        <!-- <div class="col-lg-6 mb-4">
            <div class="card p-3 shadow">
                <div class="status expire">
                    <div class="media mb-4">
                        <div class="media-thumbnail">
                            <div class="item-img">
                                <div id="carouselControls" class="carousel slide h-100" data-ride="carousel"
                                    data-interval="false">
                                    <div class="carousel-inner h-100">
                                        <div class="carousel-item h-100 active">
                                            <img src="assets/images/property/airia-mall/airia-mall.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-2.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-3.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-4.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="carousel-controls">
                                        <a class="carousel-control-prev" href="#carouselControls" role="button"
                                            data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselControls" role="button"
                                            data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-body  py-3 p-md-3 position-relative">
                            <span class="badge badge-pill bg-success position-absolute propertyType fw-300 text-uppercase p-2 text-white"
                                style="z-index:1">Freehold</span>
                            <div class="row">
                                <div class="col-10 col-md-12">
                                    <h6 class="text-info fs-14 fw-700 mb-1">
                                        <a routerLink="airia-mall" class="text-info" target="_blank">
                                            Airia Mall
                                        </a>
                                    </h6>
                                    <small class="fs-12">Sector 68, Gurgaon</small>
                                    <div class="mt-3 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Price</div>
                                        <div class="col text-truncate">12 Lacs to 30 Lacs</div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Unit</div>
                                        <div class="col text-truncate">
                                            Retail Shops
                                        </div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Size</div>
                                        <div class="col text-truncate">195 Sq Ft to 580 Sq Ft</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card p-3 border-0 rounded-0">
                        <div class="row">
                            <div class="col-md-6 text-center mb-3 mb-md-0">
                                <div class="btn status px-4 text-white text-uppercase fs-12">Expired</div>
                                <div class="mt-3">
                                    <div class="progress-circle">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    80% <br>
                                                    <span class="fw-300 fs-10">Completed</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <h6 class="fs-14 fw-500">Posted July 18, 2020</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/edit.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/trash.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/visibility.svg" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Expires Aug 17, 2020</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/extand.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/question.svg" width="16" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Services</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/leads.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/boost.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/advertise.svg" width="16" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card bg-white py-3 border-0 rounded-0 project-views-response-qualified">
                        <div class="row align-items-center">
                            <div class="col-md-8 col-lg-7 col-xl-8">
                                <div class="media ml-xl-5">
                                    <div class="progress-circle mr-3">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    0 <br>
                                                    <span class="fw-300 fs-10">New</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <div>
                                            <div><span class="fw-500">15</span> Views</div>
                                            <div><span class="fw-500">6</span> Responses</div>
                                            <div><span class="fw-500">2</span> Qualified Leads</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-5 col-xl-4 mt-3 mt-md-0 views-contact-btns text-right">
                                <button class="btn bg-white shadow-sm mb-0 mb-md-3 float-left float-md-none">
                                    <img src="assets/images/icons/view.svg" width="18" class="mr-1" alt=""> Views
                                </button>
                                <button class="btn bg-white shadow-sm">
                                    <img src="assets/images/icons/contact-blue.svg" width="18" class="mr-1" alt=""> Contacts
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- EXPIRE CARD END HERE -->
        <!-- DELETE CARD BEGIN HERE -->
        <!-- <div class="col-lg-6 mb-4">
            <div class="card p-3 shadow">
                <div class="status delete">
                    <div class="media mb-4">
                        <div class="media-thumbnail">
                            <div class="item-img">
                                <div id="carouselControls" class="carousel slide h-100" data-ride="carousel"
                                    data-interval="false">
                                    <div class="carousel-inner h-100">
                                        <div class="carousel-item h-100 active">
                                            <img src="assets/images/property/airia-mall/airia-mall.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-2.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-3.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-4.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="carousel-controls">
                                        <a class="carousel-control-prev" href="#carouselControls" role="button"
                                            data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselControls" role="button"
                                            data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-body py-3 p-md-3 position-relative">
                            <span class="badge badge-pill bg-success position-absolute propertyType fw-300 text-uppercase p-2 text-white"
                                style="z-index:1">new project</span>
                            <div class="row">
                                <div class="col-10 col-md-12">
                                    <h6 class="text-info fs-14 fw-700 mb-1">
                                        <a routerLink="airia-mall" class="text-info" target="_blank">
                                            Airia Mall
                                        </a>
                                    </h6>
                                    <small class="fs-12">Sector 68, Gurgaon</small>
                                    <div class="mt-3 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Price</div>
                                        <div class="col text-truncate">12 Lacs to 30 Lacs</div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Unit</div>
                                        <div class="col text-truncate">
                                            Retail Shops
                                        </div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Size</div>
                                        <div class="col text-truncate">195 Sq Ft to 580 Sq Ft</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card p-3 border-0 rounded-0">
                        <div class="row">
                            <div class="col-md-6 text-center mb-3 mb-md-0">
                                <div class="btn status px-4 text-white text-uppercase fs-12">Deleted</div>
                                <div class="mt-3">
                                    <div class="progress-circle">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    80% <br>
                                                    <span class="fw-300 fs-10">Completed</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <h6 class="fs-14 fw-500">Posted July 18, 2020</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/edit.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/trash.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/visibility.svg" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Expires Aug 17, 2020</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/extand.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/question.svg" width="16" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Services</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/leads.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/boost.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/advertise.svg" width="16" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card bg-white py-3 border-0 rounded-0 project-views-response-qualified">
                        <div class="row align-items-center">
                            <div class="col-md-8 col-lg-7 col-xl-8">
                                <div class="media ml-xl-5">
                                    <div class="progress-circle mr-3">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    0 <br>
                                                    <span class="fw-300 fs-10">New</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <div>
                                            <div><span class="fw-500">15</span> Views</div>
                                            <div><span class="fw-500">6</span> Responses</div>
                                            <div><span class="fw-500">2</span> Qualified Leads</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-5 col-xl-4 mt-3 mt-md-0 views-contact-btns text-right">
                                <button class="btn bg-white shadow-sm mb-0 mb-md-3 float-left float-md-none">
                                    <img src="assets/images/icons/view.svg" width="18" class="mr-1" alt=""> Views
                                </button>
                                <button class="btn bg-white shadow-sm">
                                    <img src="assets/images/icons/contact-blue.svg" width="18" class="mr-1" alt=""> Contacts
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- DELETE CARD END HERE -->
        <!-- HIDDEN CARD BEGIN HERE -->
        <!-- <div class="col-lg-6 mb-4">
            <div class="card p-3 shadow">
                <div class="status hiddenProject">
                    <div class="media mb-4">
                        <div class="media-thumbnail">
                            <div class="item-img">
                                <div id="carouselControls" class="carousel slide h-100" data-ride="carousel"
                                    data-interval="false">
                                    <div class="carousel-inner h-100">
                                        <div class="carousel-item h-100 active">
                                            <img src="assets/images/property/airia-mall/airia-mall.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-2.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-3.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                        <div class="carousel-item h-100">
                                            <img src="assets/images/property/airia-mall/airia-mall-4.jpg" class="img-fluid"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="carousel-controls">
                                        <a class="carousel-control-prev" href="#carouselControls" role="button"
                                            data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselControls" role="button"
                                            data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-body py-3 p-md-3 position-relative">
                            <span class="badge badge-pill bg-success position-absolute propertyType fw-300 text-uppercase p-2 text-white"
                                style="z-index:1">Freehold</span>
                            <div class="row">
                                <div class="col-10 col-md-12">
                                    <h6 class="text-info fs-14 fw-700 mb-1">
                                        <a routerLink="airia-mall" class="text-info" target="_blank">
                                            Airia Mall
                                        </a>
                                    </h6>
                                    <small class="fs-12">Sector 68, Gurgaon</small>
                                    <div class="mt-3 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Price</div>
                                        <div class="col text-truncate">12 Lacs to 30 Lacs</div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Unit</div>
                                        <div class="col text-truncate">
                                            Retail Shops
                                        </div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600 fixed-width-column">Size</div>
                                        <div class="col text-truncate">195 Sq Ft to 580 Sq Ft</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card p-3 border-0 rounded-0">
                        <div class="row">
                            <div class="col-md-6 text-center mb-3 mb-md-0">
                                <div class="btn status px-4 text-white text-uppercase fs-12">Hidden</div>
                                <div class="mt-3">
                                    <div class="progress-circle">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    80% <br>
                                                    <span class="fw-300 fs-10">Completed</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <h6 class="fs-14 fw-500">Posted July 18, 2020</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/edit.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/trash.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/visibility.svg" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Expires Aug 17, 2020</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/extand.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/question.svg" width="16" alt="">
                                            </button>
                                        </div>
                                        <h6 class="fs-14 fw-500">Services</h6>
                                        <div class="btn-group btn-group-lg mb-3">
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/leads.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/boost.svg" width="16" alt="">
                                            </button>
                                            <button class="btn bg-white">
                                                <img src="assets/images/icons/advertise.svg" width="16" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card bg-white py-3 border-0 rounded-0 project-views-response-qualified">
                        <div class="row align-items-center">
                            <div class="col-md-8 col-lg-7 col-xl-8">
                                <div class="media ml-xl-5">
                                    <div class="progress-circle mr-3">
                                        <div class="progress">
                                            <span class="progress-left">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <span class="progress-right">
                                                <span class="progress-bar"></span>
                                            </span>
                                            <div class="progress-value">
                                                <span class="value-wrapper fw-500">
                                                    0 <br>
                                                    <span class="fw-300 fs-10">New</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-body align-self-center">
                                        <div>
                                            <div><span class="fw-500">15</span> Views</div>
                                            <div><span class="fw-500">6</span> Responses</div>
                                            <div><span class="fw-500">2</span> Qualified Leads</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-5 col-xl-4 mt-3 mt-md-0 views-contact-btns text-right">
                                <button class="btn bg-white shadow-sm mb-0 mb-md-3 float-left float-md-none">
                                    <img src="assets/images/icons/view.svg" width="18" class="mr-1" alt=""> Views
                                </button>
                                <button class="btn bg-white shadow-sm">
                                    <img src="assets/images/icons/contact-blue.svg" width="18" class="mr-1" alt=""> Contacts
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- HIDDEN CARD END HERE -->
    </div>
</div>