// var BASE_URL='https://vvw5u4nhii.execute-api.us-east-1.amazonaws.com/refozo_db_api';
// var BASE_GET_URL='https://api.refozo.com/Stage/refozo_get';
// var BASE_POST_URL='https://api.refozo.com/Stage/refozo_post';
// var BASE_URL='https://api.refozo.com/Stage/refozo_db_api';

// var BASE_GET_URL='https://api.refozo.com/refozo_get';
// var BASE_POST_URL='https://api.refozo.com/refozo_post';
// var BASE_URL='https://api.refozo.com/refozo_db_api';

import { environment } from '../../environments/environment';

var BASE_GET_URL=environment.BASE_GET_URL;
var BASE_POST_URL=environment.BASE_POST_URL;
var BASE_URL=environment.BASE_URL;

export class apiUrl {
    url: any = {
        get_cities_api : BASE_GET_URL + "?api=get_city_api",
        get_all_properties_api : BASE_GET_URL + "?api=get_all_properties_api"
    }
    BASE_URL = BASE_URL
    BASE_GET_URL = BASE_GET_URL
    BASE_POST_URL = BASE_POST_URL
}