import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-reach-confidence',
  templateUrl: './reach-confidence.component.html',
  styleUrls: ['./reach-confidence.component.css']
})
export class ReachConfidenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
