<nav class="reachPropertiesMenu nav-scroll">
    <ul class="nav nav-tabs" id="nav-exclusiveProperties-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" id="nav-all-tab" data-toggle="tab" href="#nav-all" role="tab" aria-controls="nav-all" aria-selected="true">
                All
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="nav-office-tab" data-toggle="tab" href="#nav-office" role="tab" aria-controls="nav-office" aria-selected="false">
                Office
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="nav-apartment-tab" data-toggle="tab" href="#nav-apartment" role="tab" aria-controls="nav-apartment" aria-selected="false">
                Apartment
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="nav-plot-tab" data-toggle="tab" href="#nav-plot" role="tab" aria-controls="nav-plot" aria-selected="false">
                Plot
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="nav-retail-tab" data-toggle="tab" href="#nav-retail" role="tab" aria-controls="nav-retail" aria-selected="false">
                Retail
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="nav-industrial-tab" data-toggle="tab" href="#nav-industrial" role="tab" aria-controls="nav-industrial" aria-selected="false">
                Industrial
            </a>
        </li>
    </ul>
</nav>
<div class="tab-content" id="nav-exclusiveProperties-item">
    <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let allProperty of exclusiveAllProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{allProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{allProperty.propertyId}}" class="text-info">{{allProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{allProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{allProperty.propertyPrice[0].min}} to {{allProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{allProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{allProperty.propertySize[0].min}} to {{allProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{allProperty.propertyId}}"  class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="nav-office" role="tabpanel" aria-labelledby="nav-office-tab">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let officeProperty of exclusiveOfficeProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{officeProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{officeProperty.propertyId}}" class="text-info">{{officeProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{officeProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{officeProperty.propertyPrice[0].min}} to {{officeProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{officeProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{officeProperty.propertySize[0].min}} to {{officeProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{officeProperty.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="nav-apartment" role="tabpanel" aria-labelledby="nav-apartment-tab">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let apartmentProperty of exclusiveApartmentProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{apartmentProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{apartmentProperty.propertyId}}" class="text-info">{{apartmentProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{apartmentProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{apartmentProperty.propertyPrice[0].min}} to {{apartmentProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{apartmentProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{apartmentProperty.propertySize[0].min}} to {{apartmentProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{apartmentProperty.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="nav-plot" role="tabpanel" aria-labelledby="nav-plot-tab">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let plotProperty of exclusivePlotProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{plotProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{plotProperty.propertyId}}" class="text-info">{{plotProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{plotProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{plotProperty.propertyPrice[0].min}} to {{plotProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{plotProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{plotProperty.propertySize[0].min}} to {{plotProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{plotProperty.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="nav-retail" role="tabpanel" aria-labelledby="nav-retail-tab">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let rentalProperty of exclusiveRentalProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{rentalProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{rentalProperty.propertyId}}" class="text-info">{{rentalProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{rentalProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{rentalProperty.propertyPrice[0].min}} to {{rentalProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{rentalProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{rentalProperty.propertySize[0].min}} to {{rentalProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{rentalProperty.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="nav-industrial" role="tabpanel" aria-labelledby="nav-industrial-tab">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let industrialProperty of exclusiveIndustrialProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{industrialProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{industrialProperty.propertyId}}" class="text-info">{{industrialProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{industrialProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{industrialProperty.propertyPrice[0].min}} to {{industrialProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{industrialProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-2 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{industrialProperty.propertySize[0].min}} to {{industrialProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton"  tooltip="Find More" placement="top">
                                <a routerLink="{{industrialProperty.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>


