import { Component, OnInit, AfterViewChecked, OnDestroy, Renderer2 } from '@angular/core';
import { HeaderService } from './services/header/header.service';
import { SidebarService } from './services/sidebar/sidebar.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'reach-x';


  sidebarActive : boolean = false;
  postPropertyPage : boolean = false;
  headerCollapsed : boolean = false;

  constructor(private _headerService : HeaderService, private _sidebarService : SidebarService, private renderer : Renderer2){
    
  }


  ngOnInit() : void {
    this._headerService.postPropertyHeader.subscribe(res => {
      this.postPropertyPage = res;
    });
    this._sidebarService.sidebarActive.subscribe(res => {
      this.sidebarActive = res;
    });
    this._headerService.headerCollapse = !this.headerCollapsed;
  }
  

  ngOnDestroy(): void {
    
  }
}

