<div class="row">
    <div class="container py-5 text-center">
        <h3 class="text-info fw-700 text-uppercase"><span class="fw-300">Sell your property</span> with Confidence</h3>
        <p class="fs-20 text-gray">Reach-X is making the property posting, selling and renting process simpler.</p>
    </div>
</div>
<div class="row">
    <div class="container">
        <div class="row d-flex">
            <div class="col-lg-7 order-2 order-lg-0">
                <div class="media mb-4">
                    <div class="bg-white rounded shadow p-3 mr-4">
                        <img src="assets/images/icons/help.svg" alt="">
                    </div>
                    <div class="media-body">
                        <h5 class="fs-20 text-warning">Discover how Reach-X can help</h5>
                        <p>From home valuation, personal assistance, 3D and Video walkthroughs, handling leads/offers to
                            preparing your property for sale or rent, Reach-X experts can handle it all. Contact us to
                            know more. </p>
                    </div>
                </div>
                <div class="media mb-4">
                    <div class="bg-white rounded shadow p-3 mr-4">
                        <img src="assets/images/icons/familiar-process.svg" alt="">
                    </div>
                    <div class="media-body">
                        <h5 class="fs-20 text-warning">Get familiar with the process</h5>
                        <p>Owners - Sell or rent with confidence by knowing your property value, best time to put it on
                            market and understand the process. Review our resources for a successful sale. </p>
                    </div>
                </div>
                <div class="media mb-4">
                    <div class="bg-white rounded shadow p-3 mr-4">
                        <img src="assets/images/icons/broker-agent.svg" alt="">
                    </div>
                    <div class="media-body">
                        <h5 class="fs-20 text-warning">Find and Work with a Broker/Agent</h5>
                        <p>Reach-X has a large pool of verified and trusted Agents and Brokers that are best in the
                            industry. Search the one suitable for you. </p>
                    </div>
                </div>
                <div class="media mb-4">
                    <div class="bg-white rounded shadow p-3 mr-4">
                        <img src="assets/images/icons/best-platform.svg" alt="">
                    </div>
                    <div class="media-body">
                        <h5 class="fs-20 text-warning">Give your property the best platform </h5>
                        <p>Builders – Showcase your residential and commercial projects with ease, and reach the largest
                            audience of buyers. Discover how Reach-X can help with all the work from prep to close. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 mb-5 mb-lg-0">
                <div class="row">
                    <div class="col-lg-11 col-md-7 mx-auto">
                        <div class="card p-4 py-4 shadow border-0 loginCard">

                            <div [hidden]="logInDetail">
                                <form class="needs-validation" novalidate>
                                    <h4 class="mb-3">Login to Reach-X</h4>
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="input-prepend property">
                                                        <select name="countryCode" id="countryCode"
                                                            class="custom-select form-control fs-12 fw-500 py-2 h-100 rounded-left rounded-0 border-right-0">
                                                            <option value="">+91</option>
                                                            <option value="">+92</option>
                                                            <option value="">+93</option>
                                                            <option value="">+94</option>
                                                            <option value="">+95</option>
                                                            <option value="">+96</option>
                                                        </select>
                                                    </div>
                                                    <input [(ngModel)]="user_number" name="phone" type="tel"
                                                        maxlength="10" minlength="10"
                                                        class="form-control modal-control fs-12 fw-500 py-2 bg-white"
                                                        placeholder="Enter your number" value="9999999999" required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex form-group mb-0 justify-content-between">
                                        <button class="btn btn-dark mr-2" (click)="otpLogin()"
                                            [disabled]="user_number.length != 10">
                                            Login with OTP
                                        </button>
                                        <button class="btn btn-success" (click)="passwordLogin()"
                                            [disabled]="user_number.length != 10">
                                            Login with Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div [hidden]="otpSec">
                                <form name="otp" action="" class="needs-validation" novalidate>
                                    <h4 class="mb-3">OTP has been sent to </h4>
                                    <div class="mb-3">
                                        +91-{{ user_number }} <button class="btn text-success py-0"
                                            (click)="changeMobNo()">Change</button>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="form-group">
                                                <div class="form-row">
                                                    <div class="col-3">
                                                        <input type="text" value="9" name="firstInput"  [(ngModel)]="otp1" #firstInput
                                                            class="form-control modal-control fs-12 fw-500 py-2 bg-white text-center"
                                                            maxlength="1" minlength="1"
                                                            (keypress)="secInput.focus();"
                                                            required>
                                                    </div>
                                                    <div class="col-3">
                                                        <input type="text" value="9" name="secInput" [(ngModel)]="otp2" #secInput
                                                            class="form-control modal-control fs-12 fw-500 py-2 bg-white text-center"
                                                            maxlength="1" minlength="1"
                                                            (keypress)="thirdInput.focus();"
                                                            required>
                                                    </div>
                                                    <div class="col-3">
                                                        <input type="text" value="9" name="thirdInput" [(ngModel)]="otp3" #thirdInput
                                                            class="form-control modal-control fs-12 fw-500 py-2 bg-white text-center"
                                                            maxlength="1" minlength="1"
                                                            (keypress)="fourthInput.focus();"
                                                            required>
                                                    </div>
                                                    <div class="col-3">
                                                        <input type="text" value="9" name="fourthInput" [(ngModel)]="otp4" #fourthInput
                                                            class="form-control modal-control fs-12 fw-500 py-2 bg-white text-center"
                                                            maxlength="1" minlength="1"
                                                            required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="otpCounter">
                                                <i class="far fa-clock mr-1 fs-14"></i> 00:42
                                            </div>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button class="btn btn-dark px-4" (click)="postProperty('otp')"
                                                [disabled]="otp1 == '' || otp2 == '' || otp3 == '' || otp4 == ''">
                                                Verify
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn bg-transparent px-0 pt-4 pb-0 text-success"
                                                (click)="passwordLogin()">Login with password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div [hidden]="passwordSec">
                                <form action="">
                                    <h4 class="mb-3">Please enter password</h4>
                                    <div class="mb-3">
                                        +91-{{ user_number }} <button class="btn text-success py-0"
                                            (click)="changeMobNo()">Change</button>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="form-group">
                                                <input [(ngModel)]="user_password" type="password" name="password"
                                                    class="form-control modal-control fs-12 fw-500 py-2 bg-white"
                                                    placeholder="Enter password" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-dark px-4" (click)="postProperty('pwd')"
                                                [disabled]="user_password == ''">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn bg-transparent px-0 pt-4 pb-0 text-success"
                                                (click)="otpLogin()">Login with otp</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row sell-building">
    <div class="col-md-12 col-lg-9 col-xl-6 mx-auto ">
        <div class="row align-items-center">
            <div class="col-md-3 text-center order-2 order-md-0">
                <img src="assets/images/expand-reach-mobile.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-9 mb-4 mb-md-0">
                <h5 class="fw-700">You can also get the same <br class="d-none d-md-block"> experience on Reach-X App.
                </h5>
                <div class="mt-4">
                    <a href="" class="d-inline-block mr-1 mr-md-3 mb-2 mb-md-0">
                        <img src="assets/images/google-play-btn.jpg" alt="">
                    </a>
                    <a href="" class="d-inline-block">
                        <img src="assets/images/app-store-btn.jpg" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>