<div class="row">
    <div class="py-3 col-lg-11 mx-auto">
        <div class="row">
            <div class="col-lg-3 mb-3 mb-lg-0">
                <div class="card profileMenu py-2">
                    <div id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <ul class="nav flex-column nav-pills" >
                            <li class="nav-item">
                                <a class="nav-link active" id="v-pills-profile-dashboard-tab" data-toggle="pill" href="#v-pills-profile-dashboard" role="tab" aria-controls="v-pills-profile-dashboard" aria-selected="true">
                                    <span class="icons">
                                        <img src="assets/images/icons/dashboard.svg" alt="">
                                    </span>
                                    <span class="text">Dashboard</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-profile-saved-searches-tab" data-toggle="pill" href="#v-pills-profile-saved-searches" role="tab" aria-controls="v-pills-profile-saved-searches" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-properties.svg" alt="">
                                    </span>
                                    <span class="text">My Saved Searches</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-profile-favorites-tab" data-toggle="pill" href="#v-pills-profile-favorites" role="tab" aria-controls="v-pills-profile-favorites" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-listing.svg" alt="">
                                    </span>
                                    <span class="text">My Favorites</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-profile-properties-tab" data-toggle="pill" href="#v-pills-profile-properties" role="tab" aria-controls="v-pills-profile-properties" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-properties.svg" alt="">
                                    </span>
                                    <span class="text">My Properties</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="v-pills-profile-messages-tab" data-toggle="pill" href="#v-pills-profile-messages" role="tab" aria-controls="v-pills-profile-messages" aria-selected="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/messages.svg" alt="">
                                    </span>
                                    <span class="text">Messages</span>
                                </a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                    <span class="icons">
                                        <img src="assets/images/icons/my-settings.svg" alt="">
                                    </span>
                                    <span class="text">Settings</span>
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Finance</a>
                                    <a class="dropdown-item" href="#">Valuation</a>
                                    <a class="dropdown-item" href="#">e-Payments</a>
                                    <a class="dropdown-item" href="#">Legal</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 mobileDashboard">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-profile-dashboard" role="tabpanel" aria-labelledby="v-pills-profile-dashboard-tab">
                        <app-profile-dashboard></app-profile-dashboard>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile-saved-searches" role="tabpanel" aria-labelledby="v-pills-profile-saved-searches-tab">
                        <app-profile-saved-searches></app-profile-saved-searches>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile-favorites" role="tabpanel" aria-labelledby="v-pills-profile-favorites-tab">
                        <app-profile-favorites></app-profile-favorites>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile-properties" role="tabpanel" aria-labelledby="v-pills-profile-properties-tab">
                        <app-profile-properties></app-profile-properties>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile-messages" role="tabpanel" aria-labelledby="v-pills-profile-messages-tab">
                        <app-profile-messages></app-profile-messages>
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile-services" role="tabpanel" aria-labelledby="v-pills-profile-services-tab">
                        <app-profile-services></app-profile-services>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>