import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router, NavigationExtras, ActivatedRoute, Params } from '@angular/router';
import { FormControl } from '@angular/forms';
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { HttpParams } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.css']
})
export class SearchListComponent implements OnInit {
  selectedcity = "";
  page = 1;
  latitude = "";
  longitude = "";
  cities: {};

  getcity(): void {
    let query_params = new HttpParams();

    query_params = query_params.append('api', 'get_city_api');
    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.cities = refozoDBAPIService);
  }

  search_city = '';
  search_value = '';
  p_type = "";
  property_type = [
    { id: "", name: "Property" },
    { id: "New", name: "New" },
    { id: "Freehold", name: "Freehold" },
    { id: "Resale", name: "Resale" },
    { id: "Rent", name: "Rent" }
  ];

  usage = "";
  property_usage = [
    { id: "", name: "Type" },
    { id: "Residential", name: "Residential" },
    { id: "Commercial", name: "Commercial" }
  ];

  // PRICE RANGE 
  minPrice: number = 15000;
  maxPrice: number = 1000000;
  price: Options = {
    floor: 0,
    ceil: 1000000
  };

  // SIZE RANGE
  minSize: number = 800;
  maxSize: number = 20000;
  size: Options = {
    floor: 0,
    ceil: 20000
  };

  // Bedroom Count
  bedroomCount = 0;

  plus() {
    this.bedroomCount += 1;
  }
  minus() {
    this.bedroomCount -= 1;
    if (this.bedroomCount < 0) {
      this.bedroomCount = 0
    }
  }

  @ViewChild('sticyMap') mapContainer: ElementRef;
  @ViewChild('outerContainer') parentContainer: ElementRef;

  sticky: boolean = true;
  height: any;

  propertyList: {};

  getproperties(): void {
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'get_all_properties_api');
    query_params = query_params.append('property_type', this.p_type);
    query_params = query_params.append('property_usage', this.usage);
    query_params = query_params.append('search_city', this.selectedcity);
    query_params = query_params.append('search_value', this.search_value);
    query_params = query_params.append('longitude', this.longitude);
    query_params = query_params.append('latitude', this.latitude);
    query_params = query_params.append('page', this.page.toString());

    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.propertyList = refozoDBAPIService);
  }


  constructor(private router: Router, private refozoDBAPIService: refozoDBAPIService, private activatedRoute: ActivatedRoute) { }



  ngAfterViewInit() {
    if (this.parentContainer) {
      this.height = this.parentContainer.nativeElement.scrollHeight;
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // handleScroll() {
  //   const windowScroll = window.pageYOffset;
  //   console.log(this.height)
  //   if (windowScroll > this.height) {
  //     this.sticky = false;
  //   } else {
  //     this.sticky = true;
  //   }
  // }



  ngOnInit() {
    this.getcity();
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      this.p_type = params['property_type'];
      this.usage = params['property_usage'];
      this.selectedcity = params['search_city'];
      this.search_value = params['search_value'];
    });

    this.getproperties();

    // this.router.navigate(['/search-list']);
    // 

    $(window).on('scroll', function () {
      var winHeight = $(window).outerHeight();
      winHeight = winHeight - 175;
      var scroll = $(window).scrollTop();
      var getHeight = $('.getHeight').height();
      if (scroll > (getHeight - winHeight)) {
        $('.getHeight2 .map').removeClass('position-fixed').addClass('position-absolute');
        $('#google_div').removeClass('map_div_before_scroll').addClass('map_div_after_scroll');

      } else {
        $('.getHeight2 .map').removeClass('position-absolute').addClass('position-fixed');
        $('#google_div').removeClass('map_div_after_scroll').addClass('map_div_before_scroll');

      }
    })

    // $(".search-container").css("minHeight", winHeight-200);
  }



  search() {
    this.page = 1;
    this.getproperties();
  }

  set_features(id: number, attr: string) {
    if (id == 1) {
      let p_type_btn = <HTMLElement>document.querySelector("#property_type");
      if (attr == 'Property') {
        this.p_type = "";
      }
      else {
        this.p_type = attr;
      }
      p_type_btn.innerHTML = attr;
      return false;
    }
    if (id == 2) {
      let property_usage_btn = <HTMLElement>document.querySelector("#property_usage");
      property_usage_btn.innerHTML = attr;
      if (attr == 'Type') {
        this.usage = "";
      }
      else {
        this.usage = attr;
      }
      return false;
    }
    if (id == 3) {
      let search_city_btn = <HTMLElement>document.querySelector("#search_city");
      search_city_btn.innerHTML = attr;
      if (attr == 'City') {
        this.selectedcity = "";
      }
      else {
        this.selectedcity = attr;
      }
      return false;
    }
  }

  refresh_page(e) {
    this.page = e;
    this.getproperties();
  }

  search_map(e) {
    this.page = 1;
    this.latitude = e.lat;
    this.longitude = e.lng;
    this.getproperties();
  }

  onhover_property_function(e) {
    $("#m" + e).click();
  }
}
