<div class="main-container">
    <div class="sidebar">
        <div class="p-3 d-none d-md-block">
            <div class="navbar-brand w-100">
                <div class="media">
                    <a routerLink="">
                        <img src="assets/images/reach-arrow.png" class="mr-3 img-fluid" style="width: 30px;" alt="">
                    </a>
                    <div class="media-body align-items-center" style="width: 100px; flex: none;">
                        <a routerLink="">
                            <img src="assets/images/reach-text.png" class="img-fluid" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div>
                <div class="menuHeading fs-12 py-2 px-3">
                    <span>MAIN</span>
                </div>
                <ul>
                    <li>
                        <a routerLink="/admin/my-reach-x" routerLinkActive="active">
                            <span class="icon">
                                <img src="assets/images/icons/admin-dashboard.svg" alt="">
                            </span>
                            <span class="text">
                                Dashboard
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="post-property#message">
                            <span class="icon">
                                <img src="assets/images/icons/admin-message.svg" alt="">
                            </span>
                            <span class="text">
                                Message
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mt-2">
                <div class="menuHeading fs-12 py-2 px-3 text-uppercase">
                    <span>Manage Listings</span>
                </div>

                <ul>
                    <li>
                        <a [ngClass]="{'active': active_menu == 'location'}" (click)="update_active_menu('location')"
                            id="location-link" href="post-property#location"
                            onclick="document.getElementById('location-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">
                            <span class="icon">
                                <img src="assets/images/icons/location.svg" alt="">
                            </span>
                            <span class="text">
                                Location
                            </span>
                        </a>
                    </li>
                    <li>
                        <a [ngClass]="{'active': active_menu == 'basics'}" (click)="update_active_menu('basics')"
                            id="basic-link" href="post-property#basics"
                            onclick="document.getElementById('basic-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">
                            <span class="icon">
                                <img src="assets/images/icons/basic.svg" alt="">
                            </span>
                            <span class="text">
                                Basics
                            </span>
                        </a>
                    </li>
                    <li>
                        <a [ngClass]="{'active': active_menu == 'property'}" (click)="update_active_menu('property')"
                            id="property-link" href="post-property#property"
                            onclick="document.getElementById('property-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">
                            <span class="icon">
                                <img src="assets/images/icons/property.svg" alt="">
                            </span>
                            <span class="text">
                                Property
                            </span>
                        </a>
                    </li>

                    <li>
                        <a [ngClass]="{'active': active_menu == 'features'}" (click)="update_active_menu('features')"
                            id="featured-link" href="post-property#features"
                            onclick="document.getElementById('featured-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">
                            <span class="icon">
                                <img src="assets/images/icons/feature.svg" alt="">
                            </span>
                            <span class="text">
                                Features
                            </span>
                        </a>
                    </li>
                    <li>
                        <a [ngClass]="{'active': active_menu == 'media'}" (click)="update_active_menu('media')"
                            id="media-link" href="post-property#media"
                            onclick="document.getElementById('media-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">

                            <span class="icon">
                                <img src="assets/images/icons/media.svg" alt="">
                            </span>
                            <span class="text">
                                Media
                            </span>
                        </a>
                    </li>
                    <li>
                        <a [ngClass]="{'active': active_menu == 'price'}" (click)="update_active_menu('price')"
                            id="pricing-link" href="post-property#price"
                            onclick="document.getElementById('pricing-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">
                            <span class="icon">
                                <img src="assets/images/icons/price.svg" alt="">
                            </span>
                            <span class="text">
                                Price
                            </span>
                        </a>
                    </li>
                    <li>
                        <a [ngClass]="{'active': active_menu == 'profile'}" (click)="update_active_menu('profile')"
                            id="profile-link" href="post-property#profile"
                            onclick="document.getElementById('profile-section').scrollIntoView({ behavior: 'smooth', block: 'center' }); return false;">
                            <span class="icon">
                                <img src="assets/images/icons/profile.svg" alt="">
                            </span>
                            <span class="text">
                                Profile
                            </span>
                        </a>
                    </li>

                    <li>
                        <a [ngClass]="{'active': active_menu == 'publish'}" (click)="update_active_menu('publish')"
                            id="publish-link" href="post-property#publish"
                            onclick="document.getElementById('publish-section').scrollIntoView({ behavior: 'smooth', block: 'center' });return false;">

                            <span class="icon">
                                <img src="assets/images/icons/publish.svg" alt="">
                            </span>
                            <span class="text">
                                Plan
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mt-2">
                <div class="menuHeading fs-12 py-2 px-3 text-uppercase">
                    <span>Listing Completed</span>
                </div>
                <!-- <div class="progress-circle">
                    <div class="progress green">
                        <span class="progress-left">
                            <span class="progress-bar"></span>
                        </span>
                        <span class="progress-right">
                            <span class="progress-bar"></span>
                        </span>
                        <div class="progress-value">{{complete_percent}}%</div>
                    </div>
                </div> -->
                <circle-progress [percent]="complete_percent" [radius]="50" [outerStrokeWidth]="12"
                    [innerStrokeWidth]="6" [titleColor]="'#FFFFFF'" [outerStrokeColor]="'#78C000'"
                    [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300">
                </circle-progress>
            </div>

        </div>
    </div>
    <div class="postPropertyContainer">
        <div class="row">
            <div class="col-12">
                <h5 class="title pt-4 pb-3 border-bottom text-uppercase text-info">
                    <span class="fw-400">Post</span> Property
                </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div id="dealer-section">
                    <div class="my-2 fw-700">
                        I am a
                    </div>
                    <div class="btn-group-toggle d-flex iama" data-toggle="buttons">
                        <!-- <div class="btn d-flex iama" data-toggle="buttons"> -->
                            <label class="btn bg-white mr-2 p-2 mr-md-4 p-md-3 border align-items-center d-flex" [ngClass]="{'active': user_type=='UT01'}" >
                            <div class="d-block w-100">
                                <input type="radio" id="owner" name="iama" (click)="update_property('user_type','UT01');user_type='UT01';"
                                    [checked]="user_type=='UT01'">
                                <img src="assets/images/icons/owner.svg" alt=""> <br>
                                <span class="d-block mt-1 fw-700">
                                    Owner
                                </span>
                            </div>
                        </label>
                        <label class="btn bg-white  mr-2 p-2 mr-md-4 p-md-3 border align-items-center d-flex" [ngClass]="{'active': user_type=='UT02'}" >
                            <div class="d-block w-100">
                                <input type="radio" id="dealer" name="iama"
                                    (click)="update_property('user_type','UT02');user_type='UT02';" [checked]="user_type=='UT02'">
                                <img src="assets/images/icons/dealer.svg" alt=""> <br>
                                <span class="d-block mt-1 fw-700">
                                    Dealer
                                </span>
                            </div>
                        </label>
                        <label class="btn bg-white  mr-0 p-2 mr-md-4 p-md-3 border align-items-center d-flex" [ngClass]="{'active': user_type=='UT03'}" >
                            <div class="d-block w-100">
                                <input type="radio" id="builder" name="iama"
                                    (click)="update_property('user_type','UT03');user_type='UT03';" [checked]="user_type=='UT03'">
                                <img src="assets/images/icons/builder.svg" alt=""> <br>
                                <span class="d-block mt-1 fw-700">
                                    Builder
                                </span>
                            </div>
                        </label>
                    </div>
                    <div class="my-2 fw-700">
                        I am listing a
                        <div class="d-inline-block">
                            <select name="propertyType" #propertyType
                                class="form-control bg-transparent border-0 align-middle fs-12 p-0"
                                (change)="update_property('property_usage',propertyType.value)">
                                <option value="PU01" [selected]="property_usage=='PU01'">Residential</option>
                                <option value="PU02" [selected]="property_usage=='PU02'">Commercial</option>
                            </select>
                        </div>


                        property <br class="d-block d-md-none"> for
                        <div class="d-inline-block align-middle common-post-property">
                            <select #objective class="form-control bg-transparent border-0"
                                (change)="update_property('property_action',objective.value)">
                                <option value="PA01" [selected]="property_action=='PA01'">Sale</option>
                                <option value="PA02" [selected]="property_action=='PA02'">Rent</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="accordion post-property" id="post-property-panels" (scroll)="scrollHandler($event)">
                    <div class="card mb-3">
                        <div class="card-header" id="locationHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='location-section'>
                                Location
                                <div class="green-check d-inline-block float-right">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                        [ngClass]="{'d-none': this.locationdetails.get('location_detail_completed').value != '100'}">
                                </div>
                            </h2>
                        </div>
                        <div id="locationCollapse">
                            <form id="locationdetails" name="locationdetails" [formGroup]="locationdetails"
                                (ngSubmit)="saveFormData(this.locationdetails.value)">
                                <div class="card-body">
                                    <div class="row mt-n3">
                                        <div class="col-12">
                                            <div class="border rounded p-4 mt-4 position-relative mb-3">
                                                <legend>Property is located in</legend>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group mb-md-0">
                                                                    <div class="mb-2 fw-700">
                                                                        City
                                                                    </div>
                                                                    <select name="location_city"
                                                                        formControlName="location_city"
                                                                        class="form-control form-control-lg fs-12">
                                                                        <option *ngFor="let city of cities"
                                                                            value="{{city.name}}" selected>
                                                                            {{ city.name }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group mb-0">
                                                                    <div class="mb-2 fw-700">
                                                                        Area
                                                                    </div>
                                                                    <input type="text" name="location_area"
                                                                        formControlName="location_area"
                                                                        class="form-control form-control-lg fs-12">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-2 fw-700 d-none d-md-block">&nbsp;</div>
                                            <div class="form-group">
                                                    <input type="text" placeholder="Pin code"
                                                        class="form-control form-control-lg fs-12" name="location_pincode"
                                                        formControlName="location_pincode" value="Pin code">
                                                </div>
                                            <div class="form-group">
                                                <div class="mb-2 fw-700">
                                                    It is a part of (Project/Building/Society)
                                                </div>
                                                <input type="text" name="location_society"
                                                    formControlName="location_society"
                                                    class="form-control form-control-lg fs-12">
                                            </div>
                                            <div class="form-group">
                                                <div class="mb-2 fw-700">
                                                    House and Street Address
                                                </div>
                                                <div class="form-group">
                                                    <textarea name="location_address" formControlName="location_address"
                                                        cols="30" rows="4" class="form-control"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div class="form-group">
                                                <div class="mb-2 fw-700">
                                                    Nearby Landmarks
                                                </div>
                                                <div class="form-row mb-2">
                                                    <div class="col-4">
                                                        <div class="align-middle form-post-property">
                                                            <select name="location_landmark1_type"
                                                                formControlName="location_landmark1_type"
                                                                class="form-control bg-transparent">
                                                                <option value="" selected>Type</option>
                                                                <option
                                                                    *ngFor="let landmark of property_data_template?.landmarks"
                                                                    value="{{landmark.type_code}}">
                                                                    {{landmark.type_title}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-8">
                                                        <input name="location_landmark1_desc"
                                                            formControlName="location_landmark1_desc" type="text"
                                                            class="form-control form-control-lg fs-12">
                                                    </div>
                                                </div>
                                                <div class="form-row mb-2">
                                                    <div class="col-4">
                                                        <div class="align-middle form-post-property">
                                                            <select name="location_landmark2_type"
                                                                formControlName="location_landmark2_type"
                                                                class="form-control bg-transparent">
                                                                <option value="" selected>Type</option>
                                                                <option
                                                                    *ngFor="let landmark of property_data_template?.landmarks"
                                                                    value="{{landmark.type_code}}">
                                                                    {{landmark.type_title}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-8">
                                                        <input name="location_landmark2_desc"
                                                            formControlName="location_landmark2_desc" type="text"
                                                            class="form-control form-control-lg fs-12">
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-4">
                                                        <div class="align-middle form-post-property">
                                                            <select name="location_landmark3_type"
                                                                formControlName="location_landmark3_type"
                                                                class="form-control bg-transparent">
                                                                <option value="" selected>Type</option>
                                                                <option
                                                                    *ngFor="let landmark of property_data_template?.landmarks"
                                                                    value="{{landmark.type_code}}">
                                                                    {{landmark.type_title}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-8">
                                                        <input name="location_landmark3_desc"
                                                            formControlName="location_landmark3_desc" type="text"
                                                            class="form-control form-control-lg fs-12">
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-2">
                                                    <div class="col-5 col-md-4">
                                                        <button class="btn btn-light py-2 px-4 fs-12">
                                                            + Add
                                                        </button>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-2 fw-700 d-none d-md-block">&nbsp;</div>
                                            <div class="map card shadow-none mb-3" (mouseup)="logCenter()"
                                                (mouseleave)="logCenter()">
                                                <!-- <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.371080792907!2d77.27713441455928!3d28.61863849146171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce35097d5f585%3A0xf043b18628a93987!2sAkshardham%20Metro%20Station!5e0!3m2!1sen!2sin!4v1600222584114!5m2!1sen!2sin"
                                                    width="100%" height="300" frameborder="0" style="border:0;"
                                                    allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->

                                                <google-map width="100%" height="300" [zoom]="zoom" [options]="options"
                                                    [center]="center">
                                                </google-map>
                                                <div class="centerMarker"></div>
                                            </div>
                                            <div class="form-row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" name="location_lat"
                                                            formControlName="location_lat"
                                                            class="form-control form-control-lg fs-12"
                                                            placeholder="Latitude" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" name="location_long"
                                                            formControlName="location_long"
                                                            class="form-control form-control-lg fs-12"
                                                            placeholder="Longitude" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="exactMatch"
                                                    name="location_address_display"
                                                    formControlName="location_address_display">
                                                <label class="custom-control-label" for="exactMatch">Do not show on
                                                    Property
                                                    Listing</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-dark py-2 px-3"
                                                (click)="this.locationdetails.get('location_detail_completed').setValue('100')"
                                                [disabled]="!this.locationdetails.valid">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="basicHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='basic-section'>
                                Basic
                                <div class="green-check d-inline-block float-right">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                        [ngClass]="{'d-none': this.basicdetails.get('basic_detail_completed').value != '100'}">
                                </div>
                            </h2>
                        </div>
                        <form id="basicdetails" name="basicdetails" [formGroup]="basicdetails"
                            (ngSubmit)="saveFormData(this.basicdetails.value)">
                            <div id="basicCollapse">
                                <div class="card-body pt-0">
                                    <ng-container>

                                        <div class="residential">
                                            <div class="mt-3 fw-700 mb-2">
                                                My Property type is
                                            </div>
                                            <div class="btn-group-toggle propertyType" >
                                            <!-- <div class="btn-group-toggle propertyType"> -->
                                                <label
                                                    *ngFor="let building of property_data_template?.building_codes[propertyType.value]"
                                                    class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center"  [ngClass]="{'active': this.basicdetails.get('building_type').value==building.type_code}">
                                                    <div class="media">
                                                        <input type="radio" formControlName="building_type"
                                                            name="building_type" value="{{building.type_code}}">
                                                        <img src="{{building.type_icon_path}}"
                                                            class="mr-1 align-self-center" alt="">
                                                        <span class="media-body">
                                                            {{building.type_title}}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="mt-3 fw-700 mb-2">
                                                It is a
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 ">
                                                    <select class="form-control form-control-lg fs-12"
                                                        formControlName="building_subtype"
                                                        [ngClass]="{'border border-danger':(building_subtype.dirty || building_subtype.touched) && building_subtype.invalid==true}">
                                                        <option
                                                            *ngFor="let subbuilding of property_data_template?.subbuilding_codes[propertyType.value]"
                                                            value="{{subbuilding.type_code}}">{{subbuilding.type_title}}
                                                        </option>
                                                    </select>
                                                    <span
                                                        *ngIf="(building_subtype.dirty || building_subtype.touched) && building_subtype.invalid && building_subtype.errors.required">
                                                        It is required.
                                                    </span>
                                                    <!-- <span
                                                        *ngIf="(building_subtype.dirty || building_subtype.touched) && building_subtype.invalid">
                                                        Please select from dropdown.
                                                    </span> -->


                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <!-- <button class="btn btn-light mr-2 py-2 px-3" data-toggle="collapse"
                                                data-target="#locationCollapse">
                                                Back
                                            </button> -->
                                            <button type="submit" class="btn btn-dark"
                                                (click)="this.basicdetails.get('basic_detail_completed').setValue('100')"
                                                [ngClass]="{'border border-dark':!basicdetails.valid,'default-btn page-btn box-btn':basicdetails.valid}"
                                                [disabled]="!this.basicdetails.valid">
                                                Save
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="propertyHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='property-section'>
                                Property
                                <div class="green-check d-inline-block float-right">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                        [ngClass]="{'d-none': this.propertydetails.get('property_detail_completed').value != '100'}">
                                </div>
                            </h2>
                        </div>
                        <div id="propertyCollapse">
                            <form id="propertydetails" name="propertydetails" [formGroup]="propertydetails"
                                (ngSubmit)="savePropertyDetails(this.propertydetails.value)">
                                <div class="card-body">
                                    <div class="mt-n3 mb-2 fw-700">
                                        Property is (BHK)
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="position-relative">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span
                                                            class="input-group-text bg-transparent input-group-text-border">
                                                            <img src="assets/images/icons/building2.svg" alt="">
                                                        </span>
                                                    </div>
                                                    <input type="text" name="bedroom_type" value="0"
                                                        formControlName="bedroom_type"
                                                        class="form-control form-control-lg fs-12 bg-white border-left-0">
                                                </div>
                                                <div class="position-absolute add-remove-btn">
                                                    <button type="button" class="btn px-0"
                                                        (click)="counter(this.propertydetails.get('bedroom_type'), '+')">
                                                        <img src="assets/images/icons/plus.svg" alt="">
                                                    </button>
                                                    <button type="button" class="btn px-0"
                                                        (click)="counter(this.propertydetails.get('bedroom_type'), '-')">
                                                        <img src="assets/images/icons/minus.svg" alt="">
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="col-12">
                                            <div class="border rounded p-4 mt-4 position-relative">
                                                <legend>Property's Area</legend>
                                                <div class="row mt-3">
                                                    <div class="col-md-4">
                                                        <div class="form-group mb-md-0">
                                                            <div class="mb-2 fw-700">
                                                                Super Build-Up Area
                                                            </div>
                                                            <input type="text"
                                                                class="form-control form-control-lg fs-12"
                                                                formControlName="super_area" (blur)="calculate_price_sqft()">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group mb-md-0">
                                                            <div class="mb-2 fw-700">
                                                                Build-Up Area
                                                            </div>
                                                            <input type="text"
                                                                class="form-control form-control-lg fs-12"
                                                                formControlName="buildup_area">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group mb-0">
                                                            <div class="mb-2 fw-700">
                                                                Carpet Area
                                                            </div>
                                                            <input type="text"
                                                                class="form-control form-control-lg fs-12"
                                                                formControlName="carpet_area">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border rounded p-4 mt-4 position-relative">
                                                <legend>Property's Layout</legend>
                                                <div class="row mt-3">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <div class="mb-2 fw-700">
                                                                Bedrooms
                                                            </div>
                                                            <div class="position-relative">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span
                                                                            class="input-group-text bg-transparent input-group-text-border">
                                                                            <i class="fas fa-bed"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" name="bedroom_count" value="0"
                                                                        class="form-control form-control-lg fs-12 bg-white border-left-0"
                                                                        formControlName="bedroom_count">
                                                                </div>
                                                                <div class="position-absolute add-remove-btn">
                                                                    <button type="button" class="btn px-0"
                                                                        (click)="counter(this.propertydetails.get('bedroom_count'), '+')">
                                                                        <img src="assets/images/icons/plus.svg" alt="">
                                                                    </button>
                                                                    <button type="button" class="btn px-0"
                                                                        (click)="counter(this.propertydetails.get('bedroom_count'), '-')">
                                                                        <img src="assets/images/icons/minus.svg" alt="">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <div class="mb-2 fw-700">
                                                                Bathrooms
                                                            </div>
                                                            <div class="position-relative">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span
                                                                            class="input-group-text bg-transparent input-group-text-border">
                                                                            <img src="assets/images/icons/shower.svg"
                                                                                alt="">
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" name="bathroom_count" value="0"
                                                                        class="form-control form-control-lg fs-12 bg-white border-left-0"
                                                                        formControlName="bathroom_count">
                                                                </div>
                                                                <div class="position-absolute add-remove-btn">
                                                                    <button type="button" class="btn px-0"
                                                                        (click)="counter(this.propertydetails.get('bathroom_count'), '+')">
                                                                        <img src="assets/images/icons/plus.svg" alt="">
                                                                    </button>
                                                                    <button type="button" class="btn px-0"
                                                                        (click)="counter(this.propertydetails.get('bathroom_count'), '-')">
                                                                        <img src="assets/images/icons/minus.svg" alt="">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <div class="mb-2 fw-700">
                                                                Balcony
                                                            </div>
                                                            <div class="position-relative">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span
                                                                            class="input-group-text bg-transparent input-group-text-border">
                                                                            <img src="assets/images/icons/balcony.svg"
                                                                                alt="">
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" name="balcony_count" value="0"
                                                                        class="form-control form-control-lg fs-12 bg-white border-left-0"
                                                                        formControlName="balcony_count">
                                                                </div>
                                                                <div class="position-absolute add-remove-btn">
                                                                    <button type="button" class="btn px-0"
                                                                        (click)="counter(this.propertydetails.get('balcony_count'), '+')">
                                                                        <img src="assets/images/icons/plus.svg" alt="">
                                                                    </button>
                                                                    <button type="button" class="btn px-0"
                                                                        (click)="counter(this.propertydetails.get('balcony_count'), '-')">
                                                                        <img src="assets/images/icons/minus.svg" alt="">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="fw-700 mb-2">
                                                            Additional
                                                        </div>
                                                        <!-- <div class="btn-group-toggle features" data-toggle="buttons"> -->
                                                        <div class="btn-group-toggle features">
                                                            <label *ngFor="let a of layout_info.controls; let i=index"
                                                                class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center"  [ngClass]="{'active':layout_info.controls[i].value==true}">
                                                                <div class="media">
                                                                    <input type="checkbox" [formControl]=a />
                                                                    <img src="{{property_data_template.layout_info[i].type_icon_path}}"
                                                                        class="mr-1" alt="">
                                                                    <span class="media-body">
                                                                        {{property_data_template?.layout_info[i].type_title}}
                                                                    </span>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-5">
                                                                <input type="text" value="Other"
                                                                    class="form-control form-control-lg fs-12"
                                                                    formControlName="layout_other_info">
                                                            </div>
                                                        </div>
                                                        <div class="row mt-3">
                                                            <div class="col-12 fw-700">
                                                                Property is
                                                                <div
                                                                    class="d-inline-block align-middle common-post-property">
                                                                    <select class="form-control bg-transparent"
                                                                        formControlName="age">
                                                                        <option
                                                                            *ngFor="let a of property_data_template.age_codes"
                                                                            value="{{a.type_code}}">{{a.type_title}}
                                                                        </option>
                                                                    </select>
                                                                </div>

                                                                old <br class="d-block d-md-none"> and It faces
                                                                <div
                                                                    class="d-inline-block align-middle common-post-property">
                                                                    <select class="form-control bg-transparent"
                                                                        formControlName="facing">
                                                                        <option
                                                                            *ngFor="let a of property_data_template.facing_codes"
                                                                            value="{{a.type_code}}">{{a.type_title}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                and <br class="d-block d-md-none"> it is
                                                                <div
                                                                    class="d-inline-block align-middle common-post-property">
                                                                    <select class="form-control bg-transparent"
                                                                        formControlName="ready_status">
                                                                        <option
                                                                            *ngFor="let a of property_data_template.pstatus_codes"
                                                                            value="{{a.type_code}}">{{a.type_title}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <!-- <button type="button" class="btn btn-light mr-2 py-2 px-3"
                                                data-toggle="collapse" data-target="#basicCollapse">
                                                Back
                                            </button> -->
                                            <button type="submit" class="btn btn-dark py-2 px-3"
                                                (click)="this.propertydetails.get('property_detail_completed').setValue('100')"
                                                [disabled]="!this.propertydetails.valid">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="featuredHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='featured-section'>
                                Features
                                <div class="green-check d-inline-block float-right">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                        [ngClass]="{'d-none': this.featuredetails.get('feature_detail_completed').value != '100'}">
                                </div>
                            </h2>
                        </div>
                        <form id="featuredetails" name="featuredetails " [formGroup]="featuredetails"
                            (ngSubmit)="saveFeatureDetails(this.featuredetails.value)">
                            <div id="featuredCollapse">
                                <div class="card-body">
                                    <div class="mt-n3 mb-2 fw-700">
                                        Property Features
                                    </div>
                                    <!-- <div class="btn-group-toggle features" data-toggle="buttons"> -->
                                    <div class="btn-group-toggle features">
                                        <label *ngFor="let a of property_features.controls; let i=index"
                                            class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center" [ngClass]="{'active':property_features.controls[i].value==true}">
                                            <div class="media">
                                                <input type="checkbox" [formControl]=a
                                                    value="property_data_template.property_features[i].type_code" />
                                                <img src="{{property_data_template.property_features[i].type_icon_path}}"
                                                    class="mr-1" alt="">
                                                <span class="media-body">
                                                    {{property_data_template.property_features[i].type_title}}
                                                </span>
                                            </div>
                                        </label>
                                    </div>

                                    <div class="border rounded p-4 mt-4 position-relative">
                                        <legend>Project/Building/Society Amenities</legend>
                                        <div class="fw-700 mb-2">
                                            Sports
                                        </div>
                                        <!-- <div class="btn-group-toggle features" data-toggle="buttons"> -->
                                        <div class="btn-group-toggle features">
                                            <label *ngFor="let a of sport_amenities.controls; let i=index"
                                                class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center"  [ngClass]="{'active':sport_amenities.controls[i].value==true}">
                                                <div class="media">
                                                    <input type="checkbox" [formControl]=a
                                                        value="property_data_template.sport_amenities[i].type_code" />
                                                    <img src="{{property_data_template.sport_amenities[i].type_icon_path}}"
                                                        class="mr-1" alt="">
                                                    <span class="media-body">
                                                        {{property_data_template.sport_amenities[i].type_title}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="mt-3 fw-700 mb-2">
                                            Convenience
                                        </div>
                                        <!-- <div class="btn-group-toggle features" data-toggle="buttons"> -->
                                        <div class="btn-group-toggle features">
                                            <label *ngFor="let a of convenience_amenities.controls; let i=index"
                                                class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center" [ngClass]="{'active':convenience_amenities.controls[i].value==true}">
                                                <div class="media">
                                                    <input type="checkbox" [formControl]=a
                                                        value="property_data_template.convenience_amenities[i].type_code" />
                                                    <img src="{{property_data_template.convenience_amenities[i].type_icon_path}}"
                                                        class="mr-1" alt="">
                                                    <span class="media-body">
                                                        {{property_data_template.convenience_amenities[i].type_title}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="mt-3 fw-700 mb-2">
                                            Safety
                                        </div>

                                        <!-- <div class="btn-group-toggle features" data-toggle="buggons"> -->
                                        <div class="btn-group-toggle features">
                                            <label *ngFor="let a of safety_amenities.controls; let i=index"
                                                class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center"  [ngClass]="{'active':safety_amenities.controls[i].value==true}">
                                                <div class="media">
                                                    <input type="checkbox" [formControl]=a
                                                        value="property_data_template.safety_amenities[i].type_code" />
                                                    <img src="{{property_data_template.safety_amenities[i].type_icon_path}}"
                                                        class="mr-1" alt="">
                                                    <span class="media-body">
                                                        {{property_data_template.safety_amenities[i].type_title}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>

                                        <div class="mt-3 fw-700 mb-2">
                                            Leisure
                                        </div>

                                        <!-- <div class="btn-group-toggle features" data-toggle="buttons"> -->
                                        <div class="btn-group-toggle features">
                                            <label *ngFor="let a of leisure_amenities.controls; let i=index"
                                                class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center" [ngClass]="{'active':leisure_amenities.controls[i].value==true}">
                                                <div class="media">
                                                    <input type="checkbox" [formControl]=a
                                                        value="property_data_template.leisure_amenities[i].type_code" />
                                                    <img src="{{property_data_template.leisure_amenities[i].type_icon_path}}"
                                                        class="mr-1" alt="">
                                                    <span class="media-body">
                                                        {{property_data_template.leisure_amenities[i].type_title}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>

                                        <div class="mt-3 fw-700 mb-2">
                                            Environment
                                        </div>
                                        <!-- <div class="btn-group-toggle features" data-toggle="buttons"> -->
                                        <div class="btn-group-toggle features">
                                            <label *ngFor="let a of environment_amenities.controls; let i=index"
                                                class="btn bg-white mr-3 mb-3 py-2 px-3 border align-items-center" [ngClass]="{'active':environment_amenities.controls[i].value==true}">
                                                <div class="media">
                                                    <input type="checkbox" [formControl]=a
                                                        value="property_data_template.environment_amenities[i].type_code" />
                                                    <img src="{{property_data_template.environment_amenities[i].type_icon_path}}"
                                                        class="mr-1" alt="">
                                                    <span class="media-body">
                                                        {{property_data_template.environment_amenities[i].type_title}}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="mt-3 fw-700 mb-2">
                                            Unique Property Features
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group row">
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control form-control-lg fs-12"
                                                            formControlName="unique_features_1">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control form-control-lg fs-12"
                                                            formControlName="unique_features_2">
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-0">
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control form-control-lg fs-12"
                                                            formControlName="unique_features_3">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <!-- <button type="button" class="btn btn-light mr-2 py-2 px-3"
                                                data-toggle="collapse" data-target="#locationCollapse">
                                                Back
                                            </button> -->
                                            <button type="submit" class="btn btn-dark py-2 px-3"
                                                (click)="this.featuredetails.get('feature_detail_completed').setValue('100')"
                                                [disabled]="!this.featuredetails.valid">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="mediaHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='media-section'>
                                Media
                                <div class="green-check d-inline-block float-right disabled">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                        [ngClass]="{'d-none': this.media_detail_completed != '100'}">
                                </div>
                            </h2>
                        </div>
                        <div id="mediaCollapse">
                            <div class="card-body">
                                <div class="mt-n3 mb-2 fw-700">
                                    Property Photos
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="uploadImg w-100 rounded text-center d-flex">
                                            <div class="row align-items-center w-100 m-0">
                                                <div class="col-md-12">
                                                    <span class="uploadIcon d-inline-block mb-2">
                                                        <img src="assets/images/icons/cloud-computing.svg" alt="">
                                                    </span>
                                                    <p class="mb-0">Drag and Drop photos here to upload</p>
                                                    <p class="fs-20">or</p>
                                                    <div class="d-inline-block w-auto">
                                                        <input multiple class="custom-input-file" type="file"
                                                            name="fileList" #fileInput
                                                            (change)="onChange(fileInput.files)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small class="mt-2 d-inline-block">File size for each photo up to 4mb, file
                                            types
                                            allowed JPEG, PNG, GIF only.</small>
                                        <div class="mt-3">
                                            <div *ngFor="let i of [0, 1, 2, 3, 4]" class="media mt-2"
                                                [ngClass]="{'d-none': fileNames[i] == 'No file selected'}">
                                                <img [src]="imageUrls[i]" alt="" class="mr-3" width="100" height="100">
                                                <div class="media-body">
                                                    <h6>{{ fileNames[i] }}</h6>
                                                    <div class="progress" style="height: 5px;">
                                                        <div class="progress-bar" role="progressbar"
                                                            style="width: {{progress[i]}}%;"
                                                            attr.aria-valuenow="{{progress[i]}}" aria-valuemin="0"
                                                            aria-valuemax="100"></div>
                                                    </div>
                                                    <div class="row mt-1 fs-10">
                                                        <div class="col-md-6">
                                                            {{uploadedSize[i]}} mb of {{fileSize[i]}} mb
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            {{progress[i]}}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="float-right">
                                            <button class="btn btn-dark py-2 px-3" (click)="onUpload()"
                                                [attr.disabled]="isUploading ? '' : null">
                                                Upload
                                            </button></div>
                                        <div class="row mt-3">
                                            <div *ngFor="let media of medias" class="col"
                                                [ngClass]="{'d-none': deleteImages.includes(media.row_id)}">
                                                <div *ngIf="media.media_type.includes('image')"
                                                    class="media-thumbnail upload-media">
                                                    <div class="item-img" id="">
                                                        <img src="{{media.thumb_url}}" class="img-fluid" alt="">
                                                    </div>
                                                    <button class="close position-absolute"
                                                        (click)="trashImage(media.row_id)">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <div class="mt-3 mb-2 fw-700">
                                                    Video Walkthrough
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="!is_video">
                                            <div class="col-md-6">
                                                <div class="uploadImg rounded text-center align-items-center d-flex justify-content-center"
                                                    style="height: 192px;">
                                                    <div class="d-inline-block">
                                                        <img src="assets/images/icons/video.svg" alt="">
                                                        <div class="mt-2">Drag and Drop</div>
                                                        <p class="fs-20">or</p>

                                                        <input class="custom-input-file" type="file" name="videoList"
                                                            #videoInput (change)="onVideoChange(videoInput.files[0])" />
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                                <small class="mt-2 d-inline-block">
                                                    File size up to 25mb
                                                </small>
                                            </div>
                                            <div class="col-md-6">
                                                Enhance the listing by adding a video or <br> 3D walkthrough.
                                                Contact Reach-X and we <br> can help you create an awesome
                                                multi-dimensional
                                                <br> view the property.
                                            </div>
                                        </div>
                                        <div class="row mt-3 float-center">
                                            <div *ngFor="let media of medias" class="col-md-3"
                                                [ngClass]="{'d-none': deleteImages.includes(media.row_id)}">
                                                <div *ngIf="media.media_type.includes('video')"
                                                    class="media-thumbnail upload-media">
                                                    <div class="item-img" id="">
                                                        <img src="{{media.thumb_url}}" class="img-fluid" alt="">
                                                    </div>
                                                    <button class="close position-absolute"
                                                        (click)="trashImage(media.row_id)">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="mt-3">
                                            <div class="media mt-2"
                                                [ngClass]="{'d-none': vfileName == 'No file selected'}">
                                                <img src="assets/images/icons/video_thumbnail.png" alt="" class="mr-3"
                                                    width="100" height="100">
                                                <div class="media-body">
                                                    <h6>{{ vfileName }}</h6>
                                                    <div class="progress" style="height: 5px;">
                                                        <div class="progress-bar" role="progressbar"
                                                            style="width: {{vprogress}}%;"
                                                            attr.aria-valuenow="{{vprogress}}" aria-valuemin="0"
                                                            aria-valuemax="100"></div>
                                                    </div>
                                                    <div class="row mt-1 fs-10">
                                                        <div class="col-md-6">
                                                            {{vuploadedSize}} mb of {{vfileSize}} mb
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            {{vprogress}}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="float-right">
                                            <button class="btn btn-dark py-2 px-3" (click)="onVideoUpload()"
                                                [attr.disabled]="isUploading ? '' : null">
                                                Upload
                                            </button>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <div class="mt-3 mb-2 fw-700">
                                                    Property Description
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <textarea name="" id="" cols="30" rows="7"
                                                    class="form-control"></textarea>
                                                <small class="mt-2 d-inline-block">
                                                    Max limit 500 words
                                                </small>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 text-right">
                                                <!-- <button class="btn btn-light mr-2 py-2 px-3" data-toggle="collapse"
                                                    data-target="#featuredCollapse">
                                                    Back
                                                </button> -->
                                                <button class="btn btn-dark py-2 px-3">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="pricingHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='pricing-section'>
                                Pricing
                                <div class="green-check d-inline-block float-right disabled">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                        [ngClass]="{'d-none': this.pricedetails.get('price_detail_completed').value != '100'}">
                                </div>
                            </h2>
                        </div>
                        <div id="pricingCollapse">
                            <form id="pricedetails" name="pricedetails" [formGroup]="pricedetails"
                                (ngSubmit)="saveFormData(this.pricedetails.value)">
                                <div class="card-body">
                                    <div class="border rounded p-4 position-relative">
                                        <legend>Property Pricing</legend>
                                        <div class="row mt-n3">
                                            <div class="col-md-5">
                                                <div class="mt-3 mb-2 fw-700">
                                                    Price Expected
                                                </div>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span
                                                            class="input-group-text bg-transparent input-group-text-border">
                                                            <i class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                        class="form-control form-control-lg fs-12 bg-white border-left-0"
                                                        formControlName="price_expected" (blur)="calculate_price_sqft()">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-md-5 ml-1">
                                                <div class="custom-control custom-checkbox mb-3">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="all-inclusive" formControlName="price_all_inclusive">
                                                    <label class="custom-control-label" for="all-inclusive">All
                                                        Inclusive</label>
                                                </div>
                                                <div class="custom-control custom-checkbox mb-3">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="taxes-excluded" formControlName="price_tax_included">
                                                    <label class="custom-control-label" for="taxes-excluded">Taxes
                                                        Excluded</label>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="negotiable"
                                                        formControlName="price_negotiable">
                                                    <label class="custom-control-label"
                                                        for="negotiable">Negotiable</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="border rounded p-4 mt-4 position-relative">
                                        <legend>Additional Charges</legend>
                                        <div class="row mt-n3">
                                            <div class="col-md-6">
                                                <div class="mt-3 mb-2 fw-700">
                                                    Maintenance Dues (Annual)
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    class="input-group-text bg-transparent input-group-text-border">
                                                                    <i class="fas fa-rupee-sign"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text"
                                                                class="form-control form-control-lg fs-12 bg-white border-left-0"
                                                                formControlName="additional_charges">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mt-3 mb-2 fw-700">
                                                Price Per Sqft
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span
                                                                class="input-group-text bg-transparent input-group-text-border">
                                                                <i class="fas fa-rupee-sign"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" value="1850"
                                                            class="form-control form-control-lg fs-12 bg-white border-left-0"
                                                            formControlName="price_per_sqft" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <div class="alert alert-success bg-white text-center">
                                                <h5 class="text-success">
                                                    Reach-X recommendation is good <i class="fas fa-thumbs-up"></i>
                                                </h5>
                                                <small class="fs-14">Price expected is good</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <!-- <button type="button" class="btn btn-light mr-2 py-2 px-3"
                                                data-toggle="collapse" data-target="#mediaCollapse">
                                                Back
                                            </button> -->
                                            <button type="submit" class="btn btn-dark py-2 px-3"
                                                (click)="this.pricedetails.get('price_detail_completed').setValue('100')"
                                                [disabled]="!this.pricedetails.valid">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="profileHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='profile-section'>
                                Profile
                                <div class="green-check d-inline-block float-right disabled">
                                    <img src="assets/images/icons/green-check.svg" alt=""
                                    [ngClass]="{'d-none': !profile_saved }"
                                    >
                                </div>
                            </h2>
                        </div>
                        <div id="profileCollapse">
                            <form id="profiledetails" name="profiledetails" [formGroup]="profiledetails"
                                (ngSubmit)="saveProfileData(this.profiledetails.value)">
                                <div class="card-body">
                                    <!-- <div class="mb-2 fw-700 mt-n3">
                                        Already have an account?
                                    </div>
                                    <button class="btn btn-success" data-target="#login" data-toggle="modal">Login</button> -->
                                    <div class="border rounded p-4 position-relative mt-4">

                                        <legend *ngIf="profile_saved">Let me create an account</legend>
                                        <legend *ngIf="profile_saved">Let me edit my account</legend>
                                        <div class="form-group mb-0">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-row">
                                                        <div class="col-xl-5 col-md-6">
                                                            <div class="form-row">
                                                                <div class="col-12 mb-2 fw-700">
                                                                    First Name
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <input type="text" formControlName="first_name"
                                                                            class="form-control form-control-lg fs-12 bg-transparent">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-xl-5 col-md-6">
                                                            <div class="form-row">
                                                                <div class="col-12 mb-2 fw-700">
                                                                    Last Name
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <input type="text" formControlName="last_name"
                                                                            class="form-control form-control-lg fs-12 bg-transparent">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-row">
                                                <div class="col-xl-5 col-md-6">
                                                    <div class="form-row">
                                                        <div class="col-12 mb-2 fw-700">
                                                            Mobile
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <input type="text" formControlName="mobile_number"
                                                                    class="form-control form-control-lg fs-12 bg-transparent"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-xl-5 col-md-6">
                                                    <div class="form-row">
                                                        <div class="col-12 mb-2 fw-700">
                                                            Email
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group mb-0">
                                                                <input type="email" formControlName="email"
                                                                    class="form-control form-control-lg fs-12 bg-transparent">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="form-row">
                                                <div class="col-xl-5 col-md-6">
                                                    <div class="form-row">
                                                        <div class="col-12 mb-2 fw-700">
                                                            Home Phone
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <input type="text" formControlName="home_number"
                                                                    class="form-control form-control-lg fs-12 bg-transparent">
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-xl-5 col-md-6">
                                                    <div class="form-row">
                                                        <div class="col-12 mb-2 fw-700">
                                                            Office Phone
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group mb-0">
                                                                <input type="text" formControlName="office_number"
                                                                    class="form-control form-control-lg fs-12 bg-transparent">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-0" *ngIf="!profile_saved">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-row">
                                                        <div class="col-xl-5 col-md-6">
                                                            <div class="form-row">
                                                                <div class="col-12 mb-2 fw-700">
                                                                    Password
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <input type="password"
                                                                            formControlName="user_password"
                                                                            class="form-control form-control-lg fs-12 bg-transparent">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-xl-5 col-md-6">
                                                            <div class="form-row">
                                                                <div class="col-12 mb-2 fw-700">
                                                                    Confirm Password
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group mb-0">
                                                                        <input type="password"
                                                                            class="form-control form-control-lg fs-12 bg-transparent">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <!-- <button class="btn btn-light mr-2 py-2 px-3" data-toggle="collapse"
                                                data-target="#pricingCollapse">
                                                Back
                                            </button> -->
                                            <button class="btn btn-dark py-2 px-3" [disabled]="!this.profiledetails.valid">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-header" id="publishHeading">
                            <h2 class="mb-0 fs-14 fw-400 text-uppercase" id='publish-section'>
                                Plan
                                <div class="green-check d-inline-block float-right disabled">
                                    <img src="assets/images/icons/green-check.svg" alt="" [ngClass]="{'d-none': plan_detail_completed != '100'}">
                                </div>
                            </h2>
                        </div>
                        <div id="publishCollapse">
                            <div class="card-body">
                                <div class="mt-n3 fw-700 mb-2">
                                    Select a Best Plan option for your Property
                                </div>
                                <table class="table planTable table-sm">
                                    <thead>
                                        <tr>
                                            <th class="border-0" width="240"></th>
                                            <th class="text-center border-0 basic-plan py-3">
                                                Basic <br>
                                                <small class="fw-300">Free</small>
                                            </th>
                                            <th class="text-center border-0 silver-plan py-3">
                                                Silver <br>
                                                <small class="fw-300">Rs 500</small>
                                            </th>
                                            <th class="text-center border-0 gold-plan py-3">
                                                GOLD <br>
                                                <small class="fw-300">Rs 1000</small>
                                            </th>
                                            <th class="text-center border-0 platinum-plan py-3">
                                                PLATINUM <br>
                                                <small class="fw-300">Rs 2500</small>
                                            </th>
                                            <th class="text-center border-0 daimond-plan py-3">
                                                DIAMOND <br>
                                                <small class="fw-300">Customized</small>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="border-0">Plan Validity</td>
                                            <td class="text-center basic-plan border-0">2 Months</td>
                                            <td class="text-center silver-plan border-0">3 Months</td>
                                            <td class="text-center gold-plan border-0">3 Months</td>
                                            <td class="text-center platinum-plan border-0">6 Months</td>
                                            <td class="text-center daimond-plan border-0">Till you sell</td>
                                        </tr>
                                        <tr>
                                            <td>Properties </td>
                                            <td class="text-center basic-plan">1</td>
                                            <td class="text-center silver-plan">1</td>
                                            <td class="text-center gold-plan">2</td>
                                            <td class="text-center platinum-plan">3</td>
                                            <td class="text-center daimond-plan">Multiple</td>
                                        </tr>
                                        <tr>
                                            <td>Reach-X Verification</td>
                                            <td class="text-center basic-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center silver-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center gold-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center platinum-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Priority in Searches</td>
                                            <td class="text-center basic-plan">&nbsp;</td>
                                            <td class="text-center silver-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center gold-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center platinum-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Premium Listing</td>
                                            <td class="text-center basic-plan">&nbsp;</td>
                                            <td class="text-center silver-plan">&nbsp;</td>
                                            <td class="text-center gold-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center platinum-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Posting Review by Experts</td>
                                            <td class="text-center basic-plan">&nbsp;</td>
                                            <td class="text-center silver-plan">&nbsp;</td>
                                            <td class="text-center gold-plan">&nbsp;</td>
                                            <td class="text-center platinum-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email Promotions</td>
                                            <td class="text-center basic-plan">&nbsp;</td>
                                            <td class="text-center silver-plan">&nbsp;</td>
                                            <td class="text-center gold-plan">&nbsp;</td>
                                            <td class="text-center platinum-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3D model &amp; Video Shoot </td>
                                            <td class="text-center basic-plan">&nbsp;</td>
                                            <td class="text-center silver-plan">&nbsp;</td>
                                            <td class="text-center gold-plan">&nbsp;</td>
                                            <td class="text-center platinum-plan">&nbsp;</td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dedicated Reach-X Expert</td>
                                            <td class="text-center basic-plan">&nbsp;</td>
                                            <td class="text-center silver-plan">&nbsp;</td>
                                            <td class="text-center gold-plan">&nbsp;</td>
                                            <td class="text-center platinum-plan">&nbsp;</td>
                                            <td class="text-center daimond-plan">
                                                <img src="assets/images/icons/small-check.svg" alt="">
                                            </td>
                                        </tr>
                                        <tr class="btn-group btn-group-toggle" data-toggle="buttons">
                                            <!-- <tr> -->
                                                <td class="border-0"></td>
                                            <td class="border-0 {{selectPlan.planBtn}}"
                                                *ngFor="let selectPlan of selectedPlan; let i = index;">
                                                <label class="btn btn-light btn-block"
                                                    [class.active]="selectPlan.planActive ? 'active' : '' ">
                                                    <input type="radio" name="selectPlan" id="{{selectPlan.id}}"
                                                    [checked]="plan_code==selectPlan.id" (click)="select_plan(selectPlan.id)">
                                                    {{plan_code==selectPlan.id ? 'Selected' : 'Buy'}}
                                                </label>
                                            </td>
                                            <td class="border-0">
                                                <div class="btn btn-light btn-block">
                                                    <span class="d-block d-md-none">
                                                        <i class="fas fa-phone-alt"></i>
                                                    </span>
                                                    <span class="d-none d-md-block">
                                                        Contact Us
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row mt-3">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-dark py-2 px-3" (click)="save_plan()">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 text-right">
                        <button class="btn btn-dark py-2 px-3" [disabled]="complete_percent != 100" (click)="publish_property()">
                            Publish
                        </button>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-md-3 mt-2">
                <div class="card p-4 d-block mb-3">
                    <h6 class="fs-16 fw-700">Realzo</h6>
                    <p class="fs-10">Hi! I am Realzo, your Real Estate Advisor. If you need any help or have any
                        questions
                        about posting your property, you can Ask Me or chat online with one of our experts online.</p>
                    <button class="btn btn-dark fs-12 p-3 fw-300 text-uppercase ask-realzo mb-3"
                        onclick="$('.chatBot').addClass('active');">
                        <img src="assets/images/realzo.svg" alt="" class="mr-1 align-middle">
                        Ask RealZo
                    </button>
                    <p class="fs-10">If you want to contact a Reach-X Real Estate expert, </p>
                    <p class="mb-0 fs-10">Please call at 1-800-ReachUS, <br>
                        <span class="fs-16 fw-600">or</span> <br>
                        Email at <a href="mailto:NeedAdvise@reach-x.com">NeedAdvise@reach-x.com</a></p>
                </div>
                <div class="card p-4 d-block mb-3" style="min-height: 150px;">
                    <h6 class="fs-16 fw-700">Realzo</h6>

                </div>
            </div>
        </div>
    </div>
</div>
