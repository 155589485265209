<nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm fixed-top py-1">
    <a class="navbar-brand pr-3 border-right mr-3" routerLink="" *ngIf="!sidebarActive">
        <img [src]="logo" alt="">
    </a>
    <!-- FOR MOBILE VIEW IN  -->
    <a class="navbar-brand pr-3 border-right mr-3 d-inline-block d-md-none" routerLink="" *ngIf="sidebarActive">
        <img [src]="logo" alt="">
    </a>
    <div class="" *ngIf="sidebarActive" [class]="sidebarActive ? 'sidebarActive' : ''">
        <button type="button" class="btn bg-transparent px-0" (click) = "sidebar()">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
    <button class="navbar-toggler" *ngIf="!sidebarActive" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" *ngIf="!admin" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
            <a class="nav-link fw-500 dropdown-toggle text-uppercase" href="#" id="buy-rent" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Buy/Rent</a>
            <div class="dropdown-menu py-3" aria-labelledby="buy-rent">
                <a class="dropdown-item" >Residential</a>
                <a class="dropdown-item">Commercial</a>
            </div>
        </li>
        <li class="nav-item dropdown services">
            <a class="nav-link fw-500 dropdown-toggle text-uppercase" href="#" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">services</a>
            <div class="dropdown-menu py-3" aria-labelledby="servicesDropdown">
                <a class="dropdown-item" href="#">Valuation</a>
                <a class="dropdown-item" href="#">Rates and Insights</a>
                <a class="dropdown-item" href="#">Loans</a>
                <a class="dropdown-item" href="#">Property Management</a>
                <a class="dropdown-item" href="#">Consultation</a>
                <a class="dropdown-item" href="#">NRI Services</a>
                <a class="dropdown-item" href="#">Investment</a>
            </div>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link fw-500 dropdown-toggle text-uppercase" href="#" id="sell" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">sell</a>
            <div class="dropdown-menu py-3" aria-labelledby="sell">
                <a class="dropdown-item" routerLink="sell">Post a Property</a>
                <a class="dropdown-item">Find an Agent</a>
            </div>
        </li>
        
      </ul>
    </div>
    <form class="form-inline my-2 my-lg-0 ml-auto">
        <a routerLink="" class="btn btn-sm btn-warning text-white text-uppercase fs-11 px-2 fw-500">
            crm
        </a>
        <div class="dropdown profile ml-2">
            <button class="btn btn-sm fs-11 text-dark fw-500 text-uppercase" id="profile" data-toggle="dropdown">
                <i class="fas fa-user mr-0 mr-md-1"></i> 
                <span class="d-none d-md-inline-block">
                    Profile
                </span>
            </button>
            <div class="dropdown-menu pb-3 dropdown-menu-right" aria-labelledby="profile">
                <div class="px-3 py-1 border-bottom mb-2">
                    <span class="fs-11">Access your portal here</span>
                    <div class="my-2 d-flex">
                        <a href="" data-target="#login" data-toggle="modal" class="btn btn-sm btn-success fw-500 text-uppercase fs-11 mr-2 text-white px-3 py-1 d-inline-block">
                            Login
                        </a>
                        <a href="" data-target="#signup" data-toggle="modal" class="btn btn-sm btn-warning fw-500 text-uppercase fs-11 text-white px-3 py-1 d-inline-block">
                            Signup
                        </a>
                    </div>
                </div>
                <a class="dropdown-item">My Account</a>
                <a class="dropdown-item">My Profile</a>
                <a class="dropdown-item">My Favorites</a>
            </div>
        </div>
    </form>
</nav>


<!-- LOGIN MODAL BEGIN HERE -->
<div class="modal fade reachX-modal" id="login" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="loginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-body py-0">
            <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
            </button>
            <div class="row">
                <div class="col-md-6 py-4 px-4">
                    <h5 class="modal-title text-uppercase fw-700 mb-3" id="loginModalLabel">Login</h5>
                    <form action="" class="needs-validation" novalidate>
                        <div class="form-group">
                            <input type="email" class="form-control modal-control fs-12 fw-500 form-control-lg" placeholder="Email ID" required>
                            <div class="valid-feedback">
                                Looks good!
                            </div>
                            <div class="invalid-feedback">
                                Please provide a valid email.
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <input type="password" class="form-control modal-control fs-12 fw-500 py-2" placeholder="Password" required>
                            <div class="invalid-feedback">
                                Please provide a valid password.
                            </div>
                            <div class="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <a class="text-success fw-500" href="" data-toggle="modal" data-target="#forgetPassword" data-dismiss="modal">
                                Forgot Password?
                            </a>
                        </div>
                        <div class="form-group mb-0">
                            <button type="submit" (click) = "gotoDashboard()" data-dismiss="modal" class="btn btn-lg btn-dark text-uppercase btn-block fs-12 py-2">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col-md-6 py-4 px-4 modal-graphic">
                    <div class="text-center mb-3">
                        <img src="assets/images/modal-logo.png" alt="">
                    </div>
                    <div class="reachXFeatures fw-500 text-dark my-4">
                        <ul class="m-0 p-0">
                            <li>Buy and sell properties</li>
                            <li>Manage your listings</li>
                            <li>Get custom property alerts</li>
                        </ul>
                    </div>
                    <div class="bottom-button">
                        <button data-toggle="modal" data-target="#signup" data-dismiss="modal" class="btn btn-lg py-2 btn-success text-uppercase fs-12 btn-block">
                            sign up
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
    </div>
</div>
<!-- LOGIN MODAL END HERE -->
<!-- SIGN UP MODAL BEGIN HERE -->
<div class="modal fade reachX-modal" id="signup" tabindex="-1" role="dialog" aria-labelledby="signupLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-body py-0">
            <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
            </button>
            <div class="row">
                <div class="col-md-6 py-4 px-4">
                    <h5 class="modal-title text-uppercase fw-700 mb-3" id="signupModalLabel">signup</h5>
                    <form action="" class="needs-validation" novalidate>
                        <div class="form-group">
                            <input type="text" class="form-control modal-control fs-12 fw-500 form-control-lg" placeholder="First Name" required>
                            <div class="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control modal-control fs-12 fw-500 py-2" placeholder="Last Name" required>
                            <div class="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <input type="password" class="form-control modal-control fs-12 fw-500 py-2" placeholder="Password (6 to 20 character long)" required>
                            <div class="valid-feedback">
                                Looks good!
                            </div>
                            <div class="invalid-feedback">
                                Please create a valid password!
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control modal-control fs-12 fw-500 py-2" placeholder="Business phone (optional)">
                        </div>
                        
                        <div class="form-group mb-0">
                            <button type="submit" class="btn btn-lg btn-dark text-uppercase btn-block fs-12 py-2">
                                Sign Up
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col-md-6 py-4 px-4 modal-graphic">
                    <div class="text-center mb-3">
                        <img src="assets/images/modal-logo.png" alt="">
                    </div>
                    <div class="reachXFeatures fw-500 text-dark my-4">
                        <ul class="m-0 p-0">
                            <li>Buy and sell properties</li>
                            <li>Manage your listings</li>
                            <li>Get custom property alerts</li>
                        </ul>
                    </div>
                    
                    <div class="bottom-button">
                        <button data-toggle="modal" data-target="#login" data-dismiss="modal" class="btn btn-lg py-2 btn-success text-uppercase fs-12 btn-block">
                            login 
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
    </div>
</div>
<!-- SIGN UP MODAL END HERE -->
<!-- FORGET PASSWORD MODAL BEGIN HERE -->
<div class="modal fade reachX-modal" data-backdrop="static" id="forgetPassword" tabindex="-1" role="dialog" aria-labelledby="signupLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-body py-0">
            <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
                <i class="fas fa-times"></i>
            </button>
            <div class="row">
                <div class="col-md-12 py-4 px-4">
                    <h5 class="modal-title text-uppercase fw-700 mb-3" id="signupModalLabel">forget password</h5>
                    <form action=""  class="needs-validation" novalidate>
                        <div class="form-group ">
                            <input type="email" class="form-control modal-control fs-12 fw-500 form-control-lg" placeholder="Enter your Email address">
                            <div class="valid-feedback">
                                Looks good!
                            </div>
                            <div class="invalid-feedback">
                                Please enter a valid Email ID.
                            </div>
                        </div>
                        
                        <div class="form-group mb-0">
                            <button type="submit" class="btn btn-lg btn-dark text-uppercase btn-block fs-12 py-2">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                <!-- <div class="col-md-6 py-4 px-4 modal-graphic">
                    <div class="text-center mb-3">
                        <img src="assets/images/modal-logo.png" alt="">
                    </div>
                    <div class="reachXFeatures fw-500 text-dark my-4">
                        <ul class="m-0 p-0">
                            <li>Buy and sell properties</li>
                            <li>Manage your listings</li>
                            <li>Get custom property alerts</li>
                        </ul>
                    </div>
                    
                    <div class="bottom-button">
                        <button data-toggle="modal" data-target="#login" data-dismiss="modal" class="btn btn-lg py-2 btn-success text-uppercase fs-12 btn-block">
                            login 
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
        
      </div>
    </div>
</div>
<!-- FORGET PASSWORD MODAL END HERE -->