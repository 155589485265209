import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit, OnDestroy {

  constructor(private _adminHeader : HeaderService, private _sidebarService : SidebarService) { }

  ngOnInit(): void {
    this._adminHeader.postPropertyHeader.next(true);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(true);
    this._sidebarService.adminMenu.next(true);
    this._adminHeader.admin.next(true);
  }
  ngOnDestroy(): void {
    this._adminHeader.postPropertyHeader.next(false);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(false);
    this._sidebarService.adminMenu.next(false);
    this._adminHeader.admin.next(false);
  }

}
