import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';

import { FormArray, FormBuilder, FormGroup, FormControl, Form, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { CookieService } from 'ngx-cookie-service';
import { apiUrl } from '../../services/api-url';
import { Router } from '@angular/router';


@Component({
  selector: 'app-my-reach-x',
  templateUrl: './my-reach-x.component.html',
  styleUrls: ['./my-reach-x.component.css']
})
export class MyReachXComponent implements OnInit, OnDestroy {



  my_listing = [
    { name: "Total", value: 15 },
    { name: "Active", value: 6 },
    { name: "Deleted", value: 2 },
    { name: "Inactive/Expire", value: 6 }
  ];

  my_listingColorScheme = {
    domain: ['#F1965A', '#5A9BF1', '#F35D5D', '#D4D4D4']
  };

  my_contact = [
    { name: "Total", value: 35 },
    { name: "Contacted", value: 12 },
    { name: "New", value: 15 }
  ];

  my_contactColorScheme = {
    domain: ['#F35D5D', '#F99F51', '#5A9BF1']
  };

  my_plan = [
    { name: "Total", value: 6 },
    { name: "Free", value: 3 },
    { name: "Platinum", value: 2 },
    { name: "Inactive", value: 1 }
  ];

  my_planColorScheme = {
    domain: ['#F1965A', '#D4D4D4', '#F35D5D', '#5A9BF1']
  };
  constructor(private router: Router, private _adminHeader: HeaderService, private _sidebarService: SidebarService, private refozoDBAPIService: refozoDBAPIService, private http: HttpClient, private cookieService: CookieService, private apiUrl: apiUrl) { }


  ngOnInit(): void {
    this._adminHeader.postPropertyHeader.next(true);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(true);
    this._sidebarService.adminMenu.next(true);
    this._adminHeader.admin.next(true);
  }
  ngOnDestroy(): void {
    this._adminHeader.postPropertyHeader.next(false);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(false);
    this._sidebarService.adminMenu.next(false);
    this._adminHeader.admin.next(false);
  }
  new_property() {
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'new_property');

    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        err => console.error('Observer got an error: ' + err),
        () => {
          console.log(response)
          if (response) {
            this.router.navigateByUrl('post-property')
          }
          else {
            this.router.navigateByUrl('admin/my-reach-x')
          }
        }
      )
  }
}
