<div class="row activeListing pt-5 pb-4">
    <div class="col-12">
        <h5 class="text-info fw-600 text-uppercase text-center mb-4 mb-md-3">
            <span class="fw-400">Reach</span> active listings
        </h5>
        <form action="">
            <div class="row">
                <div class="col-xl-10 mx-auto">
                    <div class="form-row">
                        <div class="col-md-9 col-xl-10">
                            <div class="row no-gutters">
                                <div class="col-md-6">
                                    <div class="row no-gutters">
                                        <div class="col-4  property dropdown">
                                            <button
                                                class="text-truncate form-control text-left custom-select fs-14 property rounded-right-0 border-right-0 h-auto py-3"
                                                type="button" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false" name="property_type" id="property_type">
                                                Property
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a *ngFor="let p_type of property_type" class="dropdown-item"
												 href="#"
                                                    (click)="set_features(1, p_type.name)">{{ p_type.name }}</a>
                                            </div>
                                        </div>
                                        <div class="col-4  type dropdown">
                                            <button
                                                class="text-truncate form-control text-left custom-select fs-14 property rounded-0 border-right-0 h-auto py-3"
                                                type="button" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false" name="property_usage" id="property_usage">
                                                Type
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a *ngFor="let usage of property_usage" class="dropdown-item"
												 href="#"
                                                    (click)="set_features(2, usage.name)">{{ usage.name }}</a>
                                            </div>
                                        </div>
                                        <div class="col-4  city dropdown">
                                            <button
                                                class="text-truncate form-control text-left custom-select fs-14 property rounded-0 border-right-0 h-auto py-3"
                                                type="button" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false" name="search_city" id="search_city">
                                                City
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a *ngFor="let city of cities" class="dropdown-item" href="#"
                                                    (click)="set_features(3, city.name)">{{ city.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-md-0 mt-3">
                                    <div class="input-group">
                                        <input [(ngModel)]="search_text" type="text" id="search_text"
										 name="search_text"
                                            class="form-control border-right-0 rounded-0 fs-14 h-auto py-3"
                                            placeholder="What are you looking for?">
                                        <div class="input-group-prepend">
                                            <button (click)="search(p_type, usage, selectedcity, search_text)"
                                                class="input-group-text btn rounded-right border-left-0 bg-white"
                                                type="submit">
                                                <span class="d-none d-md-block"><img src="assets/images/search.svg"
                                                        alt="" width="20"></span>
                                                <span class="d-block d-md-none">search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-xl-2 mt-3 mt-md-0">
                            <!-- <button class="btn btn-dark btn-block fs-12 py-3 fw-300 text-uppercase ask-realzo"
                                (click)="openModal(template)">
                                <img src="assets/images/realzo.svg" class="mr-1 align-middle" alt=""> Ask RealZo
                            </button> -->
                            <button class="btn btn-dark btn-block fs-12 py-3 fw-300 text-uppercase ask-realzo h-100" onclick="$('.chatBot').addClass('active');">
                                <img src="assets/images/realzo.svg" class="mr-1 align-middle" alt=""> Ask RealZo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <p class="text-center fs-14 fw-500 mb-0 mt-4">
            Introducing Realzo: Real Estate Industries first Artificial Intelligence engine.
        </p>
    </div>
</div>