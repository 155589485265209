import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';


@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit, OnDestroy {

  // SELECT PLAN
  selectedPlan = [
    {
      "id" : "basic",
      "planBtn" : "basic-btn",
      "planActive" : true
    },
    {
      "id" : "silver",
      "planBtn" : "silver-btn",
      "planActive" : false
    },
    {
      "id" : "gold",
      "planBtn" : "gold-btn",
      "planActive" : false
    },
    {
      "id" : "platinum",
      "planBtn" : "platinum-btn",
      "planActive" : false
    }
  ]

  constructor(private _adminHeader : HeaderService, private _sidebarService : SidebarService) { }

  ngOnInit(): void {
    this._adminHeader.postPropertyHeader.next(true);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(true);
    this._sidebarService.adminMenu.next(true);
    this._adminHeader.admin.next(true);
  }
  ngOnDestroy(): void {
    this._adminHeader.postPropertyHeader.next(false);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(false);
    this._sidebarService.adminMenu.next(false);
    this._adminHeader.admin.next(false);
  }

}
