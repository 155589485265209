<!-- TOP DARK SECTION BEGIN HERE -->
<div class="row">
    <div class="bg-dark container-fluid py-3 text-white topbar">
        <div class="row">
            <div class="col-12 col-md-5 col-xl-4">
                <h4 class=" fw-600 mb-1 text-truncate">{{exclusiveSelectedItem.propertyName}}</h4>
                <small class="fw-500 fs-14">{{exclusiveSelectedItem.propertyPlace}}</small>
            </div>
            <div class="col-12 col-md-7 col-xl-8 mt-3 mt-md-0">
                <div class="row">
                    <div class="col-md-12 col-xl-6 text-md-right">
                        <h4>{{exclusiveSelectedItem.propertyPrice[0].min}} to
                            {{exclusiveSelectedItem.propertyPrice[0].max}}</h4>
                        <small class="fs-12">
                            <span class="fw-500">Unit: </span> {{exclusiveSelectedItem.propertyUnit}}
                            <span class="d-inline-block mx-2">|</span>
                            <span class="fw-500">Size: </span> {{exclusiveSelectedItem.propertySize[0].min}} to
                            {{exclusiveSelectedItem.propertySize[0].max}}
                        </small>
                    </div>
                    <div class="col-md-5 col-xl-6 propertySharedBtn">
                        <div class="form-row h-100">
                            <div class="col h-100">
                                <button class="btn btn-light-blue btn-block h-100 fs-12 fw-700">
                                    <i class="fas fa-share-alt mr-md-1 fs-16 align-middle"></i>
                                    <span class="d-md-inline-block d-none">Share</span>
                                </button>
                            </div>
                            <div class="col h-100">
                                <button class="btn btn-light-red btn-block h-100 fs-12 fw-700" data-toggle="modal">
                                    <i class="fas fa-heart mr-md-1 fs-16 align-middle"></i>
                                    <span class="d-md-inline-block d-none">Save</span>
                                </button>
                            </div>
                            <div class="col h-100">
                                <button class="btn btn-outline-success btn-block h-100 fs-12 fw-700">
                                    <img src="assets/images/icons/person_phone.svg" width="16" class="mr-1" alt="">
                                    <span class="d-md-inline-block d-none">Connect</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- TOP DARK SECTION END HERE -->
<!-- BANNER CAROUSEL BEGIN HERE -->
<div class="row property-detail-outlet">
    <div class="col-md-8 pr-md-0">
        <div class="photo-grid__container w-100">
            <div class="photo-grid photo-grid--ads">
                <div class="photo-grid__hero">
                    <div class="photo-grid__photo-wrapper"
                        *ngFor="let propertyPic of exclusiveSelectedItem.propertyThumbnailCarousel | slice:0:1;">
                        <a href="{{propertyPic.thumbnail}}" class="thumbnail">
                            <img src="{{propertyPic.thumbnail}}" class="img-fluid photo-grid__photo">
                        </a>
                    </div>
                </div>
                <div class="photo-grid__photos">
                    <div class="photo-grid__photo-secondary">
                        <div class="photo-grid__photo-container">
                            <div class="photo-grid__photo-wrapper"
                                *ngFor="let propertyPic of exclusiveSelectedItem.propertyThumbnailCarousel | slice:1:2;">
                                <a href="{{propertyPic.thumbnail}}" class="thumbnail">
                                    <img src="{{propertyPic.thumbnail}}" class="img-fluid photo-grid__photo">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="photo-grid__photo-tertiary position-relative">
                        <div class="photo-grid__photo-container"
                            *ngFor="let propertyPic of exclusiveSelectedItem.propertyThumbnailCarousel | slice:2:4;">
                            <div class="photo-grid__photo-wrapper">
                                <a href="{{propertyPic.thumbnail}}" class="thumbnail">
                                    <img src="{{propertyPic.thumbnail}}" class="img-fluid photo-grid__photo">
                                </a>
                            </div>
                        </div>
                        <div class="length">
                            <div *ngFor="let propertyPic of exclusiveSelectedItem.propertyThumbnailCarousel | slice:5">
                                <a href="{{propertyPic.thumbnail}}" class="thumbnail" style="visibility: hidden;">
                                    <img src="{{propertyPic.thumbnail}}" alt="">
                                </a>
                            </div>
                            <span>+ {{exclusiveSelectedItem.propertyThumbnailCarousel?.length - 4}} Photos</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div>
            <div class="item-img" *ngFor = "let propertyPic of exclusiveSelectedItem.propertyThumbnailCarousel | slice:0:5; let i=index">
                <img src="{{propertyPic.thumbnail}}" class="img-fluid">
            </div>
            
        </div> -->

        <!-- <owl-carousel-o [options]="propertiesPic">
            <ng-template carouselSlide *ngFor = "let propertyPic of exclusiveSelectedItem.propertyThumbnailCarousel">
                
            </ng-template>
        </owl-carousel-o> -->
    </div>
    <div class="col-md-4">
        <agm-map class="h-100" [latitude]="lat" [longitude]="lng">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
    </div>
</div>
<!-- BANNER CAROUSEL END HERE -->
<!-- PROPERTIES DETAIL BEGIN HERE -->
<div class="row propertyMainWrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-8 mt-4 pb-5 ">
                <div class="row">
                    <div class="col-md-12 pr-xl-0">
                        <div class="scrollableMenu">
                            <div class="propertyNavbar" data-gumshoe-header data-scroll-header>
                                <ul class="nav nav-tabs pb-3" data-gumshoe id="navbar-example2">
                                    <li class="nav-item"
                                        *ngFor="let menuName of exclusiveSelectedItem.propertyDetail[0].propertyMenus">
                                        <a class="nav-link" [class.active]="menuName.menuactive" data-scroll
                                            href="{{exclusiveSelectedItem.propertyId}}#{{menuName.propertyMenusURL}}">{{menuName.propertyMenusName}}</a>
                                    </li>
                                    <li class="ml-auto nav-item d-none d-lg-inline-block">
                                        <button class="nav-link">
                                            <img src="assets/images/icons/ellipsis.svg">
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="propertyDetail">
                                    <!-- OVERVIEW SECTION BEGIN HERE -->
                                    <div class="overview-section section" id="property-overview"
                                        *ngFor="let overview of exclusiveSelectedItem.propertyDetail[0].overviewDetail">
                                        <div class="overviewDetail">
                                            <h5 class="fw-500 text-info text-uppercase mt-4 mb-3">
                                                overview
                                            </h5>
                                            <div class="overview-text lh-24" [innerHTML]="overview.overviewText"
                                                [class.expand]="expanded">

                                            </div>
                                            <div class="view-more">
                                                <button class="btn btn-link fs-12 fw-500 px-0"
                                                    (click)="expandOverview()">{{text}}</button>
                                            </div>
                                        </div>
                                        <div class="overviewOptions mt-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-6 mb-4"
                                                            *ngFor="let overviewOptions of overview.overviewOptions">
                                                            <h6 class="text-uppercase fs-14 mb-1 text-orange">
                                                                {{overviewOptions.overviewOptionTitle}}</h6>
                                                            <small
                                                                class="fs-12">{{overviewOptions.overviewOptionValue}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- OVERVIEW SECTION END HERE -->
                                    <!-- AMINITIES SECTION BEGIN HERE -->
                                    <div class="aminities-section section" id="property-aminities">
                                        <div class="aminities-detail">
                                            <h5 class="fw-500 text-info text-uppercase mt-0 mb-3">
                                                Aminities
                                            </h5>
                                            <div class="aminities-available">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-12 mb-3 btn-group-toggle" >
                                                                <div class="d-inline-flex media btn bg-white mr-3 mb-3 py-2 px-3 border text-left align-items-center" *ngFor="let aminitiesAvailable of exclusiveSelectedItem.propertyDetail[0].aminitiesAvailable">
                                                                    <img src="{{aminitiesAvailable.aminitiesIcon}}" class="mr-2" width="20" height="20" alt="">
                                                                    <div class="media-body align-self-center">
                                                                        {{aminitiesAvailable.aminitiesName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- AMINITIES SECTION END HERE -->
                                    <!-- TOURS SECTION BEGIN HERE -->
                                    <div class="tours-section section" id="property-tours"
                                        *ngFor="let tour of exclusiveSelectedItem.propertyDetail[0].virtualTour">
                                        <div class="tours-detail">
                                            <h5 class="fw-500 text-info text-uppercase mt-3 mb-3">
                                                Virtual Tour
                                            </h5>
                                            <div class="" [innerHTML]="tour.virtualDesc"></div>
                                            <div class="tour-preview-section">
                                                <h6 class="fs-12 fw-700">Take a Tour of this impressive mall:</h6>
                                                <div class="tour-preview">
                                                    <img src="assets/images/airia-mall-big.jpg" class="img-fluid">
                                                </div>
                                                <div class="tour-caption fs-10 fw-700 lh-normal mt-2 fs-11 text-gray">
                                                    Click on the arrows on the bottom to move forward, backward, and
                                                    left or right. Click on the circle arrows at the top left to look
                                                    left right, up & down. You can also use your mouse to rotate the
                                                    image by Click and drag anywhere in the Tour to ‘look around’. Click
                                                    on upper right corner to see ‘full screen’ Tour. Enjoy the tour!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- TOURS SECTION END HERE -->
                                    <!-- DOCUMENTS SECTION BEGIN HERE -->
                                    <div class="document-section section" id="property-documents">
                                        <div class="document-detail">
                                            <h5 class="fw-500 text-info text-uppercase mt-3 mb-3">
                                                Documents
                                            </h5>
                                            <div class="document-tree pl-1">
                                                <div class="treeviewItem mb-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="notify-me">
                                                        <label class="custom-control-label" for="notify-me">Notify me
                                                            when the Due Diligence Documents are updated</label>
                                                    </div>
                                                </div>
                                                <div class="treeviewItem folder mb-3">
                                                    <div class="tree-group">
                                                        <div class="tree-head">
                                                            <div class="custom-control custom-checkbox d-inline-block">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="broker-offering">
                                                                <label class="custom-control-label"
                                                                    for="broker-offering"></label>
                                                            </div>
                                                            <div class="d-inline-block align-middle document-checkbox">
                                                                <div class="d-inline-block">
                                                                    <button type="button" class="btn p-0 fs-12 fw-500"
                                                                        data-toggle="collapse"
                                                                        data-target="#brokerOfferMemo"
                                                                        aria-expanded="false"
                                                                        aria-controls="brokerOfferMemo">
                                                                        <img src="assets/images/icons/folder-blue.png"
                                                                            class="mr-2 "> Broker Offering Memorandum
                                                                        (1)
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tree-body">
                                                            <div class="collapse" id="brokerOfferMemo">
                                                                <div class="treeviewItem treeviewChildItem mt-3">
                                                                    <div
                                                                        class="custom-control custom-checkbox d-inline-block">
                                                                        <input type="checkbox"
                                                                            class="custom-control-input"
                                                                            id="broker-offering-child">
                                                                        <label class="custom-control-label"
                                                                            for="broker-offering-child"></label>
                                                                    </div>
                                                                    <div
                                                                        class="d-inline-block align-middle document-child-checkbox">
                                                                        <button type="button" class="btn p-0 fs-12 fw-"
                                                                            data-toggle="collapse"
                                                                            data-target="#brokerOfferMemoChild"
                                                                            aria-expanded="false"
                                                                            aria-controls="brokerOfferMemoChild">
                                                                            <img src="assets/images/icons/folder-blue.png"
                                                                                class="mr-2"> Broker Offering Memorandum
                                                                            (1)
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="treeviewItem folder mb-3">
                                                    <div class="tree-group">
                                                        <div class="tree-head">
                                                            <div class="custom-control custom-checkbox d-inline-block">
                                                                <input type="checkbox" class="custom-control-input"
                                                                    id="valuations">
                                                                <label class="custom-control-label"
                                                                    for="valuations"></label>
                                                            </div>
                                                            <div class="d-inline-block align-middle document-checkbox">
                                                                <div class="d-inline-block">
                                                                    <button type="button" class="btn p-0 fs-12 fw-500"
                                                                        data-toggle="collapse"
                                                                        data-target="#valuationsCollapse"
                                                                        aria-expanded="false"
                                                                        aria-controls="valuationsCollapse">
                                                                        <img src="assets/images/icons/folder-blue.png"
                                                                            class="mr-2"> Valuations (4)
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tree-body">
                                                            <div class="collapse" id="valuationsCollapse">
                                                                <div class="tree-group">
                                                                    <div class="tree-head">
                                                                        <div
                                                                            class="treeviewItem treeviewChildItem mt-3">
                                                                            <div
                                                                                class="custom-control custom-checkbox d-inline-block">
                                                                                <input type="checkbox"
                                                                                    class="custom-control-input"
                                                                                    id="airia-mall-checkbox">
                                                                                <label class="custom-control-label"
                                                                                    for="airia-mall-checkbox"></label>
                                                                            </div>
                                                                            <div
                                                                                class="d-inline-block align-middle document-child-checkbox">
                                                                                <button type="button"
                                                                                    class="btn p-0 fs-12 fw-"
                                                                                    data-toggle="collapse"
                                                                                    data-target="#airia-mall-doc"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="airia-mall-doc">
                                                                                    <img src="assets/images/icons/folder-green.svg"
                                                                                        class="mr-2"> Airia Mall, Sector
                                                                                    68, Gurugram (2)
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="tree-body">
                                                                        <div class="collapse" id="airia-mall-doc">
                                                                            <div
                                                                                class="treeviewItem treeviewSubChildItem mt-3">
                                                                                <div
                                                                                    class="custom-control custom-checkbox d-inline-block">
                                                                                    <input type="checkbox"
                                                                                        class="custom-control-input"
                                                                                        id="airia-mall-file-checkbox">
                                                                                    <label class="custom-control-label"
                                                                                        for="airia-mall-file-checkbox"></label>
                                                                                </div>
                                                                                <div
                                                                                    class="d-inline-block align-middle document-sub-child-checkbox">
                                                                                    <button type="button"
                                                                                        class="btn p-0 fs-12 fw-">
                                                                                        <img src="assets/images/icons/files.svg"
                                                                                            class="mr-2"> Airia Mall,
                                                                                        Sector 68, Gurugram (2)
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="tree-group">
                                                                    <div class="tree-head">
                                                                        <div class="collapse show">
                                                                            <div
                                                                                class="treeviewItem treeviewChildItem mt-3">
                                                                                <div
                                                                                    class="custom-control custom-checkbox d-inline-block">
                                                                                    <input type="checkbox"
                                                                                        class="custom-control-input"
                                                                                        id="airia-mall-checkbox-2">
                                                                                    <label class="custom-control-label"
                                                                                        for="airia-mall-checkbox-2"></label>
                                                                                </div>
                                                                                <div
                                                                                    class="d-inline-block align-middle document-child-checkbox">
                                                                                    <button type="button"
                                                                                        class="btn p-0 fs-12 fw-">
                                                                                        <img src="assets/images/icons/folder-green.svg"
                                                                                            class="mr-2"> Airia Mall,
                                                                                        Sector 68, Gurugram (2)
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- DOCUMENTS SECTION END HERE -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form id="contactForm" [formGroup]="contactform" (ngSubmit)="submitContactUsForm(this.contactform.value)">
                <div class="col-xl-4 bg-white border-left px-4 py-5 contactSec">
                    <div class="contactWrapper">
                        <h5 class="fw-500 text-uppercase text-info mb-3 fs-18">
                            contact our reach-x expert
                        </h5>


                        <div class="form-row">
                            <div class="col-md-4 col-xl-12">
                                <div class="form-group">
                                    <input class="form-control modal-control fs-12 fw-500 py-2 bg-white" type="text"
                                        name="person_name" id="person_name" formControlName="person_name" maxlength=255
                                        required
                                        [ngClass]="{'border border-danger':(person_name.dirty || person_name.touched) && person_name.invalid==true}"
                                        placeholder="Your Name" />
                                    <span
                                        *ngIf="(person_name.dirty || person_name.touched) && person_name.invalid && person_name.errors.required">
                                        Name is required.
                                    </span>
                                    <span
                                        *ngIf="(person_name.dirty || person_name.touched) && person_name.invalid && person_name.errors.invalidName">
                                        Value has to be a valid name (i.e. FirstName LastName).
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-4 col-xl-12">
                                <div class="form-group">
                                    <input type="text" name="person_email" id="person_email" maxlength=255
                                        formControlName="person_email" required
                                        class="form-control modal-control fs-12 fw-500 py-2 bg-white"
                                        [ngClass]="{'border border-danger':(person_email.dirty || person_email.touched) && person_email.invalid==true}"
                                        placeholder="Email ID" />
                                    <span class="ng-invalid"
                                        *ngIf="(person_email.dirty || person_email.touched) && person_email.invalid && person_email.errors.required">
                                        Email is required.
                                    </span>
                                    <span
                                        *ngIf="(person_email.dirty || person_email.touched) && person_email.invalid && person_email.errors.invalidEmail">
                                        Value has to be a valid email address.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-xl-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-prepend select-field property">
                                            <select name="" id=""
                                                class="custom-select form-control fs-12 fw-500 py-2 h-100 rounded-left rounded-0 border-right-0">
                                                <option value="">+91</option>
                                                <option value="">+92</option>
                                                <option value="">+93</option>
                                                <option value="">+94</option>
                                                <option value="">+95</option>
                                                <option value="">+96</option>
                                            </select>
                                        </div>

                                        <input type="text" name="person_phone" id="person_phone" maxlength=10 required
                                            formControlName="person_phone"
                                            class="form-control modal-control fs-12 fw-500 py-2 bg-white"
                                            [ngClass]="{'border border-danger':(person_phone.dirty || person_phone.touched) && person_phone.invalid==true }"
                                            placeholder="Your Phone" />
                                        <span
                                            *ngIf="(person_phone.dirty || person_phone.touched) && person_phone.invalid && person_phone.errors.required">
                                            Mobile is required.
                                        </span>
                                        <span
                                            *ngIf="(person_phone.dirty || person_phone.touched) && person_phone.invalid && person_phone.errors.invalidNumber">
                                            Value has to be a number.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="form-group">
                            <textarea name="message" id="message" formControlName="message"
                                class="form-control fs-12 fw-500 py-2" id="" cols="30" rows="3"
                                [ngClass]="{'border border-danger': (message.dirty || message.touched) &&  message.invalid==true}"
                                placeholder="Write your message"></textarea>
                            <span
                                *ngIf="(message.dirty || message.touched) && message.invalid && message.errors.required">
                                Message is required.
                            </span>
                        </div>
                        <div class="form-group">
                            <div class="custom-control mb-2">
                                <input type="checkbox" id="loan_interested"
                                    formControlName="loan_interested" name="loan_interested" value="true" checked>
                                <label for="loan_interested" class="fs-11 text-gray">I am
                                    interested in Bank Loan</label>
                            </div>
                            <div class="custom-control">
                                <input type="checkbox"
                                    formControlName="alert_subscription" name="alert_subscription" id="alert_subscription" value="true">
                                <label for="alert_subscription" class="fs-11 text-gray"> Send regular
                                    Property Alerts and share my contact details with seller.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-success fs-14 fw-500 text-uppercase px-3"
                                [ngClass]="{'border border-dark':!contactform.valid,'default-btn page-btn box-btn':contactform.valid}"
                                [disabled]="!this.contactform.valid">Contact
                                us</button>
                            <button type="button" class="btn btn-dark fs-14 fw-500 text-uppercase px-3 ml-2 ask-realzo"
                                onclick="$('.chatBot').addClass('active');">
                                <span class="d-none d-md-inline-block"><img src="assets/images/realzo.svg" class="mr-1"
                                        alt=""></span> Ask Realzo
                            </button>
                        </div>
                        <div class="content h-100" *ngIf="this.response_message.status_desc!=''">
                            <div class="col-xs-12">
                                <p class="alert"
                                    [ngClass]="{'alert-danger': this.response_message.status_code=='error','alert-success': this.response_message.status_code!='error'}">
                                    <strong>{{ this.response_message.status_desc }}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- PROPERTIES DETAIL END HERE -->
<!-- SIMILAR PROPERTIES BEGIN HERE -->
<div class="row bg-white py-5 border-bottom afterPropertyDetail">
    <div class="col-12">
        <h5 class="text-info text-uppercase fw-400 text-center mb-3 mt-0">
            You might also like these similar properties
        </h5>
    </div>
    <div class="col-xl-11 m-auto properties">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let allProperty of exclusiveAllProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{allProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{allProperty.propertyId}}"
                                    class="text-info">{{allProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{allProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{allProperty.propertyPrice[0].min}} to
                                    {{allProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{allProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{allProperty.propertySize[0].min}} to
                                    {{allProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{allProperty.propertyId}}"
                                    class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
<!-- SIMILAR PROPERTIES END HERE -->
<!-- MORE PROPERTIES BEGIN HERE -->
<div class="row bg-white py-5 border-bottom">
    <div class="col-12">
        <h5 class="text-info text-uppercase fw-400 text-center mb-3 mt-0">
            More properties by <span class="fw-600">Reach Promoters</span>
        </h5>
    </div>
    <div class="col-xl-11 m-auto properties">
        <owl-carousel-o [options]="exclusiveProjects">
            <ng-template carouselSlide *ngFor="let allProperty of exclusiveAllProperties">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{allProperty.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <i class="fas fa-share-alt fs-18"></i>
                                </a>
                                <a href="" class="d-inline-block">
                                    <i class="fas fa-heart fs-18"></i>
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{allProperty.propertyId}}"
                                    class="text-info">{{allProperty.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{allProperty.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{allProperty.propertyPrice[0].min}} to
                                    {{allProperty.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{allProperty.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{allProperty.propertySize[0].min}} to
                                    {{allProperty.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{allProperty.propertyId}}"
                                    class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
<!-- MORE PROPERTIES END HERE -->