<div class="homeSignUp ">
    <div class="container">
        <div class="row ">
            <div class="col-md-5 text-center align-self-end pt-4">
                <img src="assets/images/sign-up-mobile.jpg" class="img-fluid" alt="">
                <div class="mobileSignUpBtn text-center">
                    <a href="" class="btn btn-success text-uppercase fw-500 fs-12">
                        Sign Up
                    </a>
                </div>
            </div>
            <div class="col-md-7 align-self-center py-4">
                <h5 class="text-info text-uppercase fw-400">
                    sign up <span class="fw-700">today</span>
                </h5>
                <p class="fs-14 text-gray">Use the Reach-X platform to accelerate your real estate transactions</p>
                <a href="" class="btn btn-success text-uppercase fw-300 fs-12 py-2 px-3">
                    Get Started
                </a>
                <div class="mt-4">
                    <a href="" class="d-inline-block mr-3">
                        <img src="assets/images/google-play-btn.jpg" width="137" alt="">
                    </a>
                    <a href="" class="d-inline-block">
                        <img src="assets/images/app-store-btn.jpg" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
