import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public getToken(): string {
    var token='TURFM05tSmxOR00yWVRka05HUXlOV0k0T1dFd1lXRXpaRGxsTURJeVpEVT0=';
    return atob(token);
    //return localStorage.getItem('token');
  }
  constructor() { }
}

