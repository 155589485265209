<div class="mt-5">
    <!-- WHY REACH-X HEADING BEGIN HERE -->
    <div class="row">
        <div class="container py-5 text-center">
            <h3 class="text-info fw-700 text-uppercase mb-0">why reach-x and how it's different</h3>
            <small class="fs-20 text-gray">REACH-X is unlike any other online real estate site. </small>
        </div>
    </div>
    <!-- WHY REACH-X HEADING END HERE -->
    <!-- MARKETPLACE SECTION BEGIN HERE -->
    <div class="row bg-light-green">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    REACH-X is not just web-site to showcase properties and listing, it is a comprehensive marketplace that covers all aspects of the transaction and post-transaction processes. 
                </div>
                <div class="col-md-2 text-md-right text-center mt-4 mt-md-0">
                    <img src="assets/images/icons/marketplace.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- MARKETPLACE SECTION END HERE -->
    <!-- TECHNOLOGY SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/education.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    REACH-X technology is powered by a state of the art architecture, artificial Intelligence and machine learning engines created by a team of people who have successful experience in developing and managing large multi-tenant global exchanges for insurance, banking and travel industry.
                </div>
            </div>
        </div>
    </div>
    <!-- TECHNOLOGY SECTION END HERE -->
    <!-- MARKETPLACE SECTION BEGIN HERE -->
    <div class="row bg-light-green mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    <p>Unlike other sites that only offer to initiate a transaction by providing an electronic online medium, REACH-X goes multi-steps beyond by integrating the technology with seasoned real estate professionals behind the platform. </p>
                    <p class="mb-0">The combination of people and technology ensures an enhanced user experience at all stages as data is checked for accuracy, processes are reviewed for regulations & compliance, and service providers are scrutinized for performance behind the scenes while users can conduct business on a seamless marketplace.</p>
                </div>
                <div class="col-md-2 text-md-right text-center mt-4 mt-md-0">
                    <img src="assets/images/icons/computer.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- MARKETPLACE SECTION END HERE -->
    <!-- TECHNOLOGY SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/hands-and-gestures.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    REACH-X is not just a technology solution. It is a marketplace that is governed, developed and managed by the real estate industry itself. The pioneers and experts from the real estate industry have come together to create this platform best suited for their needs to convert an age old real estate processes to a new era of doing business that is virtual, online, easy and industry compliant. 
                </div>
            </div>
        </div>
    </div>
    <!-- TECHNOLOGY SECTION END HERE -->
    <!-- PLATFORM SECTION BEGIN HERE -->
    <div class="row">
        <div class="container py-5">
            <div class="text-center">
                <h4 class="text-info text-uppercase fs-24 fw-300">
                    Put the <span class="fw-700">reach-X</span> platform to <br> work for you
                </h4>
                <small class="text-gray fs-16">
                    REACH-X is the first ever simplified yet futuristic end-to-end transaction platform for Buyers, Sellers, Brokers and many other entities associated with real estate market in India. 
                </small>
                <div class="mt-3">
                    <small class="text-dark fs-16">
                        Experience the way of doing business
                    </small>
                </div>
            </div>
        </div>
    </div>
    <!-- PLATFORM SECTION END HERE -->
    <!-- TABBING SECTION BEGIN HERE -->
    <div class="row pb-5 whyReachxTabs">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-4 mb-3 mb-md-0">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active brokers mb-md-3" id="v-pills-brokers-tab" data-toggle="pill" href="#v-pills-brokers" role="tab" aria-controls="v-pills-brokers" aria-selected="true">
                            <span class="d-inline-block d-md-inline-block mr-md-3 mb-2 mb-md-0">
                                <img src="assets/images/icons/brokers.svg" alt="">
                            </span> Brokers
                        </a>
                        <a class="nav-link buyers  mb-md-3" id="v-pills-buyers-tab" data-toggle="pill" href="#v-pills-buyers" role="tab" aria-controls="v-pills-buyers" aria-selected="false">
                            <span class="d-inline-block d-md-inline-block mr-md-3 mb-2 mb-md-0">
                                <img src="assets/images/icons/buyer.svg" alt="">
                            </span> Buyers
                        </a>
                        <a class="nav-link sellers" id="v-pills-sellers-tab" data-toggle="pill" href="#v-pills-sellers" role="tab" aria-controls="v-pills-sellers" aria-selected="false">
                            <span class="d-inline-block d-md-inline-block mr-md-3 mb-2 mb-md-0">
                                <img src="assets/images/icons/seller.svg" alt="">
                            </span> Sellers
                        </a>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-brokers" role="tabpanel" aria-labelledby="v-pills-brokers-tab">
                            <h4 class="text-warning text-uppercase fw-700">
                                Brokers
                            </h4>
                            <ul class="list-unstyled bullet brokers">
                                <li>Explore and connect with hundreds of pre-qualified buyers and well established sellers to close transactions faster than ever all through a digital platform.</li>
                                <li>Run complimentary marketing campaigns and evaluate the success in real-time and follow up on the strongest pre-qualified leads.</li>
                                <li>Streamline the way you work with real-time insights from our all-in-one broker dashboard</li>
                                <li>Get property alerts and up-front due diligence on properties precision-matched to your buying objectives</li>
                                <li>Combining proprietary data and insights, we precisely identify the best property opportunities for potential buyers</li>
                                <li>Focus your effort and marketing spend on the most relevant buyer pool from our pool of global investors.</li>
                            </ul>
                            <p>
                                <a href="#" class="btn-link text-underline text-uppercase fw-500">know more to buy with confidence</a>
                            </p>
                            <p>
                                <button class="btn btn-dark fs-14 fw-500 text-white text-uppercase py-2 px-3" data-toggle="modal" data-target="#signup">
                                    Join In
                                </button>
                            </p>
                        </div>
                        <div class="tab-pane fade" id="v-pills-buyers" role="tabpanel" aria-labelledby="v-pills-buyers-tab">
                            <h4 class="text-info text-uppercase fw-700">
                                Buyers
                            </h4>
                            <ul class="list-unstyled bullet buyers">
                                <li>Explore hundreds of active property listings</li>
                                <li>View property details, street view, videos, and documentations - all on the platform</li>
                                <li>Reach out and work directly with matched Brokers through the platform that are aligned with your investment goals</li>
                                <li>Get unprecedented access to market analysis and due diligence documents of the properties you are looking for</li>
                                <li>Negotiate with Brokers within the platform by leveraging its comprehensive communication tools</li>
                                <li>Complete the business transaction end-to-end virtually on the platform without ever stepping out. </li>
                                <li>Securely acquire properties online, with confidence - backed by both Reach-X platform and Reach Promoters leading market experts. </li>
                            </ul>
                            <p>
                                <a href="#" class="btn-link text-underline text-uppercase fw-500">know more to buy with confidence</a>
                            </p>
                            <p>
                                <button class="btn btn-dark fs-14 fw-500 text-white text-uppercase py-2 px-3" data-toggle="modal" data-target="#signup">
                                    Join In
                                </button>
                            </p>
                        </div>
                        <div class="tab-pane fade" id="v-pills-sellers" role="tabpanel" aria-labelledby="v-pills-sellers-tab">
                            <h4 class="text-success text-uppercase fw-700">
                                sellers
                            </h4>
                            <ul class="list-unstyled bullet sellers">
                                <li>Platform helps to Identify and match you with Brokers based on geographical expertise and the ones that align with your vision. </li>
                                <li>On-board assets easily - Use Reach-X platform and Reach group's real estate experts to help you create state of the art 360 degree and Google street view ready portfolio of your property</li>
                                <li>Work with Reach experts to create all required paperwork, perform cost analysis, legal documents and compliance requirements and upload on the platform for easy access to Brokers and Sellers without a need for in-person paper submission</li>
                                <li>Gain visibility into buyer activity and achieve an optimal outcomes with the Reach-X seller dashboard.</li>
                                <li>Our predictive modeling measures the likelihood of a property trading successfully on our platform</li>
                            </ul>
                            <p>
                                <a href="#" class="btn-link text-underline text-uppercase fw-500">know more to buy with confidence</a>
                            </p>
                            <p>
                                <button class="btn btn-dark fs-14 fw-500 text-white text-uppercase py-2 px-3" data-toggle="modal" data-target="#signup">
                                    Join In
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- TABBING SECTION END HERE -->
    <!-- GET TO KNOW YOUR REACH BEGIN HERE -->
    <div class="row bg-white">
        <div class="container py-4 text-center">
            <a routerLink="/reachx-platform" class="btn btn-success fs-14 btn-lg fw-500 text-white text-uppercase py-2 px-3">
                Get to know your reach
            </a>
        </div>
    </div>
    <!-- GET TO KNOW YOUR REACH END HERE -->
    <!-- WHAT ARE YOU WAITING FOR BEGIN HERE -->
    <div class="row bg-orange">
        <div class="container py-4 text-center text-white">
            <h5 class="fw-500 fs-20">What are you waiting for?</h5> 
            <p class="fs-20 mb-0"><a href="" data-toggle="modal" data-target="#signup" class="text-success-50">Join In</a> the Reach-X platform to fuel your future in commercial real estate</p>
        </div>
    </div>
    <!-- WHAT ARE YOU WAITING FOR END HERE -->
</div>