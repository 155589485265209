<div class="card mb-3">
  <div class="card-header" id="mediaHeading">
    <h2 class="mb-0">
      <button class="btn btn-link btn-block text-left collapsed pl-4 p-0" type="button" data-toggle="collapse"
        data-target="#mediaCollapse" aria-expanded="false" aria-controls="mediaCollapse">
        Media
        <div class="green-check disabled">
          <img src="assets/images/icons/green-check.svg" alt="">
        </div>
      </button>
    </h2>
  </div>
  <div id="mediaCollapse" aria-labelledby="mediaHeading" data-parent="#post-property-panels">
    <div class="card-body">
      <div class="mt-n3 mb-2 fw-700">
        Property Photos
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="uploadImg w-100 rounded text-center d-flex">
            <div class="row align-items-center w-100 m-0">
              <div class="col-md-12">
                <span class="uploadIcon d-inline-block mb-2">
                  <img src="assets/images/icons/cloud-computing.svg" alt="">
                </span>
                <p class="mb-0">Drag and Drop photos here to upload</p>
                <p class="fs-20">or</p>
                <div class="d-inline-block w-auto">
                  <input multiple class="custom-input-file" type="file" name="fileList" #fileInput
                    (change)="onChange(fileInput.files)" />
                </div>
              </div>
            </div>
          </div>
          <small class="mt-2 d-inline-block">File size for each photo up to 4mb, file types
            allowed JPEG, PNG, GIF only.</small>
          <div class="mt-3">
            <div *ngFor="let i of [0, 1, 2, 3, 4]" class="media mt-2"
              [ngClass]="{'d-none': fileNames[i] == 'No file selected'}">
              <img [src]="imageUrls[i]" alt="" class="mr-3" width="100" height="100">
              <div class="media-body">
                <h6>{{ fileNames[i] }}</h6>
                <div class="progress" style="height: 5px;">
                  <div class="progress-bar" role="progressbar" style="width: {{progress[i]}}%;"
                    attr.aria-valuenow="{{progress[i]}}" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="row mt-1 fs-10">
                  <div class="col-md-6">
                    {{uploadedSize[i]}} mb of {{fileSize[i]}} mb
                  </div>
                  <div class="col-md-6 text-right">
                    {{progress[i]}}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div *ngFor="let media of medias" class="col" [ngClass]="{'d-none': deleteImages.includes(media.row_id)}">
              <div class="media-thumbnail upload-media">
                <div class="item-img" id="">
                  <img src="{{media.media_type}},{{media.media_data}}" class="img-fluid" alt="">
                </div>
                <button class="close position-absolute" (click)="trashImage(media.row_id)">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="mt-3 mb-2 fw-700">
                Video Walkthrough
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="uploadImg rounded text-center align-items-center d-flex justify-content-center"
                style="height: 192px;">
                <div class="d-inline-block">
                  <img src="assets/images/icons/video.svg" alt="">
                  <div class="mt-2">Drag and Drop</div>
                </div>
              </div>
              <small class="mt-2 d-inline-block">
                File size up to 25mb
              </small>
            </div>
            <div class="col-md-8">
              Enhance the listing by adding a video or <br> 3D walkthrough.
              Contact Reach-X and we <br> can help you create an awesome multi-dimensional
              <br> view the property.
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="mt-3 mb-2 fw-700">
                Property Description
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <textarea name="" id="" cols="30" rows="7" class="form-control"></textarea>
              <small class="mt-2 d-inline-block">
                Max limit 500 words
              </small>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-right">
              <button class="btn btn-light mr-2 py-2 px-3" data-toggle="collapse" data-target="#featuredCollapse">
                Back
              </button>
              <button class="btn btn-dark py-2 px-3" (click)="onUpload()" [attr.disabled]="isUploading ? '' : null"
                data-toggle="collapse" data-target="#pricingCollapse">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

