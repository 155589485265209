<!-- SORTING AND SEARCH RESULTS BEGIN HERE -->
<div class="position-fixed sortingBar border-bottom py-1 bg-light mt-n1 mt-md-n3 mt-lg-0">
    <div class="float-left">
        <strong>{{propertyList?.record_count}}</strong> properties <span class="d-none d-md-inline-block">that match
            your search</span>
    </div>
    <div class="float-right">
        <div class="dropdown searchSorting">
            <a href="" class="dropdown-toggle text-dark" data-toggle="dropdown" id="searchSorting">
                Sort by
            </a>
            <div class="dropdown-menu dropdown-menu-right py-3" aria-labelledby="searchSorting">
                <a class="dropdown-item" href="#">Most Recent Change</a>
                <a class="dropdown-item" href="#">Price (High to Low)</a>
                <a class="dropdown-item" href="#">Price (Low to High)</a>
                <a class="dropdown-item" href="#">Newest</a>
                <a class="dropdown-item" href="#">Top Selling</a>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>
<!-- SORTING AND SEARCH RESULTS END HERE -->
<div class="searchRecord pt-lg-4">

    <div class="searchListRecordContainer pt-3">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-12" *ngFor="let property of propertyList?.record">
                <div class="card mb-3 shadow-sm">
                    <div class="media">
                        <div class="media-thumbnail">
                            <div class="item-img">
                                <!-- <div id="carouselControls{{property.id}}" class="carousel slide h-100" data-ride="carousel"
                                    data-interval="false" (mouseover)="change_hovered_property(property.id)">
                                    <div class="carousel-inner h-100">
                                        <div class="carousel-item h-100"
                                            [class.active]="proThumb.thumbnailActive ? 'active' : '' "
                                            *ngFor="let proThumb of property.propertyThumbnailCarousel">
                                            <img src="assets/images/property/{{proThumb.thumbnail}}" class="img-fluid"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="carousel-controls"
                                        *ngIf="property.propertyThumbnailCarousel?.length > 1">
                                        <a class="carousel-control-prev" href="#carouselControls{{property.id}}" role="button"
                                            data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselControls{{property.id}}" role="button"
                                            data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div> -->
                                <div id="carouselControls{{property.id}}" class="carousel slide h-100"
                                    data-ride="carousel" data-interval="false">
                                    <div class="carousel-inner h-100">
                                        <div class="carousel-item h-100"
                                            *ngFor="let m of property.media_thumbnails.split(','); let i = index"
                                            [ngClass]="{'active': i == 0}">
                                            <img src="{{m}}" class="img-fluid" alt=""
                                                onerror="this.onerror=null;this.src='assets/images/services/home-loan.jpg';">
                                        </div>
                                        <div *ngIf="property.media_thumbnails.split(',')[0].length==0"
                                            class="carousel-item h-100">
                                            <img src="assets/images/services/home-loan.jpg" class="img-fluid" alt="">
                                        </div>
                                        <div *ngIf="property.media_thumbnails.split(',').length>0"
                                            class="carousel-item h-100">
                                            <img src="{{property.media_thumbnails.split(',')[0]}}" class="img-fluid" alt="">
                                        </div>

                                    </div>
                                    <div class="carousel-controls">
                                        <a class="carousel-control-prev" href="#carouselControls{{property.id}}"
                                            role="button" data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselControls{{property.id}}"
                                            role="button" data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-body p-3 position-relative">
                            <span class="badge badge-pill position-absolute {{property.propertyTypeClass}} propertyType"
                                style="z-index:1">{{property.property_type}}</span>
                            <div class="row">
                                <div class="col-10 col-md-12 col-lg-7">
                                    <h6 class="text-info fs-14 fw-700 mb-1">
                                        <a routerLink="airia-mall" class="text-info" target="_blank"
                                            (mouseover)="change_hovered_property(property.id)">
                                            {{property.product_title}}
                                        </a>
                                    </h6>
                                    <small class="fs-12">{{property.address_line1}}</small>
                                    <div class="mt-3 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600">Price</div>
                                        <div class="col text-truncate">{{property.property_price_min}}
                                            {{property.property_price_unit}} to {{property.property_price_max}}
                                            {{property.property_price_unit}}</div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600">Unit</div>
                                        <div class="col text-truncate">{{property.property_unit}}</div>
                                    </div>
                                    <div class="mt-1 form-row">
                                        <div class="col-3 col-md-3 col-xl-3 fw-600">Size</div>
                                        <div class="col text-truncate">{{property.property_size_min}}
                                            {{property.property_size_unit}} to {{property.property_size_max}}
                                            {{property.property_size_unit}}</div>
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 mt-3 mt-xl-0 align-self-end">
                                    <div class="btn-group btn-group-sm d-flex" role="group">
                                        <button type="button" class="btn btn-outline-secondary" placement="top"
                                            tooltip="Find More" (click)="detail_page()">
                                            <i class="fa fa-ellipsis-h"></i>
                                        </button>
                                        <button type="button" class="btn btn-outline-secondary" placement="top"
                                            tooltip="Let's Connect">
                                            <img src="assets/images/icons/connect.svg" height="14">
                                        </button>
                                        <button type="button" class="btn btn-outline-secondary" placement="top"
                                            tooltip="Share">
                                            <i class="fa fa-share-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-outline-secondary lst-btn" placement="top"
                                            tooltip="Save This">
                                            <i class="fa fa-heart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- SEARCH RESULT CONTAINER END HERE -->
    <!-- SEARCH PAGINATION BEGIN HERE -->
    <div class="paginationContainer">
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
                <li class="page-item" href="#" [class.disabled]="propertyList?.page == 1">
                    <a class="page-link " href="#" (click)="paginator(propertyList?.page, -1)" tabindex="-1"
                        aria-disabled="true">
                        <i class="fas fa-chevron-left"></i>
                    </a>
                </li>
                <li class="page-item" *ngFor="let p of propertyList?.page_list" [class.active]="p==propertyList?.page">
                    <a class="page-link" (click)="paginator(p, 0)" href="#">{{p}}</a></li>
                <li class="page-item" [class.disabled]="propertyList?.page == propertyList?.total_page">
                    <a class="page-link" href="#" (click)="paginator(propertyList?.page, 1)">
                        <i class="fas fa-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <!-- SEARCH PAGINATION END HERE -->
</div>
<!-- SEARCH RESULT CONTAINER BEGIN HERE -->