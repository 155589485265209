import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reach-x-platform',
  templateUrl: './reach-x-platform.component.html',
  styleUrls: ['./reach-x-platform.component.css']
})
export class ReachXPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
