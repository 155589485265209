import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs";
import { AuthService } from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   //Authorization: `Bearer ${localStorage.get('token')}`
  constructor(public auth: AuthService, private mywindow: Window){
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {       
        Authorization: `${atob(this.auth.getToken()).replace(/-/g,'') }`,
        Requester:   `${this.mywindow.location.host}`,
        'Content-Type':  'application/x-www-form-urlencoded'
      }
    });

    return next.handle(req);
  }
}
