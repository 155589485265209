import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { featuredDeveloper } from '../../../../appModals/featuredDevelopers.modals';

@Component({
  selector: 'home-featured-developers',
  templateUrl: './featured-developers.component.html',
  styleUrls: ['./featured-developers.component.css']
})
export class FeaturedDevelopersComponent implements OnInit {

  featuredDevelopers: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [''],
    margin: 30,
    autoWidth: true,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  // PROPERTY CATEGORIES
  featuredAllDevelopers : [];
  featuredOfficeDevelopers : []
  featuredApartmentDevelopers : []
  featuredPlotDevelopers : []
  featuredRentalDevelopers : []
  featuredIndustrialDevelopers : []

  constructor(private featuredDevelopersList : featuredDeveloper) { }

  ngOnInit(): void {
    // Get All Featured Developers
    this.featuredAllDevelopers = this.featuredDevelopersList.propertyList[0].featruedDeveloperAll;
    console.log(this.featuredAllDevelopers);
    // Get Office Featured Developers
    this.featuredOfficeDevelopers = this.featuredDevelopersList.propertyList[0].featuredDeveloperOffice;
    console.log(this.featuredOfficeDevelopers);
    // Get Apartment Featured Developers
    this.featuredApartmentDevelopers = this.featuredDevelopersList.propertyList[0].featuredDeveloperApartment;
    console.log(this.featuredApartmentDevelopers);
    // Get Plot Featured Developers
    this.featuredPlotDevelopers = this.featuredDevelopersList.propertyList[0].featuredDeveloperPlot;
    console.log(this.featuredPlotDevelopers);
    // Get Rental Featured Developers
    this.featuredRentalDevelopers = this.featuredDevelopersList.propertyList[0].featuredDeveloperRental;
    console.log(this.featuredRentalDevelopers);
    // Get Industrial Featured Developers
    this.featuredIndustrialDevelopers = this.featuredDevelopersList.propertyList[0].featuredDeveloperIndustrial;
    console.log(this.featuredIndustrialDevelopers);
  }

}
