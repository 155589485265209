import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { trendingProperties } from 'src/app/appModals/trending.modals';

@Component({
  selector: 'home-trending-projects',
  templateUrl: './trending-projects.component.html',
  styleUrls: ['./trending-projects.component.css']
})
export class TrendingProjectsComponent implements OnInit {

  trendingProjects: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [''],
    margin: 30,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      738: {
        items: 2
      },
      850: {
        items: 3
      }
    },
    nav: true
  }

  trendingProperties = [];

  constructor(private trendingproperty : trendingProperties) { }

  ngOnInit(): void {
    this.trendingProperties = this.trendingproperty.trending;
  }

}
