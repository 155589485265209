import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-reachx',
  templateUrl: './why-reachx.component.html',
  styleUrls: ['./why-reachx.component.css']
})
export class WhyReachxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
