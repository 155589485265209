import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { FormArray, FormBuilder, FormGroup, FormControl, Form, Validators, AbstractControl } from '@angular/forms';
import { postPropertyTemplate } from '../../appModals/postPropertyTemplate.modals';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { apiUrl } from '../../services/api-url';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-property',
  templateUrl: './post-property.component.html',
  styleUrls: ['./post-property.component.css']
})
export class PostPropertyComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  // MAP variables
  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 8,
    fullscreenControl: false
  };

  cities: {};

  getcity(): void {
    let query_params = new HttpParams();

    query_params = query_params.append('api', 'get_city_api');
    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.cities = refozoDBAPIService);
  }

  // Basic Form
  basicdetails = new FormGroup({
    building_type: new FormControl('None', [Validators.required]),
    building_subtype: new FormControl('None', [Validators.required]),
    basic_detail_completed: new FormControl('0')
  });


  // contactform = new FormGroup({   name: new FormControl('',[Validators.required,this.nameValidator]),
  //   email: new FormControl('', [Validators.required,this.emailValidator]),
  //   phone: new FormControl('', [Validators.required,this.phoneNumberValidator]),
  //   subject: new FormControl('', [Validators.required]),
  //   message: new FormControl(''),  
  // });
  get building_subtype() {
    return this.basicdetails.get('building_subtype');
  }
  // get name() {
  //   return this.contactform.get('name');
  // }
  // get email() {
  //   return this.contactform.get('email');
  // }
  // get subject() {
  //   return this.contactform.get('subject');
  // }

  nameValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/.test(control.value)
    return valid ? null : { invalidName: { valid: false, value: control.value } }
  }
  emailValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(control.value.toLowerCase())
    return valid ? null : { invalidEmail: { valid: false, value: control.value } }
  }

  phoneNumberValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^\d+$/.test(control.value)
    return valid ? null : { invalidNumber: { valid: false, value: control.value } }
  }


  // Property Form
  // property_data_template = {
  //   layout_info: [
  //     { type_code: 'LI01', selected: true, type_title:'Study Room', type_icon_path: 'assets/images/icons/study-room.svg' },
  //     { type_code: 'LI02', selected: false,type_title:'Media Room', type_icon_path: 'assets/images/icons/media-room.svg' },
  //     { type_code: 'LI03', selected: true, type_title:'Pooja Room', type_icon_path: 'assets/images/icons/pooja-room.svg' },
  //     { type_code: 'LI04', selected: true, type_title:'Store Room', type_icon_path: 'assets/images/icons/store-room.svg' },
  //     { type_code: 'LI05', selected: true, type_title:'Servant Room', type_icon_path: 'assets/images/icons/servant-room.svg' }
  //   ]
  // }
  property_data_template: any;


  propertydetails: FormGroup;
  layout_info: FormArray;
  locationdetails = new FormGroup({
    location_address_display: new FormControl(false),
    location_address: new FormControl('None', [Validators.required]),
    location_area: new FormControl('None'),
    location_city: new FormControl('None', [Validators.required]),
    location_society: new FormControl('None'),
    location_pincode: new FormControl('None', [Validators.required]),
    location_state: new FormControl('None'),
    location_country: new FormControl('None'),
    location_landmark1_type: new FormControl('None'),
    location_landmark1_desc: new FormControl('None'),
    location_landmark2_type: new FormControl('None'),
    location_landmark2_desc: new FormControl('None'),
    location_landmark3_type: new FormControl('None'),
    location_landmark3_desc: new FormControl('None'),
    location_lat: new FormControl('None'),
    location_long: new FormControl('None'),
    location_detail_completed: new FormControl('0')
  });

  featuredetails: FormGroup;
  property_features: FormArray;
  sport_amenities: FormArray;
  convenience_amenities: FormArray;
  safety_amenities: FormArray;
  leisure_amenities: FormArray;
  environment_amenities: FormArray;


  pricedetails = new FormGroup({
    price_expected: new FormControl('None', [Validators.required]),
    price_all_inclusive: new FormControl(false),
    price_tax_included: new FormControl(false),
    price_negotiable: new FormControl(false),
    price_unit: new FormControl('None'),
    price_currency: new FormControl('INR'),
    additional_charges: new FormControl('None'),
    price_per_sqft: new FormControl('None'),
    price_remark: new FormControl('Price expected is good'),
    price_detail_completed: new FormControl('0')
  });

  profiledetails = new FormGroup({
    first_name: new FormControl('None', [Validators.required]),
    last_name: new FormControl('None', [Validators.required]),
    mobile_number: new FormControl('None'),
    home_number: new FormControl('None'),
    office_number: new FormControl('None'),
    email: new FormControl('None', [Validators.required]),
    user_password: new FormControl('None', [Validators.required])
  });

  profile_saved = false;
  // SELECT PLAN
  selectedPlan = [
    {
      "id": "basic",
      "planBtn": "basic-btn",
      "planActive": true
    },
    {
      "id": "silver",
      "planBtn": "silver-btn",
      "planActive": false
    },
    {
      "id": "gold",
      "planBtn": "gold-btn",
      "planActive": false
    },
    {
      "id": "platinum",
      "planBtn": "platinum-btn",
      "planActive": false
    }
  ]

  // Media Variables
  fileQueue = 0;
  progress = [0, 0, 0, 0, 0];
  uploadedSize = ['0', '0', '0', '0', '0'];
  fileSize = ['0', '0', '0', '0', '0'];
  infoMessage: any;
  isUploading: boolean = false;
  fileList: FileList;
  medias: any;
  imageUrl: any = '';
  fileName = '';
  imageUrls: string[] = ['', '', '', '', ''];
  uploadCount = 0;
  deleteImages = [];
  is_video = false;

  fileNames = ["No file selected",
    "No file selected",
    "No file selected",
    "No file selected",
    "No file selected"]
  // Video Variables
  vprogress = 0;
  vuploadedSize = '0';
  vfileSize = '0';
  videoUrl: any = '';
  user_type = 'UT01';
  property_usage = "PU01";
  property_action = 'PA01';
  plan_code = 'basic';
  plan_detail_completed = '0';
  media_detail_completed = '0';

  vfileName = "No file selected";
  active_menu = 'location';
  total_point = 800;
  current_point = 0;
  complete_percent = (this.current_point / this.total_point) * 100;

  update_point() {
    this.current_point = 0;
    this.current_point += parseInt(this.locationdetails.get('location_detail_completed').value);
    this.current_point += parseInt(this.basicdetails.get('basic_detail_completed').value);

    this.current_point += parseInt(this.propertydetails.get('property_detail_completed').value);

    this.current_point += parseInt(this.featuredetails.get('feature_detail_completed').value);

    if (this.media_detail_completed == '100') {
      this.current_point += 100;
    }
    if (this.profile_saved) {
      this.current_point += 100;
    }

    if (this.plan_detail_completed == '100') {
      this.current_point += 100;
    }

    this.current_point += parseInt(this.pricedetails.get('price_detail_completed').value);

    this.complete_percent = (this.current_point / this.total_point) * 100;
  }

  getmedia(): void {

    let query_params = new HttpParams();

    query_params = query_params.append('api', 'get_mediadata');
    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.medias = refozoDBAPIService,
        error => console.error(),
        () => {
          this.is_video = false;
          this.media_detail_completed = '0';
          for (let media of this.medias) {

            if ((media['media_type']).includes('video')) {
              this.is_video = true;
              break;
            }
            else {
              this.media_detail_completed = '100';
            }
          }
          this.update_point();
        }
      );
  }
  constructor(private _sidebarService : SidebarService,private router: Router,private _postPropertyHeader: HeaderService, private refozoDBAPIService: refozoDBAPIService, private http: HttpClient, private cookieService: CookieService, private apiUrl: apiUrl, private fb: FormBuilder, private postPropertyTemplate: postPropertyTemplate) { }

  buildlayout(dic_emplate) {
    const arr = dic_emplate.map(a => {
      return this.fb.control(a.selected);
    });
    return this.fb.array(arr);
  }
  buildlayout2() {
    const arr = this.property_data_template.layout_info.map(layout_info => {
      return this.fb.control(layout_info.selected);
    });
    return this.fb.array(arr);
  }

  ngOnInit(): void {
    // this.renderPropertyTemplate();
    this.getcity();
    this.property_data_template = this.postPropertyTemplate.dataTemplate;
    
    this.renderPropertyTemplate2();
    this.getmedia();
    this.get_field_value();
    // this.center = {
    //   lat: 28.7041,
    //   lng: 77.1025
    // }

    this._postPropertyHeader.postPropertyHeader.next(true);
    this._sidebarService.sidebarActive.next(true);
    this._postPropertyHeader.admin.next(false);
    // this._sidebarService.adminMenu.next(false);
  }
  ngOnDestroy(): void {
    this._postPropertyHeader.postPropertyHeader.next(false);
    this._sidebarService.sidebarActive.next(false);
    this._postPropertyHeader.admin.next(false);
    // this._sidebarService.adminMenu.next(false);
  }

  update_property(field_name, field_value) {
    var response: any;
    var post_data = {};
    post_data[field_name] = field_value;
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'update_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => this.update_point())

  }
  update_property_multi(field_name: any, field_value: any) {
    var response: any;
    var post_data = {};
    for (var i = 0; i < field_name.length; i++) {
      post_data[field_name[i]] = field_value[i];
    }
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'update_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => this.update_point())
  }

  saveFeatureDetails(post_data) {
    var checkValues = ',';
    for (var i = 0; i < post_data.property_features.length; i++) {
      if (post_data.property_features[i]) {
        checkValues = checkValues + this.property_data_template.property_features[i].type_code + ','
      }
    }
    post_data.property_features = checkValues;
    var checkValues = ',';
    for (var i = 0; i < post_data.sport_amenities.length; i++) {
      if (post_data.sport_amenities[i]) {
        checkValues = checkValues + this.property_data_template.sport_amenities[i].type_code + ','
      }
    }
    post_data.sport_amenities = checkValues;
    var checkValues = ',';
    for (var i = 0; i < post_data.convenience_amenities.length; i++) {
      if (post_data.convenience_amenities[i]) {
        checkValues = checkValues + this.property_data_template.convenience_amenities[i].type_code + ','
      }
    }
    post_data.convenience_amenities = checkValues;
    var checkValues = ',';
    for (var i = 0; i < post_data.safety_amenities.length; i++) {
      if (post_data.safety_amenities[i]) {
        checkValues = checkValues + this.property_data_template.safety_amenities[i].type_code + ','
      }
    }
    post_data.safety_amenities = checkValues;
    var checkValues = ',';
    for (var i = 0; i < post_data.leisure_amenities.length; i++) {
      if (post_data.leisure_amenities[i]) {
        checkValues = checkValues + this.property_data_template.leisure_amenities[i].type_code + ','
      }
    }
    post_data.leisure_amenities = checkValues;
    var checkValues = ',';
    for (var i = 0; i < post_data.environment_amenities.length; i++) {
      if (post_data.environment_amenities[i]) {
        checkValues = checkValues + this.property_data_template.environment_amenities[i].type_code + ','
      }
    }
    post_data.environment_amenities = checkValues;
    this.saveFormData(post_data)
  }



  savePropertyDetails(post_data) {
    var checkValues = ',';
    for (var i = 0; i < post_data.layout_info.length; i++) {
      if (post_data.layout_info[i]) {
        checkValues = checkValues + this.property_data_template.layout_info[i].type_code + ','
      }
    }
    post_data.layout_info = checkValues;
    this.saveFormData(post_data)
  }

  saveFormData(post_data) {
    var response: any;

    let query_params = new HttpParams();
    query_params = query_params.append('api', 'update_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => this.update_point())
  }

  getPropertyDetails(form_variable: FormGroup) {
    var post_data = form_variable.value;
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'get_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => {
          this.setPropertyDetails(response);
          this.update_point();
        })

  }


  setPropertyDetails(response) {
    response.layout_info = this.getCheckboxBoolArray(response.layout_info, this.property_data_template.layout_info);
    this.propertydetails.patchValue(response);
  }

  getFeatureDetails(form_variable: FormGroup) {
    var post_data = form_variable.value;
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'get_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => {
          this.setFeatureDetails(response);
          this.update_point();
        })

  }

  getCheckboxBoolArray(colname, a) {
    var checkresult = [];
    for (var i = 0; i < a.length; i++) {
      if (colname.includes(a[i].type_code)) {
        checkresult.push(true);
      }
      else {
        checkresult.push(false);
      }
    }
    return checkresult
  }

  setFeatureDetails(response) {
    // var checkresult = [];
    // for (var i = 0; i < this.property_data_template.layout_info.length; i++) {
    //   if (response.layout_info.includes(this.property_data_template.layout_info[i].type_code)) {
    //     checkresult.push(true);
    //   }
    //   else {
    //     checkresult.push(false);
    //   }
    // }
    response.property_features = this.getCheckboxBoolArray(response.property_features, this.property_data_template.property_features);
    response.sport_amenities = this.getCheckboxBoolArray(response.sport_amenities, this.property_data_template.sport_amenities);
    response.convenience_amenities = this.getCheckboxBoolArray(response.convenience_amenities, this.property_data_template.convenience_amenities);
    response.safety_amenities = this.getCheckboxBoolArray(response.safety_amenities, this.property_data_template.safety_amenities);
    response.leisure_amenities = this.getCheckboxBoolArray(response.leisure_amenities, this.property_data_template.leisure_amenities);
    response.environment_amenities = this.getCheckboxBoolArray(response.environment_amenities, this.property_data_template.environment_amenities);
    this.featuredetails.patchValue(response);
  }

  getFormData(form_variable: FormGroup, loc = false) {
    var post_data = form_variable.value;
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'get_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => form_variable.patchValue(refozoDBAPIService),
        error => console.log(error),
        () => {
          if (loc) {
            this.center = {
              lat: parseFloat(this.locationdetails.get('location_lat').value),
              lng: parseFloat(this.locationdetails.get('location_long').value)
            }
          }
          this.update_point();
        })
  }

  renderPropertyTemplate() {
    var filter_data = {};
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'get_property_data_template');

    this.refozoDBAPIService.post(query_params, filter_data)
      .subscribe(refozoDBAPIService => this.property_data_template = refozoDBAPIService,
        error => console.log(error),
        () => this.renderPropertyTemplate2())
  }

  renderPropertyTemplate2() {
    this.layout_info = this.buildlayout2();
    this.property_features = this.buildlayout(this.property_data_template.property_features);
    this.sport_amenities = this.buildlayout(this.property_data_template.sport_amenities);
    this.convenience_amenities = this.buildlayout(this.property_data_template.convenience_amenities);
    this.safety_amenities = this.buildlayout(this.property_data_template.safety_amenities);
    this.leisure_amenities = this.buildlayout(this.property_data_template.leisure_amenities);
    this.environment_amenities = this.buildlayout(this.property_data_template.environment_amenities);
    this.featuredetails = this.fb.group({
      property_features: this.property_features,
      sport_amenities: this.sport_amenities,
      convenience_amenities: this.convenience_amenities,
      safety_amenities: this.safety_amenities,
      leisure_amenities: this.leisure_amenities,
      environment_amenities: this.environment_amenities,
      unique_features_1: ['None'],
      unique_features_2: ['None'],
      unique_features_3: ['None'],
      feature_detail_completed: ['0']
    });

    this.propertydetails = this.fb.group({
      bedroom_type: ['None', [Validators.required]],
      super_area: ['None', [Validators.required]],
      buildup_area: ['None'],
      carpet_area: ['None'],
      bedroom_count: ['None'],
      bathroom_count: ['None', [Validators.required]],
      balcony_count: ['None'],
      layout_info: this.layout_info,
      layout_other_info: ['None'],
      age: ['None'],
      facing: ['None'],
      ready_status: ['None'],
      property_detail_completed: ['0']
    });
    
    this.getFormData(this.locationdetails, true);
    this.getFormData(this.basicdetails);
    this.getPropertyDetails(this.propertydetails);
    this.getFeatureDetails(this.featuredetails);
    this.getFormData(this.pricedetails);
    this.getProfileData(this.profiledetails);
    this.update_point();

  }

  counter(field, operation) {
    var val = parseInt(field.value);
    if (isNaN(val)) {
      val = 0;
    }
    if (operation == '+') {
      field.setValue(val + 1);
    }
    if (operation == '-') {
      if (val > 0) {
        field.setValue(val - 1);
      }
      else {
        field.setValue('');
      }
    }
  }
  logCenter() {
    var center = JSON.parse(JSON.stringify(this.map.getCenter()));
    this.locationdetails.patchValue({
      location_lat: center.lat,
      location_long: center.lng
    });
  }

  saveProfileData(post_data) {
    var response: any;

    let query_params = new HttpParams();
    query_params = query_params.append('api', 'update_profile_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => {
          this.profile_saved = true;
          this.update_point();
        })
  }

  getProfileData(form_variable: FormGroup) {
    var post_data = form_variable.value;
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'get_profile_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => form_variable.patchValue(refozoDBAPIService),
      error => console.log('error',error),
      () => {
        console.log('First', this.profiledetails.get('first_name').value)
        if (this.profiledetails.get('first_name').value != '') {
          this.profile_saved = true;
        }
        this.update_point();
        console.log('Last', this.profiledetails.get('first_name').value)

      })
  }

  // Media functions
  onChange(fileList: FileList) {
    this.progress = [0, 0, 0, 0, 0];
    this.imageUrls = ['', '', '', '', ''];
    this.uploadedSize = ['0', '0', '0', '0', '0'];
    this.fileSize = ['0', '0', '0', '0', '0'];

    this.fileNames = ["No file selected",
      "No file selected",
      "No file selected",
      "No file selected",
      "No file selected"]

    if (fileList) {
      var file_count = 5;
      if (fileList.length < file_count) {
        file_count = fileList.length;
      }
      this.fileQueue = file_count;
      var readers0 = new FileReader();
      var readers1 = new FileReader();
      var readers2 = new FileReader();
      var readers3 = new FileReader();
      var readers4 = new FileReader();

      this.fileList = fileList;
      for (var i = 0; i < file_count; i++) {
        this.fileNames[i] = fileList[i].name;
        this.fileSize[i] = ((fileList[i].size / 1024) / 1024).toFixed(4);

        switch (i) {
          case 0:
            readers0.readAsDataURL(fileList[0]);
            readers0.onload = event => {
              this.imageUrls[0] = <string>readers0.result;
            };
            break;
          case 1:
            readers1.readAsDataURL(fileList[1]);
            readers1.onload = event => {
              this.imageUrls[1] = <string>readers1.result;
            };
            break;
          case 2:
            readers2.readAsDataURL(fileList[2]);
            readers2.onload = event => {
              this.imageUrls[2] = <string>readers2.result;
            };
            break;
          case 3:
            readers3.readAsDataURL(fileList[3]);
            readers3.onload = event => {
              this.imageUrls[3] = <string>readers3.result;
            };
            break;
          case 4:
            readers4.readAsDataURL(fileList[4]);
            readers4.onload = event => {
              this.imageUrls[4] = <string>readers4.result;
            };
            break;
        }

      }
    }
  }

  uploader(i) {
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'upload_mediadata');
    let post_data = {
      'imgData': encodeURIComponent(<string>this.imageUrls[i]),
      'imgId': i
    }
    this.progress[i] = 0;
    this.uploadedSize[i] = (parseFloat(this.fileSize[i]) * this.progress[i] / 100).toFixed(4);

    query_params = query_params.append('_session', this.cookieService.get('_session'));
    var queryString = Object.keys(post_data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(post_data[key])).join('&');

    this.http.post(this.apiUrl.BASE_POST_URL, queryString, {
      params: query_params,
      reportProgress: true,
      observe: 'events'
    }).subscribe(response => {
      if (response.type === HttpEventType.Response) {
      }
      if (response.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * response.loaded / response.total);
        this.progress[i] = percentDone;
        this.uploadedSize[i] = (parseFloat(this.fileSize[i]) * this.progress[i] / 100).toFixed(4);
      }
      this.isUploading = false;
      this.progress[<any>response] = 100;
      this.uploadedSize[<any>response] = this.fileSize[<any>response];
      // this.infoMessage = message;
    },
      error => console.log(error),
      () => {
        this.progress[i] = 100;
        this.uploadCount = this.uploadCount + 1;
        if (this.uploadCount == this.fileQueue) {
          this.getmedia();
          this.progress = [0, 0, 0, 0, 0];
          this.imageUrls = ['', '', '', '', ''];
          this.uploadedSize = ['0', '0', '0', '0', '0'];
          this.fileSize = ['0', '0', '0', '0', '0'];

          this.fileNames = ["No file selected",
            "No file selected",
            "No file selected",
            "No file selected",
            "No file selected"]

        }
        // this.imageUrls[i] = '';
      }

    )
  }

  onUpload() {
    this.uploadCount = 0;
    this.infoMessage = null;
    this.progress[0] = 0;
    this.isUploading = true;
    i = 0;
    // 
    for (var i = 0; i < this.imageUrls.length; i++) {
      if (this.imageUrls[i] != '') {
        this.uploader(i);
      }

    }
    // if (this.deleteImages.length > 0) {
    //   let query_params = new HttpParams();
    //   query_params = query_params.append('api', 'delete_mediadata');
    //   let post_data = {
    //     'del_ids': this.deleteImages.toString()
    //   }

    //   this.refozoDBAPIService.post(query_params, post_data)
    //     .subscribe(response => {
    //       this.isUploading = false
    //     },
    //       error => console.log(error),
    //       () => {
    //         this.deleteImages = [];
    //         this.getmedia();
    //       }

    //     )
    // }

  }
  trashImage(id) {
    this.deleteImages.push(id);
    if (this.deleteImages.length > 0) {
      let query_params = new HttpParams();
      query_params = query_params.append('api', 'delete_mediadata');
      let post_data = {
        'del_ids': this.deleteImages.toString()
      }
      this.refozoDBAPIService.post(query_params, post_data)
        .subscribe(response => {
          this.isUploading = false
        },
          error => console.log(error),
          () => {
            this.deleteImages = [];
            this.getmedia();
          }

        )
    }
  }

  onVideoChange(file: File) {

    this.vprogress = 0;
    this.vuploadedSize = '0';
    this.vfileSize = '0';
    this.videoUrl = '';
    this.vfileName = "No file selected";

    if (file) {

      var readers = new FileReader();
      readers.readAsDataURL(file);
      readers.onload = event => {
        this.videoUrl = <string>readers.result;

        this.vfileSize = ((file.size / 1024) / 1024).toFixed(4);;
        this.vfileName = file.name;
      }
    }

  }
  onVideoUpload() {
    this.vprogress = 0;
    this.isUploading = true;
    if (this.videoUrl != '') {
      let query_params = new HttpParams();
      query_params = query_params.append('api', 'upload_mediadata');
      let post_data = {
        'imgData': encodeURIComponent(<string>this.videoUrl),
        'imgId': 1
      }
      this.vprogress = 0;
      this.vuploadedSize = (parseFloat(this.vfileSize) * this.vprogress / 100).toFixed(4);

      query_params = query_params.append('_session', this.cookieService.get('_session'));
      var queryString = Object.keys(post_data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(post_data[key])).join('&');

      this.http.post(this.apiUrl.BASE_POST_URL, queryString, {
        params: query_params,
        reportProgress: true,
        observe: 'events'
      }).subscribe(response => {
        if (response.type === HttpEventType.Response) {
          this.vprogress = 100;
          this.vuploadedSize = this.vfileSize;
          this.isUploading = false;
        }
        if (response.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * response.loaded / response.total);
          this.vprogress = percentDone;
          this.vuploadedSize = (parseFloat(this.vfileSize) * this.vprogress / 100).toFixed(4);
        }
      },
        error => console.log(error),
        () => {
          this.vprogress = 0;
          this.vuploadedSize = '0';
          this.vfileSize = '0';
          this.videoUrl = '';
          this.vfileName = "No file selected";
          this.getmedia();

        }
      )
    }


  }

  update_active_menu(val) {
    this.active_menu = val;
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    var x = window.pageYOffset;
    if (x < 800) {
      this.active_menu = 'location';
    }
    else if (x < 1200) {
      this.active_menu = 'basics';
    }
    else if (x < 2000) {
      this.active_menu = 'property';
    }
    else if (x < 3500) {
      this.active_menu = 'features';
    }
    else if (x < 4600) {
      this.active_menu = 'media';
    }
    else if (x < 5500) {
      this.active_menu = 'price';
    }
    else if (x < 6000) {
      this.active_menu = 'profile';
    }
    else {
      this.active_menu = 'publish';
    }
  }
  select_plan(id) {
    for (let selected of this.selectedPlan) {
      if (selected.id == id) {
        selected.planActive = true;
        this.plan_code = selected.id;
      }
      else {
        selected.planActive = false;
      }
    }
  }
  save_plan() {
    for (let selected of this.selectedPlan) {
      if (selected.planActive) {
        this.plan_detail_completed = '100';
        this.update_property('plan_code', selected.id);
        this.update_property('plan_detail_completed', '100');

      }
    }
  }

  get_field_value() {
    var post_data = {
      "user_type": 'UT01',
      "property_usage": "PU01",
      "property_action": 'PA01',
      "plan_code": 'basic',
      "plan_detail_completed": '0',
      "media_detail_completed": '0'
    }
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'get_property_formdata');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        error => console.log(error),
        () => {
          this.user_type = response['user_type'];
          this.property_usage = response['property_usage'];
          this.property_action = response['property_action'];
          this.plan_code = response['plan_code'];
          this.plan_detail_completed = response['plan_detail_completed'];
          this.media_detail_completed = response['media_detail_completed'];
          this.update_point();
          console.log(this.user_type);
        })

  }

  calculate_price_sqft(){
    let price = parseInt(this.pricedetails.get('price_expected').value)/parseInt(this.propertydetails.get('super_area').value);
    if(!isNaN(price)){
      this.pricedetails.get('price_per_sqft').setValue(price);
    }
    else{
      this.pricedetails.get('price_per_sqft').setValue('');
    }
  }
  publish_property() {
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'publish_property');

    this.refozoDBAPIService.post(query_params, {})
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        err => console.error('Observer got an error: ' + err),
        () => {
          this.router.navigateByUrl('admin/my-listing')
        }
      )
  }
}



