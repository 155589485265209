<div class="resources row py-5">
    <div class="col-xl-11 mx-auto">
        <h5 class="text-info text-uppercase fw-400 text-center mb-3">
            resources at your <span class="fw-700">reach</span>
        </h5>
        <div class="row d-md-flex">
            <div class="col-md-4 mb-3 mb-md-0">
                <div class="card shadow-sm h-100 position-relative pb-5">
                    <div class="card-body">
                        <h6 class="fs-16 text-success fw-600 mb-3">Tips & Advice</h6>
                        <div class="card-thumbnail mb-3">
                            <div class="item-img">
                                <img src="assets/images/tips.jpg" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card-detail">
                            <h6 class="fs-12 fw-700">First-Time Homebuyer's Guide</h6>
                            <p>Everything you need to know to make that big purchase easier</p>
                            <p class="text-gray mb-0">By Arun Gupta</p>
                        </div>
                        <div class="reachMoreButton">
                            <a href="" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                <i class="fas fa-ellipsis-h"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
                <div class="card shadow-sm h-100 position-relative pb-5">
                    <div class="card-body">
                        <h6 class="fs-16 text-warning fw-600 mb-3">Real Estate News</h6>
                        <div class="card-thumbnail mb-3">
                            <div class="item-img">
                                <img src="assets/images/real-estate.jpg" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card-detail">
                            <h6 class="fs-12 fw-700">Reach-X was recently in news for having closed over a thousand home deals amidst lockdown woes.</h6>
                        </div>
                        <div class="reachMoreButton">
                            <a href="" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                <i class="fas fa-ellipsis-h"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card shadow-sm h-100 position-relative pb-5">
                    <div class="card-body">
                        <h6 class="fs-16 text-dark fw-600 mb-3">Blogs & Articles</h6>
                        <div class="card-thumbnail mb-3">
                            <div class="item-img">
                                <img src="assets/images/blog.jpg" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="card-detail">
                            <h6 class="fs-12 fw-700">Reach-X Convertibles Offer Investment Liberty Despite Odds</h6>
                            <p class="text-gray mb-0">June 06, 2020 by Gagan</p>
                        </div>
                        <div class="reachMoreButton">
                            <a href="" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                <i class="fas fa-ellipsis-h"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>