<div class="serviceReach bg-white position-relative py-5 row">
    <div class="col-12">
        <h5 class="text-info fw-400 text-uppercase text-center mb-4 mb-md-3">
            Services at your <span class="fw-700">Reach</span>
        </h5>
    </div>
    <div class="col-xl-11 mx-auto">
        <owl-carousel-o [options]="reachXServicesImg">
            <ng-template carouselSlide *ngFor = "let reachXService of reachXServices">
                <div class="slide py-4">
                    <div class="card position-relative shadow">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <a href="">
                                    <img src="{{reachXService.serviceImg}}" alt="">
                                </a>
                                <div class="serviceName text-center fw-700 py-1">
                                    <a href="">
                                        {{reachXService.serviceName}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
