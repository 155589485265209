import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'home-property-type',
  templateUrl: './property-type.component.html',
  styleUrls: ['./property-type.component.css']
})
export class PropertyTypeComponent implements OnInit{

  tabLoaded: boolean = false;
  
  activeTab($event){
    this.tabLoaded = true;
  }
  


  constructor() { }

  ngOnInit(): void {
    
  }

  
}
