import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { reachXservices } from 'src/app/appModals/servicesReach.modals';

@Component({
  selector: 'home-services-reach',
  templateUrl: './services-reach.component.html',
  styleUrls: ['./services-reach.component.css']
})
export class ServicesReachComponent implements OnInit {
  reachXServicesImg: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [''],
    margin: 30,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      738: {
        items: 3
      },
      850: {
        items: 4
      }
    },
    nav: true
  }
  reachXServices = [];

  constructor(private reachXService : reachXservices) { }

  ngOnInit(): void {
    this.reachXServices = this.reachXService.services;
  }

}
