<nav class="reachPropertiesMenu nav-scroll">
    <!-- <ul class="nav nav-tabs" id="nav-featuredDevelopers-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" id="featured-nav-all-tab" data-toggle="tab" href="#featured-nav-all" role="tab" aria-controls="featured-nav-all" aria-selected="true">
                All
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="featured-nav-office-tab" data-toggle="tab" href="#featured-nav-office" role="tab" aria-controls="featured-nav-office" aria-selected="false">
                Office
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="featured-nav-apartment-tab" data-toggle="tab" href="#featured-nav-apartment" role="tab" aria-controls="featured-nav-apartment" aria-selected="false">
                Apartment
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="featured-nav-plot-tab" data-toggle="tab" href="#featured-nav-plot" role="tab" aria-controls="featured-nav-plot" aria-selected="false">
                Plot
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="featured-nav-retail-tab" data-toggle="tab" href="#featured-nav-retail" role="tab" aria-controls="featured-nav-retail" aria-selected="false">
                Retail
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="featured-nav-industrial-tab" data-toggle="tab" href="#featured-nav-industrial" role="tab" aria-controls="featured-nav-industrial" aria-selected="false">
                Industrial
            </a>
        </li>
        
    </ul> -->
</nav>
<div class="tab-content" id="nav-featuredDevelopers-item">
    <div class="coming-soon text-center">
        <h1>Coming Soon...</h1>
    </div>
    <!-- <div class="tab-pane fade show active" id="featured-nav-all" role="tabpanel" aria-labelledby="featured-nav-all-tab">
        <owl-carousel-o [options]="featuredDevelopers">
            <ng-template carouselSlide *ngFor="let allDevelopers of featuredAllDevelopers">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{allDevelopers.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <img src="assets/images/icons/share.svg" alt="">
                                </a>
                                <a href="" class="d-inline-block">
                                    <img src="assets/images/icons/heart.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{allDevelopers.propertyId}}" class="text-info">{{allDevelopers.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{allDevelopers.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{allDevelopers.propertyPrice[0].min}} to {{allDevelopers.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{allDevelopers.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{allDevelopers.propertySize[0].min}} to {{allDevelopers.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{allDevelopers.propertyId}}"  class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="featured-nav-office" role="tabpanel" aria-labelledby="featured-nav-office-tab">
        <owl-carousel-o [options]="featuredDevelopers">
            <ng-template carouselSlide *ngFor="let officeDevelopers of featuredOfficeDevelopers">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{officeDevelopers.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <img src="assets/images/icons/share.svg" alt="">
                                </a>
                                <a href="" class="d-inline-block">
                                    <img src="assets/images/icons/heart.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{officeDevelopers.propertyId}}" class="text-info">{{officeDevelopers.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{officeDevelopers.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{officeDevelopers.propertyPrice[0].min}} to {{officeDevelopers.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{officeDevelopers.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{officeDevelopers.propertySize[0].min}} to {{officeDevelopers.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{officeDevelopers.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="featured-nav-apartment" role="tabpanel" aria-labelledby="featured-nav-apartment-tab">
        <owl-carousel-o [options]="featuredDevelopers">
            <ng-template carouselSlide *ngFor="let apartmentDevelopers of featuredApartmentDevelopers">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{apartmentDevelopers.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <img src="assets/images/icons/share.svg" alt="">
                                </a>
                                <a href="" class="d-inline-block">
                                    <img src="assets/images/icons/heart.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{apartmentDevelopers.propertyId}}" class="text-info">{{apartmentDevelopers.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{apartmentDevelopers.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{apartmentDevelopers.propertyPrice[0].min}} to {{apartmentDevelopers.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{apartmentDevelopers.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{apartmentDevelopers.propertySize[0].min}} to {{apartmentDevelopers.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{apartmentDevelopers.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="featured-nav-plot" role="tabpanel" aria-labelledby="featured-nav-plot-tab">
        <owl-carousel-o [options]="featuredDevelopers">
            <ng-template carouselSlide *ngFor="let plotDevelopers of featuredPlotDevelopers">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{plotDevelopers.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <img src="assets/images/icons/share.svg" alt="">
                                </a>
                                <a href="" class="d-inline-block">
                                    <img src="assets/images/icons/heart.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{plotDevelopers.propertyId}}" class="text-info">{{plotDevelopers.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{plotDevelopers.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{plotDevelopers.propertyPrice[0].min}} to {{plotDevelopers.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{plotDevelopers.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{plotDevelopers.propertySize[0].min}} to {{plotDevelopers.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{plotDevelopers.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="featured-nav-retail" role="tabpanel" aria-labelledby="featured-nav-retail-tab">
        <owl-carousel-o [options]="featuredDevelopers">
            <ng-template carouselSlide *ngFor="let rentalDevelopers of featuredRentalDevelopers">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{rentalDevelopers.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <img src="assets/images/icons/share.svg" alt="">
                                </a>
                                <a href="" class="d-inline-block">
                                    <img src="assets/images/icons/heart.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{rentalDevelopers.propertyId}}" class="text-info">{{rentalDevelopers.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{rentalDevelopers.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{rentalDevelopers.propertyPrice[0].min}} to {{rentalDevelopers.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{rentalDevelopers.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{rentalDevelopers.propertySize[0].min}} to {{rentalDevelopers.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton" tooltip="Find More" placement="top">
                                <a routerLink="{{rentalDevelopers.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="tab-pane fade" id="featured-nav-industrial" role="tabpanel" aria-labelledby="featured-nav-industrial-tab">
        <owl-carousel-o [options]="featuredDevelopers">
            <ng-template carouselSlide *ngFor="let industrialDevelopers of featuredIndustrialDevelopers">
                <div class="slide">
                    <div class="card position-relative pb-5">
                        <div class="card-thumbnail">
                            <div class="item-img">
                                <img src="{{industrialDevelopers.propertyThumbnail}}">
                            </div>
                            <div class="propertyOptions">
                                <a href="" class="d-inline-block mr-2">
                                    <img src="assets/images/icons/share.svg" alt="">
                                </a>
                                <a href="" class="d-inline-block">
                                    <img src="assets/images/icons/heart.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="card-body">
                            <h6 class="text-info fs-14 fw-700 mb-1">
                                <a routerLink="{{industrialDevelopers.propertyId}}" class="text-info">{{industrialDevelopers.propertyName}}</a>
                            </h6>
                            <small class="fs-12">{{industrialDevelopers.propertyPlace}}</small>
                            <div class="mt-3 form-row">
                                <div class="col-3 fw-600">Price</div>
                                <div class="col">{{industrialDevelopers.propertyPrice[0].min}} to {{industrialDevelopers.propertyPrice[0].max}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Unit</div>
                                <div class="col">{{industrialDevelopers.propertyUnit}}</div>
                            </div>
                            <div class="mt-1 form-row">
                                <div class="col-3 fw-600">Size</div>
                                <div class="col">{{industrialDevelopers.propertySize[0].min}} to {{industrialDevelopers.propertySize[0].max}}</div>
                            </div>
                            <div class="reachMoreButton"  tooltip="Find More" placement="top">
                                <a routerLink="{{industrialDevelopers.propertyId}}" class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div> -->
</div>


