import { Component, OnInit, Input, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() logo = '../assets/images/logo.svg';

  @Input() headerCollapse : boolean = false;

  // conditional header
  sidebarActive : boolean = false;
  admin : boolean = false;

  constructor(
    private router : Router, 
    private _sidebarActive : SidebarService, 
    private renderer : Renderer2,
    private _headerService : HeaderService
    ) {
    this._sidebarActive.sidebarActive.subscribe(res => {
      this.sidebarActive = res;
    });
    
    this._headerService.admin.subscribe(res => {
      this.admin = res;
    });
   }

  ngOnInit(): void {

  }
  gotoDashboard(){
    this.router.navigateByUrl('/my-account')
  }
  
  sidebar(){
    this._headerService.headerCollapse = !this._headerService.headerCollapse;
    this._headerService.toggleClass();
  }

  ngOnDestroy(){
    
  }

}
