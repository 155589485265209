<div class="expandReach row text-dark-grey">
    <div class="col-xl-11 mx-auto">
        <div class="row">
            <div class="col-md-12 col-lg-9 align-self-center py-5">
                <h5 class="text-uppercase fw-400">
                    Expand your <span class="fw-700">reach</span>
                </h5>
                <p class="fs-16">Join a new era to buy and sell real estate.</p>
                <p class="mb-4">Want to be a property Broker and be part of an industry with unlimited growth opportunity? Join Reach-X. Reach-X "REforALL" platform gives you instant access to the tools that enable you to manage your real estate transactions with ease. Begin your journey today with a state-of-the art Real Estate education and certification program to learn more about the business and get access to the power of Reach-X collaboration, lead management and service management marketplace. Let's get started!</p>
                <div class="d-flex">
                    <a data-toggle="modal" data-target="#signup" class="btn btn-success btn-lg fs-12 fw-500 text-white">
                        Join In
                    </a>
                    <a href="" class="btn btn-dark btn-lg fs-12 fw-500 text-white ml-3">
                        Learn More
                    </a>
                </div>
            </div>
            <div class="col-md-5 d-md-none d-lg-block col-lg-3 col-9 mx-auto mx-m-0 text-md-right align-self-end pt-4 text-center">
                <img src="assets/images/expand-reach-mobile.png" class="img-fluid" alt="">
            </div>
        </div>

    </div>
</div>
