<!-- HEADER BEGIN HERE -->
<div class="header">
  <app-header></app-header>
</div>
<!-- HEADER END HERE -->
<!-- MAIN OUTLET BEGIN HERE -->

<div class="main-outlet bg-light" [ngClass] = "sidebarActive ? 'push-container' :''">
  <div class="sidebar" *ngIf="sidebarActive">
    <app-sidebar></app-sidebar>
  </div>
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
<!-- MAIN OUTLET END HERE -->
<!-- FOOTER BEGIN HERE -->
<div class="footer pt-5" [ngClass] = "sidebarActive ? 'pushFooter' : ''">
  <app-footer></app-footer>
</div>
<!-- FOOTER END HERE -->

<!-- ASK REALZO BEGIN HERE -->
<div class="chatBot">
  <button type="button" class="close pull-right" aria-label="Close" onclick="$('.chatBot').removeClass('active');">
    <span aria-hidden="true">
      <i class="fas fa-times"></i>
    </span>
  </button>
  <iframe src="https://chatbot.refozo.com/" class="chatbot_iframe"></iframe>
</div>
<!-- ASK REALZO END HERE -->