import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { refozoDBAPIService } from '../../../services/refozoDBAPI/refozo-db-api.service';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'home-active-listing',
  templateUrl: './active-listing.component.html',
  styleUrls: ['./active-listing.component.css']
})
export class ActiveListingComponent implements OnInit {
  selectedcity = "";

  cities: {};

  getcity(): void {
    let query_params = new HttpParams();

    query_params = query_params.append('api', 'get_city_api');
    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.cities = refozoDBAPIService);
  }

  p_type = "";
  property_type = [
    { id: "", name: "Property" },
    { id: "New", name: "New" },
    { id: "Freehold", name: "Freehold" },
    { id: "Resale", name: "Resale" },
    { id: "Rent", name: "Rent" }
  ];

  usage = "";
  property_usage = [
    { id: "", name: "Type" },
    { id: "Residential", name: "Residential" },
    { id: "Commerical", name: "Commerical" }
  ];

  search_text = "";
  constructor(private router: Router, private modalService: BsModalService, private refozoDBAPIService: refozoDBAPIService) { }

  ngOnInit(): void {
    this.getcity();
  }

  search(property_type: string, property_usage: string, search_city: string, search_text: string) {
    let navigate: NavigationExtras = {
      queryParams:
      {
        "page": 1,
        "property_type": property_type,
        "property_usage": property_usage,
        "search_city": search_city,
        "search_value": search_text
      },
      skipLocationChange: true
    };
    this.router.navigate(['/search-list'], navigate)
  }

  modalRef: BsModalRef;
  config = {
    class: 'chatbot_modal'
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  set_features(id: number, attr: string) {
    if (id == 1) {
      let p_type_btn = <HTMLElement>document.querySelector("#property_type");
      if(attr == 'Property'){
        this.p_type = "";
      }
      else{
        this.p_type = attr;
      }
      p_type_btn.innerHTML = attr;
      return false;
    }
    if (id == 2) {
      let property_usage_btn = <HTMLElement>document.querySelector("#property_usage");
      property_usage_btn.innerHTML = attr;
      if(attr == 'Type'){
        this.usage = "";
      }
      else{
        this.usage = attr;
      }
      return false;
    }
    if (id == 3) {
      let search_city_btn = <HTMLElement>document.querySelector("#search_city");
      search_city_btn.innerHTML = attr;
      if(attr == 'City'){
        this.selectedcity = "";
      }
      else{
        this.selectedcity = attr;
      }
      return false;
    }
  }
}
