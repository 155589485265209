import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
declare var $: any;

@Component({
  selector: 'app-search-list-map',
  templateUrl: './search-list-map.component.html',
  styleUrls: ['./search-list-map.component.css']
})
export class SearchListMapComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  @Input() property_type: String;
  @Input() property_usage: String;
  @Input() search_city: String;
  @Input() search_value: String;
  @Input() page: Number;
  @Input() propertyList: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }
  currentMarkerTitle = "";
  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 8,
  };
  markers = [];
  infoContent = '';

  map_center_loc = {};

  @Output() map_center = new EventEmitter();

  ngOnChanges() {
    this.init_marker();
  }

  ngOnInit() {
    // navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: 28.7041,
        lng: 77.1025,
      }
    // })
  }

  init_marker() {
    this.markers = [];
    if (this.propertyList) {
      // navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: parseFloat(this.propertyList.latitude),
          lng: parseFloat(this.propertyList.longitude),
        }
      // })
      for (const r of this.propertyList.record) {
        var add = "";
        if (r.address_line1.length > 27) {
          add = "..."
        }

        var content_html = `<center><div class='property-item rounded-left mr-3'>
      <div class='item-img rounded-left '><a href='/search-list/airia-mall' target='_blank'>
      <img width=150px height=150px src='`+ r.media_thumbnails.split(',')[0] + `' 
      onerror=\"this.onerror=null;this.src='assets/images/property/alt_property.jpg';\"  class='rounded-left img-fluid' alt='...'>
      </a></div></div><br><b>`+ r.product_title + `</b><br>` + r.address_line1.substring(0, 27) + add + `<br>
      ` + r.property_price_min + ` ` + r.property_price_unit + ` to ` + r.property_price_max + ` ` + r.property_price_unit + `
      <br>` + r.property_size_min + ` ` + r.property_size_unit + ` to ` + r.property_size_max + ` ` + r.property_size_unit + `
      </center>'`;

        this.markers.push({
          position: {
            lat: parseFloat(r.latitude),
            lng: parseFloat(r.longitude)
          },
          label: {
            color: "red",
          },
          info: content_html,
          title: r.product_title,
          id: "m" + r.id,
          options: {
          }
        });
      }
    }
  }

  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--
  }

  click(event) {
    // $("#m1").click();
    console.log(event);
  }

  logCenter() {
    this.map_center_loc = JSON.parse(JSON.stringify(this.map.getCenter()));
    this.map_center.emit(this.map_center_loc);
  }

  openInfo(marker: MapMarker, content) {
    this.infoContent = content;
    this.info.open(marker);
  }
}
