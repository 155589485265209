import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.css']
})
export class SellComponent implements OnInit {

  user_number = "";
  otp1 = "";
  otp2 = "";
  otp3 = "";
  otp4 = "";
  user_password = "";
  send_otp_response: any;
  validate_otp_response: any;

  logInDetail = false;
  otpSec = true;
  passwordSec = true;

  constructor(private router: Router, private refozoDBAPIService: refozoDBAPIService, private cookieService: CookieService) { }

  ngOnInit(): void {
  }

  postProperty(login_type) {
    if (login_type == 'otp') {
      console.log(this.otp1, this.otp2, this.otp3, this.otp4);
      var post_data = {
        'mobile': this.user_number,
        'otp': this.otp1+this.otp2+this.otp3+this.otp4
      };
      let query_params = new HttpParams();
      query_params = query_params.append('api', 'validate_mobile_otp');
  
      this.refozoDBAPIService.post(query_params, post_data)
        .subscribe(refozoDBAPIService => this.validate_otp_response = refozoDBAPIService,
          err => console.error('Observer got an error: ' + err),
          () => {
            if (this.validate_otp_response.status_code == 'S') {
              this.cookieService.set( '_session', this.validate_otp_response.session_key ); // To Set Cookie
              console.log(this.cookieService.get('_session')); // To Get Cookie
              if(this.validate_otp_response.new_user){
              this.router.navigateByUrl('post-property')
            }
            else{
              this.router.navigateByUrl('admin/my-listing')
            }
            }
          })
    }
    else {
      console.log(this.user_password)
    }

    // this.router.navigateByUrl('post-property')
  }

  otpLogin() {
    // api call to send OTP
    var post_data = {
      'mobile': this.user_number
    };
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'send_mobile_otp');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => this.send_otp_response = refozoDBAPIService,
        err => console.error('Observer got an error: ' + err),
        () => {
          if (this.send_otp_response.status_code == 'S') {
            this.logInDetail = true;
            this.otpSec = false;
            this.passwordSec = true;
          }
        })
  }

  passwordLogin() {
    this.passwordSec = false;
    this.logInDetail = true;
    this.otpSec = true;
  }
  changeMobNo() {
    this.user_password = this.otp1 = this.otp2 = this.otp3 = this.otp4 = ''
    this.passwordSec = true;
    this.logInDetail = false;
    this.otpSec = true;
  }

}
