import { Injectable, Renderer2, RendererFactory2, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  postPropertyHeader = new BehaviorSubject(false);
  headerCollapse = false;
  admin = new BehaviorSubject(false);

  private renderer : Renderer2;
  constructor(rendererFectory : RendererFactory2) {
    this.renderer = rendererFectory.createRenderer(null, null)
   }

  toggleClass(){
    this.renderer[this.headerCollapse ? 'addClass' : 'removeClass'](document.body, 'sidebarCollapsed')
  }
  ngOnDestroy(): void {
    
    
  }

}
