import { Component, OnInit, Input } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  active_menu = 'location';

  total_point = 800;
  current_point = 300;
  complete_percent = (this.current_point/this.total_point)*100;

  logo = '../assets/images/logo.svg';

  loggedIn : boolean = true;
  adminMenu : boolean = false;

  
  constructor(private _headerService : HeaderService, private _sidebarService : SidebarService) { 
    
  }

  ngOnInit(): void {
    this._sidebarService.admin.next(true);
    this._sidebarService.adminMenu.subscribe(res => {
      this.adminMenu = res;
    });
  }

  update_active_menu(val){
    this.active_menu = val;
  }
}
