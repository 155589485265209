import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-search-list-record',
  templateUrl: './search-list-record.component.html',
  styleUrls: ['./search-list-record.component.css']
})
export class SearchListRecordComponent implements OnInit {
  @Input() property_type: String;
  @Input() property_usage: String;
  @Input() search_city: String;
  @Input() search_value: String;
  @Input() page: Number;
  @Input() propertyList: any;
  @Output() page_changed = new EventEmitter();
  @Output() hovered_property_event = new EventEmitter();


  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      this.property_type = params['property_type'];
      this.property_usage = params['property_usage'];
      this.search_city = params['search_city'];
      this.search_value = params['search_value'];
    });
  }

  paginator(page, pos) {
    
    page = Number(page) + Number(pos);
    if(this.propertyList.page == page){
      return false;
    }
    this.page = page;
    this.page_changed.emit(this.page);
    const element = window;
    element.scrollTo(0, 0);
    return false;
  }

  detail_page() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/search-list/airia-mall'])
    );
    window.open(url, '_blank');
  }

  change_hovered_property(id){
    this.hovered_property_event.emit(id);
  }
}
