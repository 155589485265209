<!--<iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448183.73907005717!2d76.81307299667618!3d28.646677259922765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1592501607959!5m2!1sen!2sin"
    width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
    tabindex="0"></iframe>  -->


<div class="map_div_before_scroll" id="google_div">
    <button class="btn btn-white rounded-0 border-left" style="z-index: 1500;position: absolute;left: 180px; top: 10px; height: 40px;" (click)="logCenter()" type="button">Search
        Map</button>
    <google-map height="100%" width="100%" [zoom]="zoom" [center]="center" [options]="options"
        (mapClick)="click($event)">
        <map-marker #somemarker="mapMarker" *ngFor="let marker of markers" [position]="marker.position"
            [options]="marker.options" (mapClick)="openInfo(somemarker, marker.info)" [id] = "marker.id" (click)="openInfo(somemarker, marker.info)">
        </map-marker>

        <map-info-window [innerHTML]="infoContent"></map-info-window>
    </google-map>
</div>