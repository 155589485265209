<div class="trendingProjects properties py-5 row">
    <div class="col-xl-11 mx-auto">
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 mobileTrending">
                <div class="card h-100">
                    <div class="trending h-100 rounded p-md-3">
                        <h5 class="text-info text-uppercase mt-md-4">
                            Trending <span class="fw-700">now</span>
                        </h5>
                        <p>Top rated and hottest properties in your area</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-6">
                <owl-carousel-o [options]="trendingProjects">
                    <ng-template carouselSlide *ngFor = "let trendingProperty of trendingProperties">
                        <div class="slide">
                            <div class="card position-relative pb-5">
                                <div class="card-thumbnail">
                                    <div class="item-img">
                                        <img src="{{trendingProperty.propertyThumbnail}}">
                                    </div>
                                    <div class="propertyOptions">
                                        <a href="" class="d-inline-block mr-2">
                                            <i class="fas fa-share-alt fs-18"></i>
                                        </a>
                                        <a href="" class="d-inline-block">
                                            <i class="fas fa-heart fs-18"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <h6 class="text-info fs-14 fw-700 mb-1">
                                        <a routerLink="{{trendingProperty.propertyId}}" class="text-info">{{trendingProperty.propertyName}}</a>
                                    </h6>
                                    <small class="fs-12">{{trendingProperty.propertyPlace}}</small>
                                    <div class="mt-3 form-row">
                                        <div class="col-3 fw-600">Price</div>
                                        <div class="col text-truncate">{{trendingProperty.propertyPrice[0].min}} to {{trendingProperty.propertyPrice[0].max}}</div>
                                    </div>
                                    <div class="mt-2 form-row">
                                        <div class="col-3 fw-600">Unit</div>
                                        <div class="col text-truncate">{{trendingProperty.propertyUnit}}</div>
                                    </div>
                                    <div class="mt-2 form-row">
                                        <div class="col-3 fw-600">Size</div>
                                        <div class="col text-truncate">{{trendingProperty.propertySize[0].min}} to {{trendingProperty.propertySize[0].max}}</div>
                                    </div>
                                    <div class="reachMoreButton" tooltip="Find More" placement="top">
                                        <a routerLink="{{trendingProperty.propertyId}}"  class="btn btn-outline-light btn-sm py-1 px-3 lh-normal">
                                            <i class="fas fa-ellipsis-h"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>    
</div>
