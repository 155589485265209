<div class="mt-5">
    <!-- REACH-X PLATFORM HEADING BEGIN HERE -->
    <div class="row">
        <div class="container py-5 text-center">
            <h3 class="text-info fw-700 text-uppercase mb-0">reach-x platform</h3>
            <small class="fs-20 text-gray">The Reach-X platform empowers brokers, sellers and buyers with data-driven technology and comprehensive tools to expand market visibility and decrease time to close.</small>
        </div>
    </div>
    <!-- REACH-X PLATFORM HEADING END HERE -->
    <!-- DATA DRIVEN MARKETPLACE SECTION BEGIN HERE -->
    <div class="row bg-light-green">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">data driven marketplace</h6>
                    Reach-X drastically reduces the pain points in the traditional real estate sales process to ensure a streamlined, seamless close.
                </div>
                <div class="col-md-2 text-md-right text-center mt-4 mt-md-0">
                    <img src="assets/images/icons/data-driven-marketplace.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- DATA DRIVEN MARKETPLACE SECTION END HERE -->
    <!-- EXPAND YOUR REACH SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/expand-reach.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">expand your reach</h6>
                    From identifying untapped investment opportunities to precision-matching properties to the right buyers, Reach-X technology and platform can completely transform an age-old real estate transaction process. Reach-X is focused on driving new innovations that can help brokers, buyers and sellers save time and money.
                </div>
            </div>
        </div>
    </div>
    <!-- EXPAND YOUR REACH TECHNOLOGY SECTION END HERE -->
    <!-- AUCTION FEATURES SECTION BEGIN HERE -->
    <div class="row bg-light-green mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center">
                    <h6 class="text-success text-uppercase fs-14 fw-600">get ahead with live bid and auction features</h6>
                    <p class="mb-1">Reach-X provides industry’s first LIVE BID and AUCTION feature for commercial real estate</p>
                    <ul class="bullet fs-12 mb-0 list-unstyled buyers">
                        <li>Expedite your sale and hit your desired close date with a live commercial real estate auction</li>
                        <li>Get certainty of close with auction market dynamics that combine indicative bidding, buyer shortlisting and a final round auction.</li>
                        <li>Accelerate the traditional transaction process and watch in real-time as offers come in from pre-approved buyers.</li>
                    </ul>
                </div>
                <div class="col-md-2 text-md-right text-center mt-4 mt-md-0">
                    <img src="assets/images/icons/auction-features.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- AUCTION FEATURES SECTION END HERE -->
    <!-- ENJOY GREATER EFFECIENCY SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/enjoy-greater-effeciency.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">enjoy greater effeciency</h6>
                    Buyers get properties precision-matched to their purchasing interests. Brokers and sellers decrease time from list to close by more than 50%. No need to do in-person transactions – marketplace handles all transaction aspects online
                </div>
            </div>
        </div>
    </div>
    <!-- ENJOY GREATER EFFECIENCY SECTION END HERE -->
    <!-- TRANSACT WITH CERTAINITY SECTION BEGIN HERE -->
    <div class="row bg-light-green mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">transact with certainity</h6>
                    REACH-X is more than a pure technology platform. REACH-X is backed by hundreds of solid real estate professionals from REACH group that review and vet all aspects of a transaction. The combination of human experience and platform’s artificial intelligence separates serious buyers and sellers from the rest. 
                </div>
                <div class="col-md-2 text-md-right text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/tranasact-certainity.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- TRANSACT WITH CERTAINITY SECTION END HERE -->
    <!-- CREATE AND VIEW STATE SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/visual-tour.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">create and view state of the art visual tours</h6>
                    <p>Reach-X provides sellers the technology and skill-set to create virtual reality, interactive, 360 degree and Street Views of properties to bring virtual spaces in the real world. </p>
                    <p class="mb-0">As a buyer - along with getting access to all the needed documentation and details on the property, having details of location, area maps, surroundings, demographics info, zoning info, market potential and nearby amenities etc., and the state of the art virtual location tours and street views will help buyers make decisions without ever visiting the property and location in person.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- CREATE AND VIEW STATE SECTION END HERE -->
    <!-- GET COMPETITIVE ADVANTAGE SECTION BEGIN HERE -->
    <div class="row bg-light-green mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">get competitive advantage</h6>
                    <p>Track buyers and commercial property marketing activity instantly using streamlined tools that help ensure certainty of close. Using REACH-X comprehensive dashboard, sellers and brokers can monitor and manage their assets in real-time. </p>
                    <p class="mb-0">The Marketplace provides full visibility into potential buyer’s activity on all your properties in one place. The CRM capabilities built as part of REACH-X platform enable Sellers and Brokers to view and gauge the impact of marketing activities for their property, including targeted email, digital, print and call campaigns.</p>
                </div>
                <div class="col-md-2 text-md-right text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/competitive-advantage.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- GET COMPETITIVE ADVANTAGE SECTION END HERE -->
    <!-- TRACK TRENDS SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/track-trend.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">track trends</h6>
                    Sellers and Brokers can analyze where individual interested buyers are located by using REACH-X mapping tool. They can monitor the number of buyers who have viewed the listing, signed a confidentiality agreement, downloaded the detailed documentation package and/or have shown interest by registering on the platform for an auction or offer.
                </div>
            </div>
        </div>
    </div>
    <!-- TRACK TRENDS SECTION END HERE -->
    <!-- NEGOTIATE WITH EASE SECTION BEGIN HERE -->
    <div class="row bg-light-green mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">negotiate with ease</h6>
                    Reach-X provides integrated capabilities for various entities involved in a transaction to interact within the platform through messaging, calls, video chats and file transfers. The platform maintains a comprehensive audit trail and maintains a log of all activities on the platform to streamline the virtual meetings and negotiation process. 
                </div>
                <div class="col-md-2 text-md-right text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/negotiate-ease.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- NEGOTIATE WITH EASE SECTION END HERE -->
    <!-- ANALYSE AND EVALUATE SECTION BEGIN HERE -->
    <div class="row bg-light-orange mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-2 text-md-left text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/analyse-evaluation.svg" alt="">
                </div>
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">analyse and evaluate</h6>
                    REACH-X property value calculator engines gather data from various sources and along with the inputs from REACH’s business experts, the platform provides buyers the tools to analyze, evaluate and predict the value of their potential purchase options based on their unique requirements. Buyers use multiple tools ranging from Loan and EMI calculators, valuation services to potential ROI calculators. 
                </div>
            </div>
        </div>
    </div>
    <!-- ANALYSE AND EVALUATE SECTION END HERE -->
    <!-- INTEGRATED ECOSYSTEM SECTION BEGIN HERE -->
    <div class="row bg-light-green mt-3">
        <div class="container py-3">
            <div class="row align-items-center">
                <div class="col-md-10 text-md-left text-center fs-14">
                    <h6 class="text-success text-uppercase fs-12 fw-600">get access to an integrated ecosystem</h6>
                    <p>From simplifying processes by providing e-Signature capabilities to helping setup the office/residential space, the REACH-X platform has integrations with numerous industry leading service and solution providers that enables access to all entities within the real estate ecosystem. </p>
                    <p class="mb-0">The Pre Deal services include deed transfer service, GOV stamping, E-Signing of sale or lease, financial assistance and loan setup with banks, comprehensive property inspection and even Vaastu consultation services. The Post Deal services for buyer include design consultation, all aspects of property furnishing and setup, and all rental property management services. </p>
                </div>
                <div class="col-md-2 text-md-right text-center mb-4 mb-md-0">
                    <img src="assets/images/icons/integrated-ecosystem.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- INTEGRATED ECOSYSTEM SECTION END HERE -->
</div>