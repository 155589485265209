export class postPropertyTemplate {
    dataTemplate = {
        "layout_info": [
            {
                "type_code": "LI01",
                "type_title": "Study Room",
                "type_icon_path": "assets/images/icons/study-room.svg",
                "selected": false
            },
            {
                "type_code": "LI02",
                "type_title": "Media Room",
                "type_icon_path": "assets/images/icons/media-room.svg",
                "selected": false
            },
            {
                "type_code": "LI03",
                "type_title": "Pooja Room",
                "type_icon_path": "assets/images/icons/pooja-room.svg",
                "selected": false
            },
            {
                "type_code": "LI04",
                "type_title": "Store Room",
                "type_icon_path": "assets/images/icons/store-room.svg",
                "selected": false
            },
            {
                "type_code": "LI05",
                "type_title": "Servant Room",
                "type_icon_path": "assets/images/icons/servant-room.svg",
                "selected": false
            }
        ],
        "building_codes": {
            "PU01": [
                {
                    "type_code": "BT01",
                    "type_title": "Apartment",
                    "type_icon_path": "assets/images/icons/apartment.svg"
                },
                {
                    "type_code": "BT02",
                    "type_title": "Flat",
                    "type_icon_path": "assets/images/icons/flat.svg"
                },
                {
                    "type_code": "BT03",
                    "type_title": "Floor",
                    "type_icon_path": "assets/images/icons/floor.svg"
                },
                {
                    "type_code": "BT04",
                    "type_title": "Land",
                    "type_icon_path": "assets/images/icons/land.svg"
                },
                {
                    "type_code": "BT05",
                    "type_title": "Plot",
                    "type_icon_path": "assets/images/icons/plot.svg"
                },
                {
                    "type_code": "BT06",
                    "type_title": "House",
                    "type_icon_path": "assets/images/icons/home.svg"
                },
                {
                    "type_code": "BT07",
                    "type_title": "Villa",
                    "type_icon_path": "assets/images/icons/villa.svg"
                },
                {
                    "type_code": "BT08",
                    "type_title": "Others",
                    "type_icon_path": "PU01"
                }
            ],
            "PU02": [
                {
                    "type_code": "BT09",
                    "type_title": "Office",
                    "type_icon_path": "assets/images/icons/office.svg"
                },
                {
                    "type_code": "BT10",
                    "type_title": "Retail",
                    "type_icon_path": "assets/images/icons/retail.svg"
                },
                {
                    "type_code": "BT11",
                    "type_title": "Land",
                    "type_icon_path": "assets/images/icons/land.svg"
                },
                {
                    "type_code": "BT12",
                    "type_title": "Industry Factory",
                    "type_icon_path": "assets/images/icons/industry-factory.svg"
                },
                {
                    "type_code": "BT13",
                    "type_title": "Hotel",
                    "type_icon_path": "assets/images/icons/hotel.svg"
                },
                {
                    "type_code": "BT14",
                    "type_title": "Others",
                    "type_icon_path": "PU02"
                }
            ]
        },
        "subbuilding_codes": {
            "PU01": [
                {
                    "type_code": "BS01",
                    "type_title": "Residential Building"
                },
                {
                    "type_code": "BS02",
                    "type_title": "Independent Floor"
                },
                {
                    "type_code": "BS03",
                    "type_title": "Studio Apartment"
                },
                {
                    "type_code": "BS04",
                    "type_title": "Service Apartment"
                }
            ],
            "PU02": [
                {
                    "type_code": "BS05",
                    "type_title": "Commercial Space"
                },
                {
                    "type_code": "BS06",
                    "type_title": "Business Center"
                },
                {
                    "type_code": "BS07",
                    "type_title": "Sharing Space"
                }
            ]
        },
        "age_codes": [
            {
                "type_code": "PAG1",
                "type_title": "New"
            },
            {
                "type_code": "PAG2",
                "type_title": "Less than 5 Years"
            },
            {
                "type_code": "PAG3",
                "type_title": "6-10 Years"
            },
            {
                "type_code": "PAG4",
                "type_title": "10+ Years"
            }
        ],
        "facing_codes": [
            {
                "type_code": "PF01",
                "type_title": "East"
            },
            {
                "type_code": "PF02",
                "type_title": "West"
            },
            {
                "type_code": "PF03",
                "type_title": "North"
            },
            {
                "type_code": "PF04",
                "type_title": "South"
            },
            {
                "type_code": "PF05",
                "type_title": "North-East"
            },
            {
                "type_code": "PF06",
                "type_title": "North-West"
            },
            {
                "type_code": "PF07",
                "type_title": "South-East"
            },
            {
                "type_code": "PF08",
                "type_title": "South-West"
            }
        ],
        "pstatus_codes": [
            {
                "type_code": "PS01",
                "type_title": "Ready to Move"
            },
            {
                "type_code": "PS02",
                "type_title": "Early Stage Construction"
            },
            {
                "type_code": "PS03",
                "type_title": "Mid Stage Construction"
            },
            {
                "type_code": "PS04",
                "type_title": "Advanced Stage Construction"
            },
            {
                "type_code": "PS05",
                "type_title": "New Launch"
            },
            {
                "type_code": "PS06",
                "type_title": "Well Occupied Building"
            }
        ],
        "property_features": [
            {
                "type_code": "PZ01",
                "type_title": "Built-in Wardrobes",
                "type_icon_path": "assets/images/icons/wardrobe.svg",
                "selected": false
            },
            {
                "type_code": "PZ02",
                "type_title": "In-house Pool",
                "type_icon_path": "assets/images/icons/in-house-pool.svg",
                "selected": false
            },
            {
                "type_code": "PZ03",
                "type_title": "In-house Garden",
                "type_icon_path": "assets/images/icons/in-house-garden.svg",
                "selected": false
            },
            {
                "type_code": "PZ04",
                "type_title": "Elevator",
                "type_icon_path": "assets/images/icons/elevator.svg",
                "selected": false
            },
            {
                "type_code": "PZ05",
                "type_title": "Water Purifier",
                "type_icon_path": "assets/images/icons/water-purifier.svg",
                "selected": false
            },
            {
                "type_code": "PZ06",
                "type_title": "Piped Gas",
                "type_icon_path": "assets/images/icons/pipe-line.svg",
                "selected": false
            },
            {
                "type_code": "PZ07",
                "type_title": "Feng Shui/Vaastu Compliant",
                "type_icon_path": "assets/images/icons/fengshui.svg",
                "selected": false
            },
            {
                "type_code": "PZ08",
                "type_title": "Power Backup/Generator",
                "type_icon_path": "assets/images/icons/power-backup.svg",
                "selected": false
            },
            {
                "type_code": "PZ09",
                "type_title": "Gated Society",
                "type_icon_path": "assets/images/icons/gate.svg",
                "selected": false
            },
            {
                "type_code": "PZ10",
                "type_title": "Wheelchair Friendly",
                "type_icon_path": "assets/images/icons/wheelchair.svg",
                "selected": false
            }
        ],
        "sport_amenities": [
            {
                "type_code": "SA01",
                "type_title": "Gym",
                "type_icon_path": "assets/images/icons/gym.svg",
                "selected": false
            },
            {
                "type_code": "SA02",
                "type_title": "Swimming Pool",
                "type_icon_path": "assets/images/icons/in-house-pool.svg",
                "selected": false
            },
            {
                "type_code": "SA03",
                "type_title": "Kids Pool",
                "type_icon_path": "assets/images/icons/kids-pool.svg",
                "selected": false
            },
            {
                "type_code": "SA04",
                "type_title": "Indoor Pool",
                "type_icon_path": "assets/images/icons/indoor-pool.svg",
                "selected": false
            },
            {
                "type_code": "SA05",
                "type_title": "Kids Play Area",
                "type_icon_path": "assets/images/icons/kids-play-area.svg",
                "selected": false
            },
            {
                "type_code": "SA06",
                "type_title": "Tennis Court",
                "type_icon_path": "assets/images/icons/tennis-court.svg",
                "selected": false
            },
            {
                "type_code": "SA07",
                "type_title": "Table Tennis",
                "type_icon_path": "assets/images/icons/table-tennis.svg",
                "selected": false
            },
            {
                "type_code": "SA08",
                "type_title": "Badminton Court",
                "type_icon_path": "assets/images/icons/badminton-court.svg",
                "selected": false
            },
            {
                "type_code": "SA09",
                "type_title": "Jogging Track",
                "type_icon_path": "assets/images/icons/jogging-track.svg",
                "selected": false
            },
            {
                "type_code": "SA10",
                "type_title": "Bike Track",
                "type_icon_path": "assets/images/icons/bike-track.svg",
                "selected": false
            },
            {
                "type_code": "SA11",
                "type_title": "Yoga Center",
                "type_icon_path": "assets/images/icons/yoga-center.svg",
                "selected": false
            },
            {
                "type_code": "SA12",
                "type_title": "BasketBall Court",
                "type_icon_path": "assets/images/icons/basketball-court.svg",
                "selected": false
            },
            {
                "type_code": "SA13",
                "type_title": "VolleyBall Court",
                "type_icon_path": "assets/images/icons/volleyball-court.svg",
                "selected": false
            },
            {
                "type_code": "SA14",
                "type_title": "Skating Rink",
                "type_icon_path": "assets/images/icons/skating-rink.svg",
                "selected": false
            },
            {
                "type_code": "SA15",
                "type_title": "Squash Court",
                "type_icon_path": "assets/images/icons/squash-court.svg",
                "selected": false
            },
            {
                "type_code": "SA16",
                "type_title": "Snooker/Pool/Biliard Room",
                "type_icon_path": "assets/images/icons/snooker.svg",
                "selected": false
            },
            {
                "type_code": "SA17",
                "type_title": "Golf Course",
                "type_icon_path": "assets/images/icons/golf-course.svg",
                "selected": false
            },
            {
                "type_code": "SA18",
                "type_title": "Bowling",
                "type_icon_path": "assets/images/icons/bowling.svg",
                "selected": false
            },
            {
                "type_code": "SA19",
                "type_title": "Outdoor Trails",
                "type_icon_path": "assets/images/icons/outdoor-trail.svg",
                "selected": false
            },
            {
                "type_code": "SA20",
                "type_title": "Water Slides",
                "type_icon_path": "assets/images/icons/water-slide.svg",
                "selected": false
            }
        ],
        "convenience_amenities": [
            {
                "type_code": "CA01",
                "type_title": "Power Backup",
                "type_icon_path": "assets/images/icons/power-backup.svg",
                "selected": false
            },
            {
                "type_code": "CA02",
                "type_title": "Separate Service Lifts",
                "type_icon_path": "assets/images/icons/seprate-service-lift.svg",
                "selected": false
            },
            {
                "type_code": "CA03",
                "type_title": "Convenience Store",
                "type_icon_path": "assets/images/icons/convenience-store.svg",
                "selected": false
            },
            {
                "type_code": "CA04",
                "type_title": "Medical Facility",
                "type_icon_path": "assets/images/icons/medical-facility.svg",
                "selected": false
            },
            {
                "type_code": "CA05",
                "type_title": "Pre-School",
                "type_icon_path": "assets/images/icons/pre-school.svg",
                "selected": false
            },
            {
                "type_code": "CA06",
                "type_title": "Concierge",
                "type_icon_path": "assets/images/icons/concierge.svg",
                "selected": false
            },
            {
                "type_code": "CA07",
                "type_title": "ATM",
                "type_icon_path": "assets/images/icons/atm.svg",
                "selected": false
            },
            {
                "type_code": "CA08",
                "type_title": "Pet Area",
                "type_icon_path": "assets/images/icons/pet-area.svg",
                "selected": false
            },
            {
                "type_code": "CA09",
                "type_title": "Shuttle",
                "type_icon_path": "assets/images/icons/shuttle.svg",
                "selected": false
            },
            {
                "type_code": "CA10",
                "type_title": "Visitor Parking",
                "type_icon_path": "assets/images/icons/visitor-parking.svg",
                "selected": false
            }
        ],
        "safety_amenities": [
            {
                "type_code": "YA01",
                "type_title": "Gated Community",
                "type_icon_path": "assets/images/icons/gate.svg",
                "selected": false
            },
            {
                "type_code": "YA02",
                "type_title": "24x7 Security",
                "type_icon_path": "assets/images/icons/24-7-security.svg",
                "selected": false
            },
            {
                "type_code": "YA03",
                "type_title": "Intercom/Video Phone",
                "type_icon_path": "assets/images/icons/intercom.svg",
                "selected": false
            },
            {
                "type_code": "YA04",
                "type_title": "CCTV Video Surveillance",
                "type_icon_path": "assets/images/icons/video-surveillance.svg",
                "selected": false
            },
            {
                "type_code": "YA05",
                "type_title": "Fire Fighting System",
                "type_icon_path": "assets/images/icons/fire-extinguisher.svg",
                "selected": false
            }
        ],
        "leisure_amenities": [
            {
                "type_code": "LA01",
                "type_title": "Party Hall",
                "type_icon_path": "assets/images/icons/party-hall.svg",
                "selected": false
            },
            {
                "type_code": "LA02",
                "type_title": "ClubHouse",
                "type_icon_path": "assets/images/icons/club-house.svg",
                "selected": false
            },
            {
                "type_code": "LA03",
                "type_title": "Indoor Game Room",
                "type_icon_path": "assets/images/icons/indoor-game-room.svg",
                "selected": false
            },
            {
                "type_code": "LA04",
                "type_title": "Observation Deck",
                "type_icon_path": "assets/images/icons/observation-desk.svg",
                "selected": false
            },
            {
                "type_code": "LA05",
                "type_title": "Restaurant",
                "type_icon_path": "assets/images/icons/restaurant.svg",
                "selected": false
            },
            {
                "type_code": "LA06",
                "type_title": "Café",
                "type_icon_path": "assets/images/icons/cafe.svg",
                "selected": false
            },
            {
                "type_code": "LA07",
                "type_title": "Bar",
                "type_icon_path": "assets/images/icons/gin-tonic.svg",
                "selected": false
            },
            {
                "type_code": "LA08",
                "type_title": "Spa",
                "type_icon_path": "assets/images/icons/spa.svg",
                "selected": false
            },
            {
                "type_code": "LA09",
                "type_title": "Barbeque Area",
                "type_icon_path": "assets/images/icons/barbeque-area.svg",
                "selected": false
            }
        ],
        "environment_amenities": [
            {
                "type_code": "EA01",
                "type_title": "Park",
                "type_icon_path": "assets/images/icons/environment-park.svg",
                "selected": false
            },
            {
                "type_code": "EA02",
                "type_title": "Green Area",
                "type_icon_path": "assets/images/icons/green-area.svg",
                "selected": false
            },
            {
                "type_code": "EA03",
                "type_title": "Koi Pond",
                "type_icon_path": "assets/images/icons/koi-pond.svg",
                "selected": false
            },
            {
                "type_code": "EA04",
                "type_title": "Lake",
                "type_icon_path": "assets/images/icons/lake.svg",
                "selected": false
            },
            {
                "type_code": "EA05",
                "type_title": "Water fountains",
                "type_icon_path": "assets/images/icons/water-fountain.svg",
                "selected": false
            }
        ],
        "landmarks": [
            {
                "type_code": "LM01",
                "type_title": "Hospital"
            },
            {
                "type_code": "LM02",
                "type_title": "Mall"
            },
            {
                "type_code": "LM03",
                "type_title": "Shop/Store"
            },
            {
                "type_code": "LM04",
                "type_title": "Restaurant"
            },
            {
                "type_code": "LM05",
                "type_title": "Park"
            },
            {
                "type_code": "LM06",
                "type_title": "Main Road"
            },
            {
                "type_code": "LM07",
                "type_title": "Building"
            },
            {
                "type_code": "LM08",
                "type_title": "Bank"
            },
            {
                "type_code": "LM09",
                "type_title": "Office"
            },
            {
                "type_code": "LM10",
                "type_title": "Airport"
            },
            {
                "type_code": "LM11",
                "type_title": "Railway Station"
            },
            {
                "type_code": "LM12",
                "type_title": "Metro Station"
            },
            {
                "type_code": "LM13",
                "type_title": "Bus Stop"
            },
            {
                "type_code": "LM14",
                "type_title": "Other"
            }
        ]
    }
}