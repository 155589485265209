export class featuredDeveloper{
    propertyList : any = [
        {
            featruedDeveloperAll :  [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Retail Shops',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK, 4 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Retail Shops',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK, 4 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            featuredDeveloperOffice : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Office Space Floors',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            featuredDeveloperApartment : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK, 4 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : '2 BHK, 3 BHK',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            featuredDeveloperPlot : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Plot',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            featuredDeveloperRental : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Rental',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ],
            featuredDeveloperIndustrial : [
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'airia-mall',
                    propertyName : 'Airia Mall',
                    propertyPlace : 'Sector 68, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/airia-mall.jpg',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '12 Lacs',
                            max : '30 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'signature-global-park',
                    propertyName : 'Signature Global Park',
                    propertyPlace : 'Sohna Sector 36, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/signature-global-park.jpg',
                    propertySize : [
                        {
                            min : '750 Sq Ft',
                            max : '899 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '40 Lacs',
                            max : '80 Lacs'
                        }
                    ],
                    
                },
                {
                    propertyId : 'godrej-icon',
                    propertyName : 'Godrej Icon',
                    propertyPlace : 'Sector 88A, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/godrej-icon.jpg',
                    propertySize : [
                        {
                            min : '650 Sq Ft',
                            max : '1310 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '1 Cr',
                            max : '2.05 Cr'
                        }
                    ],
                    
                },
                {
                    propertyId : 'indiabulls-one-09',
                    propertyName : 'Indiabulls One 09',
                    propertyPlace : 'Sector 109, Gurgaon',
                    propertyType : '',
                    propertyUnit : 'Industrial',
                    propertyThumbnail : 'assets/images/property/indiabulls.png',
                    propertySize : [
                        {
                            min : '195 Sq Ft',
                            max : '580 Sq Ft'
                        }
                    ],
                    propertyPrice : [
                        {
                            min : '28 Lacs',
                            max : '6.62 Cr'
                        }
                    ],
                    
                }
            ]
        }
    ]
}