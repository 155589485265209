import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { exclusiveProperties } from '../../../../appModals/exclusiveProperty.modals';


@Component({
  selector: 'home-exclusive-property',
  templateUrl: './exclusive-property.component.html',
  styleUrls: ['./exclusive-property.component.css']
})
export class ExclusivePropertyComponent implements OnInit {

  exclusiveProjects: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [''],
    margin: 30,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      738: {
        items: 2
      },
      940: {
        items: 3
      },
      1200: {
        items: 4
      },
      1700: {
        items: 5
      }
    },
    nav: true
  }

  // PROPERTY CATEGORIES
  exclusiveAllProperties : [];
  exclusiveOfficeProperties : []
  exclusiveApartmentProperties : []
  exclusivePlotProperties : []
  exclusiveRentalProperties : []
  exclusiveIndustrialProperties : []

  constructor(private exclusivePropertyList : exclusiveProperties) { }

  ngOnInit(): void {
    // Get All Exclusive Projects
    this.exclusiveAllProperties = this.exclusivePropertyList.propertyList[0].exclusivePropertiesAll;
    
    // Get Office Exclusive Projects
    this.exclusiveOfficeProperties = this.exclusivePropertyList.propertyList[0].exclusivePropertiesOffice;

    // Get Apartment Exclusive Projects
    this.exclusiveApartmentProperties = this.exclusivePropertyList.propertyList[0].exclusivePropertiesApartment;

    // Get Plot Exclusive Projects
    this.exclusivePlotProperties = this.exclusivePropertyList.propertyList[0].exclusivePropertiesPlot;

    // Get Rental Exclusive Projects
    this.exclusiveRentalProperties = this.exclusivePropertyList.propertyList[0].exclusivePropertiesRental;

    // Get Industrial Exclusive Projects
    this.exclusiveIndustrialProperties = this.exclusivePropertyList.propertyList[0].exclusivePropertiesIndustrial;
    
  }
  
  
}
