import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ADD ONS
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AgmCoreModule } from '@agm/core';
import { Ng5SliderModule } from 'ng5-slider';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GoogleMapsModule } from '@angular/google-maps'

// import { ScrollSpyModule, ScrollSpyService } from 'ng-spy';

// COMMON COMPONENT
import { HeaderComponent } from './include/header/header.component';
import { FooterComponent } from './include/footer/footer.component';

// SEARCH PAGE COMPONENT
import { SearchListComponent } from './pages/search-list/search-list.component';
import { SearchListMapComponent } from './pages/search-list/search-list-map/search-list-map.component';
import { SearchListRecordComponent } from './pages/search-list/search-list-record/search-list-record.component';

// HOME PAGE COMPONENT
import { HomeComponent } from './pages/home/home.component';
import { BannerComponent } from './pages/home/banner/banner.component';
import { ActiveListingComponent } from './pages/home/active-listing/active-listing.component';
import { PropertyTypeComponent } from './pages/home/property-type/property-type.component';
import { FeaturedDevelopersComponent } from './pages/home/property-type/featured-developers/featured-developers.component';
import { ExclusivePropertyComponent } from './pages/home/property-type/exclusive-property/exclusive-property.component';
import { TrendingProjectsComponent } from './pages/home/trending-projects/trending-projects.component';
import { ExpandReachComponent } from './pages/home/expand-reach/expand-reach.component';
import { ResourcesComponent } from './pages/home/resources/resources.component';
import { SignUpComponent } from './pages/home/sign-up/sign-up.component';
import { ReachConfidenceComponent } from './pages/home/reach-confidence/reach-confidence.component';

// PAGES
import { WhyReachxComponent } from './pages/why-reachx/why-reachx.component';
import { ReachXPlatformComponent } from './pages/reach-x-platform/reach-x-platform.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';

// SERVICES

// MODALS
import { exclusiveProperties } from './appModals/exclusiveProperty.modals';
import { featuredDeveloper } from './appModals/featuredDevelopers.modals';
import { PropertyDetailComponent } from './pages/property-detail/property-detail.component';
import { trendingProperties } from './appModals/trending.modals';
import { reachXservices } from './appModals/servicesReach.modals';
import { postPropertyTemplate } from './appModals/postPropertyTemplate.modals';

import { DashboardComponent } from './pages/my-account/dashboard/dashboard.component';
import { MyPropertiesComponent } from './pages/my-account/my-properties/my-properties.component';
import { MyListingsComponent } from './pages/my-account/my-listings/my-listings.component';
import { MyServicesComponent } from './pages/my-account/my-services/my-services.component';
import { ProspectsComponent } from './pages/my-account/prospects/prospects.component';
import { MessagesComponent } from './pages/my-account/messages/messages.component';
import { ReportsComponent } from './pages/my-account/reports/reports.component';
import { BuyerDashboardComponent } from './pages/buyer-dashboard/buyer-dashboard.component';
import { ProfileDashboardComponent } from './pages/buyer-dashboard/profile-dashboard/profile-dashboard.component';
import { ProfileSavedSearchesComponent } from './pages/buyer-dashboard/profile-saved-searches/profile-saved-searches.component';
import { ProfileFavoritesComponent } from './pages/buyer-dashboard/profile-favorites/profile-favorites.component';
import { ProfilePropertiesComponent } from './pages/buyer-dashboard/profile-properties/profile-properties.component';
import { ProfileMessagesComponent } from './pages/buyer-dashboard/profile-messages/profile-messages.component';
import { ProfileServicesComponent } from './pages/buyer-dashboard/profile-services/profile-services.component';
import { apiUrl } from './services/api-url';
import { SellComponent } from './pages/sell/sell.component';
import { PostPropertyComponent } from './pages/post-property/post-property.component';
import { SidebarComponent } from './include/sidebar/sidebar.component';
import { HeaderService } from './services/header/header.service';
import { ServicesReachComponent } from './pages/home/services-reach/services-reach.component';
import { MyListingComponent } from './admin/my-listing/my-listing.component';
import { MyReachXComponent } from './admin/my-reach-x/my-reach-x.component';
import { ContactsComponent } from './admin/contacts/contacts.component';
import { PlansComponent } from './admin/plans/plans.component';

import { NgxDonutChartModule } from 'ngx-doughnut-chart';

import { NgxChartsModule }from '@swimlane/ngx-charts';

import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { TestMediaComponent } from './pages/test-media/test-media.component';

import { NgCircleProgressModule } from 'ng-circle-progress';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SearchListComponent,
    SearchListMapComponent,
    SearchListRecordComponent,
    HomeComponent,
    BannerComponent,
    ActiveListingComponent,
    PropertyTypeComponent,
    ExclusivePropertyComponent,
    FeaturedDevelopersComponent,
    TrendingProjectsComponent,
    ExpandReachComponent,
    ResourcesComponent,
    SignUpComponent,
    ReachConfidenceComponent,
    PropertyDetailComponent,
    WhyReachxComponent,
    ReachXPlatformComponent,
    MyAccountComponent,
    DashboardComponent,
    MyPropertiesComponent,
    MyListingsComponent,
    MyServicesComponent,
    ProspectsComponent,
    MessagesComponent,
    ReportsComponent,
    BuyerDashboardComponent,
    ProfileDashboardComponent,
    ProfileSavedSearchesComponent,
    ProfileFavoritesComponent,
    ProfilePropertiesComponent,
    ProfileMessagesComponent,
    ProfileServicesComponent,
    SellComponent,
    PostPropertyComponent,
    SidebarComponent,
    ServicesReachComponent,
    MyListingComponent,
    MyReachXComponent,
    ContactsComponent,
    PlansComponent,
    TestMediaComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TooltipModule.forRoot(),
    CarouselModule,
    ScrollToModule.forRoot(),
    Ng5SliderModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    NgxDonutChartModule,
    NgxChartsModule,
    // 
    ModalModule.forRoot(),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAeSI2gzotBsAhC0j79-11oltcbqI2ZyuE'
    }),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    }),
  ],
  providers: [
    exclusiveProperties,
    featuredDeveloper,
    trendingProperties,
    apiUrl,
    reachXservices,
    CookieService,
    postPropertyTemplate,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: Window,
      useValue: window
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
