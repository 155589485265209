<div class="reachConfidence py-5 row bg-white">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center mb-3">
                <h5 class="text-info text-uppercase fw-400 text-center">
                    reach with <span class="fw-700">confidence</span>
                </h5>
                <small class="fs-12">Get industry's best CRM for Realtors</small>
            </div>
            <div class="col-11 mx-auto text-center">
                <div class="d-none d-lg-block">
                  <svg width="880" height="265" viewBox="0 0 972 265">
                    <g id="crm" transform="translate(-108 -1838)">
                      <g id="Rectangle_122" class="rect" data-name="Rectangle 122" transform="translate(108 1939)" fill="#f0f6fe" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="972" height="87" rx="43.5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="971" height="86" rx="43" fill="none"/>
                      </g>
                      <g id="Rectangle_123" class="rect" data-name="Rectangle 123" transform="translate(443 1914)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="154" height="50" rx="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="153" height="49" rx="4.5" fill="none"/>
                      </g>
                      <g id="Rectangle_127" class="rect" data-name="Rectangle 127" transform="translate(618 1914)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="214" height="50" rx="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="213" height="49" rx="4.5" fill="none"/>
                      </g>
                      <g id="Rectangle_128" class="rect" data-name="Rectangle 128" transform="translate(852 1914)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="153" height="50" rx="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="152" height="49" rx="4.5" fill="none"/>
                      </g>
                      <g id="Rectangle_129" class="rect" data-name="Rectangle 129" transform="translate(810 2000)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="214" height="50" rx="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="213" height="49" rx="4.5" fill="none"/>
                      </g>
                      <g id="Rectangle_130" class="rect" data-name="Rectangle 130" transform="translate(618 2000)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="172" height="50" rx="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="171" height="49" rx="4.5" fill="none"/>
                      </g>
                      <g id="Rectangle_131" class="rect" data-name="Rectangle 131" transform="translate(424 2000)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                        <rect width="174" height="50" rx="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="173" height="49" rx="4.5" fill="none"/>
                      </g>
                      <path id="Path_4434" data-name="Path 4434" d="M17.977,0V17.977H0Z" transform="translate(583.237 1939.5) rotate(-45)" fill="#a2bde5"/>
                      <path id="Path_4435" data-name="Path 4435" d="M17.977,0V17.977H0Z" transform="translate(818.237 1939.5) rotate(-45)" fill="#a2bde5"/>
                      <path id="Path_4440" data-name="Path 4440" d="M17.977,0V17.977H0Z" transform="translate(991.237 1939.5) rotate(-45)" fill="#a2bde5"/>
                      <path id="Path_4437" data-name="Path 4437" d="M17.977,0V17.977H0Z" transform="translate(823.66 2025.5) rotate(135)" fill="#a2bde5"/>
                      <path id="Path_4438" data-name="Path 4438" d="M17.977,0V17.977H0Z" transform="translate(631.66 2025.5) rotate(135)" fill="#a2bde5"/>
                      <path id="Path_4439" data-name="Path 4439" d="M17.977,0V17.977H0Z" transform="translate(437.66 2025.5) rotate(135)" fill="#a2bde5"/>
                      <text id="capture_lead" data-name="capture lead" transform="translate(465 1944)" fill="#4a4a4a" font-size="14" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">CAPTURE LEAD</tspan></text>
                      <text id="simplify_follow-ups" data-name="simplify follow-ups" transform="translate(640 1944)" fill="#4a4a4a" font-size="14" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">SIMPLIFY FOLLOW-UPS</tspan></text>
                      <text id="Get_efficient" data-name="Get efficient" transform="translate(874 1944)" fill="#4a4a4a" font-size="14" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">GET EFFICIENT</tspan></text>
                      <text id="organize_integrate" data-name="organize &amp; integrate" transform="translate(830 2030)" fill="#4a4a4a" font-size="14" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">ORGANIZE &amp; INTEGRATE</tspan></text>
                      <text id="build_relations" data-name="build relations" transform="translate(639 2030)" fill="#4a4a4a" font-size="14" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">BUILD RELATIONS</tspan></text>
                      <text id="close_support" data-name="close &amp; support" transform="translate(443 2030)" fill="#4a4a4a" font-size="14" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">CLOSE &amp; SUPPORT</tspan></text>
                    </g>
                  </svg>
                </div>
                <div class="d-none d-md-block d-lg-none">
                  <svg width="643" height="205" viewBox="0 0 749 205">
                    <g id="Reach_with_confidence" data-name="Reach with confidence" transform="translate(-335 -1753)">
                      <g id="crm" transform="translate(262 1753)">
                        <g id="Rectangle_122" class="rect" data-name="Rectangle 122" transform="translate(128 63)" fill="#f0f6fe" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="694" height="87" rx="43.5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="693" height="86" rx="43" fill="none"/>
                        </g>
                        <g id="Rectangle_184" class="rect" data-name="Rectangle 184" transform="translate(467 43)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="159" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="158" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_123" class="rect" data-name="Rectangle 123" transform="translate(317 43)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="125" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="124" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_185" class="rect" data-name="Rectangle 185" transform="translate(651 43)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="124" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="123" height="37" rx="4.5" fill="none"/>
                        </g>
                        <path id="Path_4455" data-name="Path 4455" d="M11.765,0V11.765H0Z" transform="translate(765.858 62.893) rotate(-45)" fill="#a2bde5"/>
                        <g id="Rectangle_186" class="rect" data-name="Rectangle 186" transform="translate(621 130)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="170" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="169" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_187" class="rect" data-name="Rectangle 187" transform="translate(457 130)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="139" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="138" height="37" rx="4.5" fill="none"/>
                        </g>
                        <path id="Path_4457" data-name="Path 4457" d="M11.765,0V11.765H0Z" transform="translate(466.495 148.893) rotate(135)" fill="#a2bde5"/>
                        <g id="Rectangle_188" class="rect" data-name="Rectangle 188" transform="translate(288 130)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="144" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="143" height="37" rx="4.5" fill="none"/>
                        </g>
                        <path id="Path_4458" data-name="Path 4458" d="M11.765,0V11.765H0Z" transform="translate(297.495 148.893) rotate(135)" fill="#a2bde5"/>
                        <path id="Path_4434" data-name="Path 4434" d="M11.765,0V11.765H0Z" transform="translate(432.63 62.893) rotate(-45)" fill="#a2bde5"/>
                        <text id="capture_lead" data-name="capture lead" transform="translate(333 67)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">CAPTURE LEAD</tspan></text>
                        <text id="simplify_follow-ups" data-name="simplify follow-ups" transform="translate(478 67)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">SIMPLIFY FOLLOW-UPS</tspan></text>
                        <text id="Get_efficient" data-name="Get efficient" transform="translate(669 67)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">GET EFFICIENT</tspan></text>
                        <text id="organize_integrate" data-name="organize &amp; integrate" transform="translate(633 154)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">ORGANIZE &amp; INTEGRATE</tspan></text>
                        <path id="Path_4454" data-name="Path 4454" d="M11.765,0V11.765H0Z" transform="translate(616.857 62.893) rotate(-45)" fill="#a2bde5"/>
                        <text id="build_relations" data-name="build relations" transform="translate(473 154)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">BUILD RELATIONS</tspan></text>
                        <text id="close_support" data-name="close &amp; support" transform="translate(304 154)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">CLOSE &amp; SUPPORT</tspan></text>
                        <path id="Path_4456" data-name="Path 4456" d="M11.765,0V11.765H0Z" transform="translate(630.495 148.893) rotate(135)" fill="#a2bde5"/>
                      </g>
                    </g>
                  </svg>                  
                </div>
                <div class="d-block d-md-none mb-3">
                  <svg width="317" height="418" viewBox="0 0 317 418" style="width: 100%;">
                    <g id="Reach_with_confidence" data-name="Reach with confidence" transform="translate(-751 -1693)">
                      <g id="crm" transform="translate(262 1793)">
                        <g id="Rectangle_122" data-name="Rectangle 122" transform="translate(497 -100)" fill="#f0f6fe" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="300" height="418" rx="50" stroke="none"/>
                          <rect x="0.5" y="0.5" width="299" height="417" rx="49.5" fill="none"/>
                        </g>
                        <g id="Rectangle_184" data-name="Rectangle 184" transform="translate(489 -6)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="317" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="316" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_123" data-name="Rectangle 123" transform="translate(490 -69)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="316" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="315" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_185" data-name="Rectangle 185" transform="translate(489 57)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="317" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="316" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_186" data-name="Rectangle 186" transform="translate(489 120)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="317" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="316" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_187" data-name="Rectangle 187" transform="translate(489 183)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="317" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="316" height="37" rx="4.5" fill="none"/>
                        </g>
                        <g id="Rectangle_188" data-name="Rectangle 188" transform="translate(489 246)" fill="#fff" stroke="#a2bde5" stroke-width="1" stroke-dasharray="3 3">
                          <rect width="317" height="38" rx="5" stroke="none"/>
                          <rect x="0.5" y="0.5" width="316" height="37" rx="4.5" fill="none"/>
                        </g>
                        <path id="Path_4434" data-name="Path 4434" d="M11.765,0V11.765H0Z" transform="translate(648.319 -40.426) rotate(45)" fill="#a2bde5"/>
                        <text id="capture_lead" data-name="capture lead" transform="translate(602 -45)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">CAPTURE LEAD</tspan></text>
                        <text id="simplify_follow-ups" data-name="simplify follow-ups" transform="translate(579 18)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">SIMPLIFY FOLLOW-UPS</tspan></text>
                        <text id="Get_efficient" data-name="Get efficient" transform="translate(604 81)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">GET EFFICIENT</tspan></text>
                        <text id="organize_integrate" data-name="organize &amp; integrate" transform="translate(574 144)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">ORGANIZE &amp; INTEGRATE</tspan></text>
                        <text id="build_relations" data-name="build relations" transform="translate(594 207)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">BUILD RELATIONS</tspan></text>
                        <text id="close_support" data-name="close &amp; support" transform="translate(592 270)" fill="#4a4a4a" font-size="12" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">CLOSE &amp; SUPPORT</tspan></text>
                        <path id="Path_4459" data-name="Path 4459" d="M11.765,0V11.765H0Z" transform="translate(648.319 22.574) rotate(45)" fill="#a2bde5"/>
                        <path id="Path_4460" data-name="Path 4460" d="M11.765,0V11.765H0Z" transform="translate(648.319 85.574) rotate(45)" fill="#a2bde5"/>
                        <path id="Path_4461" data-name="Path 4461" d="M11.765,0V11.765H0Z" transform="translate(648.319 211.574) rotate(45)" fill="#a2bde5"/>
                        <path id="Path_4462" data-name="Path 4462" d="M11.765,0V11.765H0Z" transform="translate(648.319 148.574) rotate(45)" fill="#a2bde5"/>
                        <path id="Path_4463" data-name="Path 4463" d="M11.765,0V11.765H0Z" transform="translate(648.319 274.574) rotate(45)" fill="#a2bde5"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="confidenceLaptop">
                    <img src="assets/images/confidence_laptop.png" class="img-fluid" alt="">
                </div>
                <div class="reachConfidenceBtns">
                  <a href="" class="btn btn-success btn-lg fs-12 fw-500 text-white">
                    Join In
                  </a>
                  <a href="" class="btn btn-dark btn-lg fs-12 fw-500 text-white ml-3">
                    Learn More
                  </a>
                </div>
            </div>
        </div>
    </div>
</div>
