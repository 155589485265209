<div class="container-lg mb-5 mb-md-0" >
    <div class="row">
        <div class="col-md-3 mb-md-0 mb-3">
            <img src="assets/images/logo.svg" alt="">
        </div>
        <div class="col-6 col-md-3 mb-md-0 mb-3">
            <h6 class="text-info text-uppercase fs-12 fw-700">
                overview
            </h6>
            <ul class="m-0 p-0 list-unstyled">
                <li class="mb-2"><a routerLink="" class="text-dark">About Us</a></li>
                <li class="mb-2"><a routerLink="" class="text-dark">Careers</a></li>
                <li class="mb-2"><a routerLink="" class="text-dark">Our Partners</a></li>
                <li class="mb-2"><a routerLink="" class="text-dark">Terms & Conditions</a></li>
                <li class="mb-2"><a routerLink="" class="text-dark">Policy of Use</a></li>
                <li class="mb-2"><a routerLink="" class="text-dark">Privacy Statement</a></li>
            </ul>
        </div>
        <div class="col-6 col-md-3 mb-md-0 mb-3">
            <h6 class="text-info text-uppercase fs-12 fw-700">
                Contact Us
            </h6>
            <ul class="m-0 p-0 list-unstyled">
                <li class="mb-2">Toll Free 1800 GoReachX</li>
                <li class="mb-2">Email <a href="mailto:connect@reach-x.com">connect@reach-x.com</a></li>
            </ul>
        </div>
        <div class="col-8 col-md-3">
            <h6 class="text-info text-uppercase fs-12 fw-700">
                Connect at
            </h6>
            <div class="socialMedia">
                <a href="" class="mr-2 d-inline-block">
                    <img src="assets/images/icons/facebook.svg" alt="">
                </a>
                <a href="" class="mr-2 d-inline-block">
                    <img src="assets/images/icons/linkedin.svg" alt="">
                </a>
                <a href="" class="mr-2 d-inline-block">
                    <img src="assets/images/icons/twitter.svg" alt="">
                </a>
                <a href="" class="mr-2 d-inline-block">
                    <img src="assets/images/icons/instagram.svg" alt="">
                </a>
                <a href="" class="mr-2 d-inline-block">
                    <img src="assets/images/icons/youtube.svg" alt="">
                </a>
            </div>
        </div>
    </div>
</div>