export class reachXservices{
    services : any = [
        {
            serviceName : "Design & Decor",
            serviceImg : "assets/images/services/design-decor.jpg"
        },
        {
            serviceName : "Vaastu",
            serviceImg : "assets/images/services/vaastu.jpg"
        },
        {
            serviceName : "Rental Furniture",
            serviceImg : "assets/images/services/rental-furniture.jpg"
        },
        {
            serviceName : "Home Loans",
            serviceImg : "assets/images/services/home-loan.jpg"
        },
        {
            serviceName : "Movers & Packers",
            serviceImg : "assets/images/services/movers-packers.jpg"
        },
        {
            serviceName : "Property Management",
            serviceImg : "assets/images/services/property-management.jpg"
        }
    ]
}