import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-saved-searches',
  templateUrl: './profile-saved-searches.component.html',
  styleUrls: ['./profile-saved-searches.component.css']
})
export class ProfileSavedSearchesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
