import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-expand-reach',
  templateUrl: './expand-reach.component.html',
  styleUrls: ['./expand-reach.component.css']
})
export class ExpandReachComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
