import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  sidebarActive = new BehaviorSubject(false);
  admin = new BehaviorSubject(false);
  adminMenu = new BehaviorSubject(false);
  constructor() { }
}
