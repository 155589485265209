import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HeaderService } from '../../services/header/header.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { FormArray, FormBuilder, FormGroup, FormControl, Form, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { refozoDBAPIService } from '../../services/refozoDBAPI/refozo-db-api.service';
import { CookieService } from 'ngx-cookie-service';
import { apiUrl } from '../../services/api-url';
import { Router } from '@angular/router';




@Component({
  selector: 'app-my-listing',
  templateUrl: './my-listing.component.html',
  styleUrls: ['./my-listing.component.css']
})
export class MyListingComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private _adminHeader: HeaderService, private _sidebarService: SidebarService, private refozoDBAPIService: refozoDBAPIService, private http: HttpClient, private cookieService: CookieService, private apiUrl: apiUrl) { }

  properties: any;

  get_user_properties() {
    let query_params = new HttpParams();
    query_params = query_params.append('api', 'get_user_properties');

    this.refozoDBAPIService.get(query_params)
      .subscribe(refozoDBAPIService => this.properties = refozoDBAPIService,
        error => console.log(error),
        () => console.log(this.properties))
  }

  ngOnInit(): void {
    this._adminHeader.postPropertyHeader.next(true);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(true);
    this._sidebarService.adminMenu.next(true);
    this._adminHeader.admin.next(true);
    this.get_user_properties()
  }
  ngOnDestroy(): void {
    this._adminHeader.postPropertyHeader.next(false);
    this._sidebarService.sidebarActive.next(true);
    this._sidebarService.admin.next(false);
    this._sidebarService.adminMenu.next(false);
    this._adminHeader.admin.next(false);
  }
  edit_property(id) {
    var post_data = {
      'property_id': id
    };
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'edit_property');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        err => console.error('Observer got an error: ' + err),
        () => {
          if (response) {
            this.router.navigateByUrl('post-property')
          }
          else {
            this.router.navigateByUrl('admin/my-listing')
          }
        }
      )
  }
  delete_property(id) {
    var post_data = {
      'property_id': id
    };
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'delete_property');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        err => console.error('Observer got an error: ' + err),
        () => {
          this.get_user_properties();
        }
      )
  }

  toggle_display_setting(id) {
    var post_data = {
      'property_id': id
    };
    let query_params = new HttpParams();
    var response: any;
    query_params = query_params.append('api', 'toggle_display_setting');

    this.refozoDBAPIService.post(query_params, post_data)
      .subscribe(refozoDBAPIService => response = refozoDBAPIService,
        err => console.error('Observer got an error: ' + err),
        () => {
          this.get_user_properties();
        }
      )
  }

}

