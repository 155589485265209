<div class="bg-white bannerBg row">
    <div class="position-relative pt-md-4 pt-4 pb-4 col-lg-11 mx-auto">
        <!-- BANNER CONTENT BEGIN HERE -->
        <div class="row h-100 align-items-center">
            <div class="col-md-12 col-lg-7 col-xl-6">
                <h3 class="text-info fw-700">
                    Simplify the way you buy + sell real estate
                </h3>
                <p class="fs-16 d-none d-md-block">Experience why Brokers, Buyers, and Sellers use the #1 end-to-end transaction marketplace for real estate. </p>
                <div class="row d-none d-md-flex services">
                    <div class="col-lg-11 banner-buttons">
                        <div class="form-row">
                            <div class="col-md-4 col-6 text-break mb-3">
                                <span class="btn btn-block btn-outline-dark fs-12 fw-400">
                                    Exclusive <br> Inventory
                                </span>
                            </div>
                            <div class="col-md-4 col-6 text-break mb-3">
                                <span class="btn btn-block btn-outline-dark fs-12 fw-400">
                                    Home Loans &amp; <br> Insurance
                                </span>
                            </div>
                            <div class="col-md-4 col-6 text-break mb-3">
                                <span class="btn btn-block btn-outline-dark fs-12 fw-400">
                                    Comprehensive <br> CRM
                                </span>
                            </div>
                            <div class="col-md-4 col-6 text-break mb-3">
                                <span class="btn btn-block btn-outline-dark fs-12 fw-400">
                                    Property <br> Management
                                </span>
                            </div>
                            <div class="col-md-4 col-6 text-break mb-3">
                                <span class="btn btn-block btn-outline-dark fs-12 fw-400">
                                    Virtual <br> Visits
                                </span>
                            </div>
                            <div class="col-md-4 col-6 text-break mb-3">
                                <a routerLink="why-reachx" class="btn btn-block btn-outline-dark fs-12 fw-400 selected">
                                    Why <br> Reach-X?
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- BANNER CONTENT END HER -->
        <!-- LAPTOP SECTION BEGIN HERE -->
        <div class="laptop d-none d-md-flex">
            <div class="position-relative">
                <div class="laptopBanner">
                    <div id="laptopBanner" class="carousel slide carousel-fade" data-interval="10000" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                              <img src="assets/images/laptop-banner-1.jpg" class="img-fluid">
                          </div>
                          <div class="carousel-item">
                            <img src="assets/images/laptop-banner-2.jpg" class="img-fluid">
                          </div>
                          <div class="carousel-item">
                            <img src="assets/images/laptop-banner-3.jpg" class="img-fluid">
                          </div>
                          <div class="carousel-item">
                            <img src="assets/images/laptop-banner-4.jpg" class="img-fluid">
                          </div>
                          <div class="carousel-item">
                            <img src="assets/images/laptop-banner-5.jpg" class="img-fluid">
                          </div>
                          <div class="carousel-item">
                            <img src="assets/images/laptop-banner-6.jpg" class="img-fluid">
                          </div>
                        </div>
                      </div>
                </div>
                <img src="assets/images/laptop.svg" class="img-fluid" alt="">
            </div>
        </div>
        <!-- LAPTOP SECTION END HERE -->
    </div>
</div>
