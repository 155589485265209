<div class="dashboard-container row">
    <div class="w-100">
        <div class="card border-0 rounded-0 p-3 py-5">
            <div class="row">
                <div class="col-xl-6">
                    <div class="row d-flex h-100">
                        <div class="col-4 d-flex">
                            <a class="card p-3 shadow text-center w-100" href="#">
                                <span class="icon d-block mb-2">
                                    <i class="fas fa-search fa-2x text-success"></i>
                                </span>
                                <span class="text text-dark">
                                    Search Leads
                                </span>
                            </a>
                        </div>
                        <div class="col-4 d-flex">
                            <a class="card p-3 shadow text-center w-100" href="#">
                                <span class="icon d-block mb-2">
                                    <i class="fas fa-rupee-sign fa-2x text-info"></i>
                                </span>
                                <span class="text text-dark">
                                    Buy  Services/Plans
                                </span>
                            </a>
                        </div>
                        <div class="col-4 d-flex">
                            <button class="card p-3 shadow text-center w-100 align-items-center" (click)="new_property()">
                                <span class="icon d-block mb-2">
                                    <i class="fas fa-building fa-2x text-orange"></i>
                                </span>
                                <span class="text text-dark">
                                    Post a  Property
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-4 mt-xl-0">
                    <div class="row d-flex h-100">
                        <div class="col-md-8 d-flex mb-4 mb-md-0">
                            <div class="card p-3 shadow w-100">
                                <div class="row h-100 align-items-center">
                                    <div class="col-6">
                                        <div class="">
                                            Unique <strong>Visitors</strong>
                                            <div class="mt-2">
                                                <div class="media">
                                                    <i class="far align-self-center fa-clock mr-3 text-info fs-18"></i>
                                                    <div class="media-body">
                                                        7 days
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <img src="assets/images/unique-visitor-graph.svg" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-md-flex">
                            <div class="row">
                                <div class="col-6 col-md-12 pb-md-1">
                                    <button onclick="$('.chatBot').addClass('active');" class="btn btn-dark btn-block fs-12 py-2 px-1 fw-300 text-uppercase ask-realzo h-100">
                                        <img src="assets/images/realzo.svg" class="mr-1 align-middle" alt=""> Ask Realzo
                                     </button>
                                </div>
                                <div class="col-6 col-md-12 pt-md-1">
                                    <button class="btn btn-success btn-block fs-12 py-2 px-1 fw-300 text-uppercase connect h-100">
                                        <img src="assets/images/icons/phone.svg" width="16" class="mr-1 align-middle" alt=""> Connect
                                     </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dashboard-graph mt-4 pb-5">
    <div class="row d-lg-flex">
        <div class="col-md-6 col-xl-4 mb-4 mb-xl-0 d-md-flex">
            <div class="card p-3 shadow w-100">
                <h3 class="text-info fw-700 text-center text-uppercase fs-18">
                    <span class="fw-300">My</span> Listings
                </h3>
                <ngx-charts-advanced-pie-chart 
                    [results]="my_listing"
                    [view]="[1000,300]"
                    [scheme]="my_listingColorScheme"
                    [label]="my_listing[1].name"
                    [gradient]="false"
                    [tooltipDisabled] = "false"
                >
                </ngx-charts-advanced-pie-chart>
            </div>
        </div>
        <div class="col-md-6 col-xl-4 mb-4 mb-xl-0 d-md-flex">
            <div class="card p-3 shadow w-100">
                <h3 class="text-info fw-700 text-center text-uppercase fs-18">
                    <span class="fw-300">My</span> Contacts
                </h3>
                <ngx-charts-advanced-pie-chart 
                    [results]="my_contact"
                    [view]="[1000,300]"
                    [scheme]="my_contactColorScheme"
                    [label]="my_contact[2].name"
                    [gradient]="false"
                    [tooltipDisabled] = "false"
                     >
                </ngx-charts-advanced-pie-chart>
            </div>
        </div>
        <div class="col-md-6 col-xl-4 d-md-flex">
            <div class="card p-3 shadow w-100">
                <h3 class="text-info fw-700 text-center text-uppercase fs-18">
                    <span class="fw-300">My</span> Plans
                </h3>
                <ngx-charts-advanced-pie-chart 
                    [results]="my_plan"
                    [view]="[1000,300]"
                    [scheme]="my_planColorScheme"
                    [label]="my_plan[1].name"
                    [gradient]="false"
                    [tooltipDisabled] = "false"
                     >
                </ngx-charts-advanced-pie-chart>
            </div>
        </div>
    </div>
</div>